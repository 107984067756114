import React from "react";
import {GeoLocation, IPagedList, SortDirection, Utility} from "@renta-apps/athenaeum-toolkit";
import {ActionType, BaseComponent, ch, PageCacheProvider, TextAlign} from "@renta-apps/athenaeum-react-common";
import {
    Button,
    ButtonType,
    CellAction,
    CellModel,
    Checkbox,
    ColumnActionType,
    ColumnDefinition,
    ColumnType,
    DropdownRequiredType,
    Grid,
    GridHoveringType,
    GridModel,
    GridOddType,
    IconSize,
    Inline,
    JustifyContent,
    RowModel,
    Spinner,
    ToolbarContainer
} from "@renta-apps/athenaeum-react-components";
import User from "@/models/server/User";
import SaveWarehouseRequest from "@/models/server/requests/SaveWarehouseRequest";
import SaveWarehouseResponse from "@/models/server/responses/SaveWarehouseResponse";
import AddWarehouseRequest from "@/models/server/requests/AddWarehouseRequest";
import ListWarehousesRequest from "@/models/server/requests/ListWarehousesRequest";
import Warehouse from "@/models/server/Warehouse";
import WarehouseDetails from "@/pages/Warehouses/WarehouseDetails/WarehouseDetails";
import Localizer from "@/localization/Localizer";
import UserInteractionDataStorage from "@/providers/UserInteractionDataStorage";
import Region from "@/models/server/Region";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";

import styles from "./WarehousesData.module.scss";


interface IWarehousesDataProps  {
}

interface IWarehousesDataState {
    showDeleted: boolean,
    regions: Region[],
}

export default class WarehousesData extends BaseComponent<IWarehousesDataProps, IWarehousesDataState> {

    state: IWarehousesDataState = {
        showDeleted: false,
        regions: [],
    };

    public getTitle(): string {
        return Localizer.topNavWarehouse;
    }

    private readonly _warehousesGridRef: React.RefObject<Grid<Warehouse>> = React.createRef();

    private readonly _warehousesColumns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            minWidth: 50,
            noWrap: true,
            className: "grey",
            textAlign: TextAlign.Center
        },
        {
            header: Localizer.genericNameLanguageItemName,
            accessor: nameof<Warehouse>(d => d.name),
            minWidth: 300,
            type: ColumnType.Text,
            editable: true,
            noWrap: true,
            sorting: true,
            init: (cell: CellModel<Warehouse>) => this.initNameColumn(cell),
            settings: {
                required: true
            },
            actions: [
                {
                    title: Localizer.warehousesCostPoolsTitleLanguageItemName,
                    type: ColumnActionType.Details,
                    callback: async (cell: CellModel<Warehouse>) => await this.toggleDetailsAsync(cell),
                }
            ]
        },
        {
            header: Localizer.genericAddressLanguageItemName,
            accessor: "location.formattedAddress",
            minWidth: 400,
            type: ColumnType.Address,
            editable: true,
            noWrap: true,
            sorting: true,
            init: (cell: CellModel<Warehouse>) => this.initAddressColumn(cell)
        },
        {
            header: Localizer.genericRegion,
            accessor: "region",
            minWidth: 200,
            type: ColumnType.Dropdown,
            editable: false,
            noWrap: true,
            settings: {
                descriptionAccessor: "name",
                required: true,
                requiredType: DropdownRequiredType.AutoSelect,
                fetchItems: async (cell: CellModel<Warehouse>) => await this.getRegionsAsync(cell),
            },
            sorting: true,
        },
        {
            stretch: true,
            minWidth: "6rem",
            removable: false,
            init: (cell) => this.initWarehouseOperationsAsync(cell),
            actions: [
                {
                    name: "save",
                    title: Localizer.genericActionSaveLanguageItemName,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: async (cell, action) => await this.processWarehouseOperationAsync(cell, action)
                },
                {
                    name: "cancel",
                    title: Localizer.genericActionCancelLanguageItemName,
                    icon: "far ban",
                    type: ActionType.Delete,
                    right: true,
                    callback: async (cell, action) => await this.processWarehouseOperationAsync(cell, action)
                },
                {
                    name: "delete",
                    title: Localizer.genericActionDeleteLanguageItemName,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    right: true,
                    confirm: (cell) => this.getDeleteConfirmation(cell),
                    callback: async (cell, action) => await this.processWarehouseOperationAsync(cell, action)
                },
                {
                    name: "restore",
                    title: Localizer.genericActionRestoreLanguageItemName,
                    icon: "far undo-alt",
                    type: ActionType.Create,
                    right: true,
                    callback: async (cell, action) => await this.processWarehouseOperationAsync(cell, action)
                }
            ]
        }
    ];

    private get warehousesGrid(): GridModel<Warehouse> {
        return this._warehousesGridRef.current!.model;
    }

    private get newRowAlreadyExists(): boolean {
        return (this.warehousesGrid.rows.some(row => !row.deleted && !row.model.id));
    }

    private async processWarehouseOperationAsync(cell: CellModel<Warehouse>, action: CellAction<Warehouse>): Promise<void> {

        await ch.hideAlertAsync();

        const model: Warehouse = cell.model;
        const isNew: boolean = (!model.id);

        if (action.action.name === "save") {

            if (isNew) {

                const request = new AddWarehouseRequest();
                request.name = model.name;
                request.formattedAddress = (model.location) ? model.location.formattedAddress : null;
                request.timeTrackingDeviceId = model.timeTrackingDeviceId;
                request.regionId = model.region.id;

                const response: SaveWarehouseResponse = await cell.grid.postAsync("api/admin/addWarehouse", request);

                if (response.warehouseAlreadyExists) {
                    await ch.alertErrorAsync(Utility.format(Localizer.warehousesAlertWarehouseAlreadyExists, model.name), true);
                    return;
                }

                cell.row.model = response.warehouse!;

                await cell.row.saveAsync();

            } else {

                const request = new SaveWarehouseRequest();
                request.warehouseId = model.id;
                request.name = model.name;
                request.formattedAddress = (model.location) ? model.location.formattedAddress : null;
                request.timeTrackingDeviceId = model.timeTrackingDeviceId
                request.regionId = model.region.id;

                const response: SaveWarehouseResponse = await cell.grid.postAsync("api/admin/saveWarehouse", request);

                if (response.warehouseAlreadyExists) {
                    await ch.alertErrorAsync(Utility.format(Localizer.warehousesWarehouseDataAlertWarehouseNameAlreadyExists, model.name), true);
                    return;
                }

                cell.row.model = response.warehouse!;

                await cell.row.bindAsync();
            }

            await cell.row.bindAsync();

        } else if (action.action.name === "cancel") {

            await cell.row.cancelAsync();

        } else if (action.action.name === "delete") {

            model.deleted = true;
            model.deletedAt = new Date();
            model.deletedBy = ch.getUser<User>();

            if (isNew) {
                await cell.grid.deleteAsync(cell.row.index);
            } else {

                await cell.grid.postAsync("api/admin/deleteWarehouse", model.id);

                await cell.row.setDeletedAsync(true);
            }

        } else if (action.action.name === "restore") {

            const restoreOnServer: boolean = !isNew;

            if (restoreOnServer) {
                await cell.grid.postAsync("api/admin/restoreWarehouse", model.id);

                model.deleted = false;
                model.deletedAt = null;
                model.deletedBy = null;
            }

            await cell.row.setDeletedAsync(false);
        }
    }

    private initRow(row: RowModel<Warehouse>): void {
        const model: Warehouse = row.model;

        const isNew: boolean = ((model.id == "") || (model.id == null));
        const isValid: boolean = this.isValid(model);
        row.deleted = model.deleted;
        row.className = (!isValid)
            ? "danger"
            : (isNew)
                ? "bg-processed"
                : "";
    }

    private initNameColumn(cell: CellModel<Warehouse>): void {
        const model: Warehouse = cell.row.model;

        const isNew: boolean = !model.id;

        const detailsAction: CellAction<Warehouse> = cell.actions[0];

        detailsAction.visible = !isNew;
    }

    private initAddressColumn(cell: CellModel<Warehouse>): void {
        const model: Warehouse = cell.row.model;
        if (model.location == null) {
            model.location = new GeoLocation();
            cell.bind();
        }
    }

    private getDeleteConfirmation(cell: CellModel<Warehouse>): string {
        const model: Warehouse = cell.model;
        const isNew: boolean = !model.id;
        return (isNew)
            // Grid types do not allow the returned value to be null, but it has been like this forever.
            ? null as any
            : Utility.format(Localizer.warehousesWarehouseDataDeleteConfirmation, model.name);
    }

    private isValid(warehouse: Warehouse): boolean {
        return (!!warehouse.name) && (!!warehouse.location) && (!!warehouse.location.formattedAddress);
    }

    private async initWarehouseOperationsAsync(cell: CellModel<Warehouse>): Promise<void> {

        const model: Warehouse = cell.row.model;

        const modified: boolean = cell.row.modified;
        const deleted: boolean = cell.row.deleted;
        const isValid: boolean = this.isValid(model);
        const isNew: boolean = !model.id;

        const saveAction: CellAction<Warehouse> = cell.actions[0];
        const cancelAction: CellAction<Warehouse> = cell.actions[1];
        const deleteAction: CellAction<Warehouse> = cell.actions[2];
        const restoreAction: CellAction<Warehouse> = cell.actions[3];

        saveAction.visible = (modified) && (isValid);
        cancelAction.visible = (modified) && (!isNew);
        deleteAction.visible = (!deleted) && ((!modified) || (isNew));
        restoreAction.visible = (deleted);
    }

    private async addWarehouseAsync(): Promise<void> {
        if (!this.newRowAlreadyExists) {
            const warehouse = new Warehouse();

            const newRows: RowModel<Warehouse>[] = await this.warehousesGrid.insertAsync(0, warehouse);

            const newRow: RowModel<Warehouse> = newRows[0];
            const nameCell: CellModel<Warehouse> = newRow.get("name");

            await nameCell.editAsync(true);
        }
    }

    private async toggleDetailsAsync(cell: CellModel<Warehouse>): Promise<void> {
        if ((cell.model.id != null) && (cell.model.id.length > 0)) {
            const spannedRows: RowModel<Warehouse>[] = cell.spannedRows;
            const rowToExpand: RowModel<Warehouse> = spannedRows[spannedRows.length - 1];
            await rowToExpand.toggleAsync();
        } else {
            await ch.flyoutWarningAsync(Localizer.warehousesWarehouseDataAlertCreateWarehouse);
        }
    }

    private async getWarehousesAsync(
        pageNumber: number,
        pageSize: number,
        sortColumnName: string | null,
        sortDirection: SortDirection | null): Promise<IPagedList<Warehouse>> {

        await this.setSpinnerAsync(true);

        UserInteractionDataStorage.setFilters(sortColumnName, "WarehousesData.SortColumn");
        UserInteractionDataStorage.setFilters(sortDirection, "WarehousesData.SortDirection");

        const request = new ListWarehousesRequest();
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;
        request.showDeleted = this.state.showDeleted;
        request.sortColumnName = sortColumnName;
        request.sortDirection = sortDirection;

        await this.setSpinnerAsync(false);

        return await this.warehousesGrid.postAsync("api/admin/listWarehouses", request);
    }

    private async setShowDeletedAsync(showDeleted: boolean): Promise<void> {
        await this.setState({showDeleted});
        await this.reloadAsync();
    }

    private renderDetailsContent(row: RowModel<Warehouse>) {
        const model: Warehouse = row.model;
        return (
            <WarehouseDetails warehouse={model}/>
        );
    }

    private get sortColumn(): string {
        return UserInteractionDataStorage.getFilters("name", "WarehousesData.SortColumn");
    }

    private get sortDirection(): SortDirection {
        return UserInteractionDataStorage.getFilters(SortDirection.Asc, "WarehousesData.SortDirection");
    }
    
    private async getRegionsAsync(cell: CellModel<Warehouse>): Promise<Region[]> {
        return PageCacheProvider.getAsync(
            "api/warehouse/getRegions",
            () => cell.grid.instance.getAsync("api/warehouse/getRegions")
        );
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        await this.setSpinnerAsync(true);
    }

    public async reloadAsync(): Promise<void> {
        await this.warehousesGrid.reloadAsync();
    }

    public hasSpinner(): boolean {
        return true;
    }

    public render(): React.ReactNode {
        return (
            <div className={styles.container}>
                {
                    (this.isSpinning()) && <Spinner/>
                }

                <ToolbarContainer className={styles.toolbar}>

                    <Inline justify={JustifyContent.End}>

                        <Checkbox inline
                                  id="showDeletedWarehouses"
                                  label={Localizer.genericShowDeleted}
                                  value={this.state.showDeleted}
                                  onChange={async (_, value) => await this.setShowDeletedAsync(value)}
                        />

                        <Button id="reloadWarehouses"
                                className="ml-1"
                                title={Localizer.genericActionReload}
                                icon={{name: "far history", size: IconSize.Large}}
                                type={ButtonType.Info}
                                onClick={async () => await this.reloadAsync()}
                        />

                        <Button id="addWarehouse"
                                icon={{name: "plus", size: IconSize.Large}}
                                type={ButtonType.Orange}
                                title={Localizer.warehousesWarehouseDataAddNewWarehouse}
                                onClick={async () => await this.addWarehouseAsync()}
                                disabled={this.isSpinning()}
                        />

                    </Inline>

                </ToolbarContainer>

                <Grid ref={this._warehousesGridRef}
                      id="warehousesGrid"
                      pagination={RentaTaskConstants.paginationNumber}
                      hovering={GridHoveringType.EditableCell}
                      odd={GridOddType.None}
                      minWidth="auto"
                      columns={this._warehousesColumns}
                      noDataText={Localizer.genericNoData}
                      renderDetails={(row) => this.renderDetailsContent(row)}
                      initRow={(row) => this.initRow(row)}
                      fetchData={async (_, pageNumber, pageSize, sortColumnName, sortDirection) => await this.getWarehousesAsync(pageNumber, pageSize, sortColumnName, sortDirection)}
                      defaultSortColumn={this.sortColumn}
                      defaultSortDirection={this.sortDirection}
                />

            </div>
        );
    }
}