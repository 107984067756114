import React from "react";
import {BaseAsyncComponent, BasePageParameters, ch, IBaseAsyncComponentState, IBasePage, PageRoute, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import WorkOrderEquipment from "@/models/server/WorkOrderEquipment";
import Category from "@/models/server/Category";
import {Button, ButtonType, Checkbox, ConfirmationDialog, Icon, IconSize, Modal, Spinner, TextInput} from "@renta-apps/athenaeum-react-components";
import {ICatalogDataProvider} from "@/providers/CatalogDataProvider";
import BreadCrumb from "@/components/Catalog/BreadCrumb/BreadCrumb";
import AddCustomEquipmentModal from "./AddCustomEquipmentModal/AddCustomEquipmentModal";
import CatalogModal from "./CatalogModal/CatalogModal";
import Product from "@/models/server/Product";
import Comparator from "@/helpers/Comparator";
import CategoryItem, {CategoryItemMode} from "./CategoryItem/CategoryItem";
import EquipmentItem from "@/components/Catalog/EquipmentItem/EquipmentItem";
import ProductItem, {ProductItemMode} from "@/components/Catalog/ProductItem/ProductItem";
import SaveCategoryResponse from "@/models/server/responses/SaveCategoryResponse";
import SaveProductResponse from "@/models/server/responses/SaveProductResponse";
import AddExtraChargeModal from "@/components/Catalog/AddExtraChargeModal/AddExtraChargeModal";
import WorkOrderExtraCharge from "@/models/server/WorkOrderExtraCharge";
import ExtraChargeItem from "@/components/Catalog/ExtraChargeItem/ExtraChargeItem";
import DeleteProductResponse from "@/models/server/responses/DeleteProductResponse";
import Localizer from "@/localization/Localizer";
import UnleashHelper from "@/helpers/UnleashHelper";
import FeatureFlags from "@/helpers/FeatureFlags";
import {FeatureSwitch} from "@/components/FeatureSwitch/FeatureSwitch";
import AddRentalEquipmentModal from "@/components/Catalog/AddRentalEquipmentModal/AddRentalEquipmentModal";
import WorkOrderRentalItemModel from "@/models/server/WorkOrderRentalItemModel";
import RentalEquipmentItem from "@/components/Catalog/RentalEquipmentItem/RentalEquipmentItem";
import MassRentalCatalogModal from "@/components/Catalog/CatalogModal/MassRentalCatalogModal";
import {CatalogType} from "@/models/Enums";
import DeleteCategoryResponse from "@/models/server/responses/DeleteCategoryResponse";

import styles from "./Catalog.module.scss";

export enum CatalogMode {

    /**
     * Display navigable {@link Category}s, and {@link WorkOrderEquipment}s whick can be added to "shopping cart".
     */
    AddEquipment = 0,

    /**
     * Display a search bar, list of added {@link WorkOrderEquipment}, "add equipment" and "add custom equipment" buttons.
     */
    ShoppingCart = 1,

    /**
     * Display navigable {@link Category}s and raw {@link Product}s, along with editor functionality
     */
    Editor = 2
}

export class CatalogParameters extends BasePageParameters {
    public search: string | null = null;
    public categoryId: string | null = null;
}

export interface ICatalogProps {
    id?: string;
    type: CatalogType,
    dataProvider: ICatalogDataProvider;
    mode: CatalogMode;
    className?: string;
    onChange?(sender: Catalog, item: WorkOrderEquipment | WorkOrderExtraCharge | WorkOrderRentalItemModel): Promise<void>;
    onFavoriteChange?(sender: Catalog, productId: string, favorite: boolean): Promise<void>;
}

interface ICatalogState extends IBaseAsyncComponentState<{}> {
    categories: Category[];
    displayedCategories: Category[];
    displayedEquipment: WorkOrderEquipment[];
    displayedExtraCharges: WorkOrderExtraCharge[];
    displayedRentalEquipments: WorkOrderRentalItemModel[];
    editing: boolean;
    expandedEquipmentProductId: string | null;
    expandedExtraChargeId: string | null;
    expandedRentalItemId: string | null;
    mode: InternalCatalogMode;
    products: Product[];
    search: string;
    searchValue: string;
    selectedCategoryId: string | null;
    showDeleted: boolean;
}

enum InternalCatalogMode {

    /**
     * Display a search bar, list of added {@link WorkOrderEquipment}, "add equipment" and "add custom equipment" buttons.
     */
    ShoppingCart,

    /**
     * Display a list of {@link Category}s.
     */
    Categories,

    /**
     * Display a list of {@link WorkOrderEquipment}s.
     */
    Products
}

export default class Catalog extends BaseAsyncComponent<ICatalogProps, ICatalogState, {}> {

    public state: ICatalogState = {
        categories: [],
        data: null,
        displayedCategories: [],
        displayedEquipment: [],
        displayedExtraCharges: [],
        displayedRentalEquipments: [],
        editing: false,
        expandedEquipmentProductId: null,
        expandedExtraChargeId: null,
        expandedRentalItemId: null,
        isLoading: false,
        mode: (this.props.mode === CatalogMode.ShoppingCart)
            ? InternalCatalogMode.ShoppingCart
            : InternalCatalogMode.Categories,
        products: [],
        search: "",
        searchValue: "",
        selectedCategoryId: null,
        showDeleted: false
    };


    // Fields

    private readonly _catalogModalRef: React.RefObject<CatalogModal> = React.createRef();
    private readonly _massRentalCatalogModalRef: React.RefObject<MassRentalCatalogModal> = React.createRef();
    private readonly _customEquipmentModalRef: React.RefObject<AddCustomEquipmentModal> = React.createRef();
    private readonly _extraChargeModalRef: React.RefObject<AddExtraChargeModal> = React.createRef();
    private readonly _addRentalEquipmentModalRef: React.RefObject<AddRentalEquipmentModal> = React.createRef();
    private readonly _newCategoryModalRef: React.RefObject<Modal> = React.createRef();
    private readonly _newProductModalRef: React.RefObject<Modal> = React.createRef();
    private readonly _confirmationDialogRef: React.RefObject<ConfirmationDialog> = React.createRef();
    private readonly _params: CatalogParameters = new CatalogParameters();


    // Inherited / implemented

    protected async fetchDataAsync(): Promise<{}> {
        const shoppingCart: boolean = (this.props.mode === CatalogMode.ShoppingCart);
        const productsOnly: boolean = (!shoppingCart) && (!!this.search);

        const categories: Category[] = await this.dataProvider.getAllCategoriesAsync(this, this.props.type);

        const products: Product[] = await this.dataProvider.getProductsAsync(this, this.props.type);

        const displayedCategories: Category[] = ((!shoppingCart) && (!productsOnly))
            ? await this.dataProvider.getCategoriesAsync(this, this.props.type, this.selectedCategoryId, this.showDeleted)
            : [];

        const displayedEquipment: WorkOrderEquipment[] = (shoppingCart)
            ? await this.dataProvider.getAddedEquipmentAsync(this, this.search)
            :  await this.dataProvider.getEquipmentAsync(this, this.props.type, this.showDeleted, this.selectedCategoryId, this.search);

        const displayedExtraCharges: WorkOrderExtraCharge[] = (shoppingCart)
            ? await this.dataProvider.getAddedExtraChargesAsync(this)
            : [];

        const displayedRentalEquipments: WorkOrderRentalItemModel[] = (shoppingCart)
            ? await this.dataProvider.getAddedRentalEquipmentsAsync(this)
            : [];

        const inLeafCategory: boolean = ((!!this.selectedCategoryId) && (displayedCategories.length === 0));
        const mode: InternalCatalogMode = (shoppingCart)
            ? InternalCatalogMode.ShoppingCart
            : (inLeafCategory || productsOnly)
                ? InternalCatalogMode.Products
                : InternalCatalogMode.Categories;

        this.setState({
            categories,
            displayedCategories,
            displayedEquipment,
            displayedExtraCharges,
            displayedRentalEquipments,
            mode,
            products,
        });

        return {};
    }

    protected getEndpoint(): string {
        return "";
    }

    public isAsync(): boolean {
        return true;
    }

    public async reloadAsync(search: string | null = null, categoryId: string | null = null, push: boolean = true): Promise<void> {

        this.state.selectedCategoryId = categoryId;
        this.state.search = search || "";
        this.state.searchValue = "";

        if (push) {
            this.pushRoute();
        }

        await super.reloadAsync();
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if (this.displayingShoppingCart) {
            const params = this.getPage().route.parameters as (CatalogParameters | null);
            if ((params) && ((params.search != this._params.search) || (params.categoryId !== this._params.categoryId))) {
                const modal: CatalogModal | null = this._catalogModalRef.current;
                if (modal) {
                    await modal.reloadAsync(params.search, params.categoryId, false);
                }
            }
        }
    }


    // Getters

    private get categories(): Category[] {
        return this.state.categories;
    }

    private get dataProvider(): ICatalogDataProvider {
        return this.props.dataProvider;
    }

    private get editable(): boolean {
        return (this.props.mode === CatalogMode.Editor);
    }

    private get editing(): boolean {
        // noinspection PointlessBooleanExpressionJS - let's strictly expect a boolean value.
        return (this.editable) && (this.state.editing === true);
    }

    private get expandedEquipmentProductId(): string | null {
        return this.state.expandedEquipmentProductId;
    }

    private get expandedExtraChargeId(): string | null {
        return this.state.expandedExtraChargeId;
    }

    private get mode(): InternalCatalogMode {
        return this.state.mode;
    }

    private get search(): string {
        return this.state.search;
    }

    private get searchValue(): string {
        return this.state.searchValue;
    }

    private get selectedCategoryId(): string | null {
        return this.state.selectedCategoryId;
    }

    private get selectedCategory(): Category | null {
        return (this.selectedCategoryId)
            ? this.categories.find((category: Category) => category.id === this.selectedCategoryId) || null
            : null;
    }

    private get breadCrumbs(): Category[] {
        return (this.selectedCategory)
            ? Category.toBreadCrumbs(this.selectedCategory)
            : [];
    }

    private get displayedCategories(): Category[] {
        return this.state.displayedCategories;
    }

    private get displayedEquipment(): WorkOrderEquipment[] {
        return this.state.displayedEquipment;
    }

    private get displayedExtraCharges(): WorkOrderExtraCharge[] {
        return this.state.displayedExtraCharges;
    }

    private get displayedRentalEquipments(): WorkOrderRentalItemModel[] {
        return this.state.displayedRentalEquipments;
    }

    private get displayingShoppingCart(): boolean {
        return (this.mode === InternalCatalogMode.ShoppingCart);
    }

    private get displayingProducts(): boolean {
        return (this.mode === InternalCatalogMode.Products);
    }

    private get displayingCategories(): boolean {
        return (this.mode === InternalCatalogMode.Categories);
    }

    private get needsBreadCrumbs(): boolean {
        return (!this.displayingShoppingCart);
    }

    private get products(): Product[] {
        return this.state.products;
    }

    private get searchResultCount(): number {
        return (this.displayingCategories)
            ? this.displayedCategories.length
            : this.displayedEquipment.length;
    }

    private get selectedCategoryName(): string | null {
        const category: Category | null = (this.selectedCategory)
            ? this.breadCrumbs.find(category => category.id === this.selectedCategoryId) || null
            : null;
        return (category?.name)
            ? category.name
            : null;
    }

    private get searchResult(): string {
        return (this.isLoading)
            ? Localizer.rentaTasksAddEquipmentCategoriesOrProductsSearching
            : (this.searchResultCount)
                ? Localizer.rentaTasksAddEquipmentCategoriesOrProductsProductsFound.format(this.searchResultCount)
                : Localizer.rentaTasksAddEquipmentCategoriesOrProductsNoProductsFound
    }

    private get title(): string {
        const page: IBasePage = this.getPage();
        let title: string = page.getTitle();
        const selectedCategoryName: string | null = this.selectedCategoryName;
        if (selectedCategoryName) {
            title = title + "/" + selectedCategoryName;
        }
        if (this.search) {
            title = title + "/" + this.search;
        }
        return title;
    }

    private get categoriesWithoutProducts(): Category[] {
        return this.categories.where((category: Category) => (
                !this.products.some((product: Product) => (product.categoryId === category.id))));
    }

    private get categoriesWithoutSubCategories(): Category[] {
        return this.categories.where((parent: Category) =>
            !this.categories.some((child: Category) => (child.parentId === parent.id)));
    }

    private get newCategoryModal(): Modal {
        return Comparator.assertIsNonNullObject(this._newCategoryModalRef?.current);
    }

    private get newProductModal(): Modal {
        return Comparator.assertIsNonNullObject(this._newProductModalRef?.current);
    }

    private get confirmationDialog(): ConfirmationDialog {
        return Comparator.assertIsNonNullObject(this._confirmationDialogRef.current);
    }

    private get existingCategoryNames(): string[] {
        return this.categories.map((category: Category) => category.name);
    }

    private get existingProductNames(): string[] {
        return this.products.map((product: Product) => product.name);
    }

    private get existingProductExternalIds(): string[] {
        return this.products.map((product: Product) => product.externalId).filter((externalId: string | null) => !!externalId) as string[];
    }

    private get showDeleted(): boolean {
        return this.state.showDeleted;
    }


    // Sync-methods

    private pushRoute(): void {
        if (!this.displayingShoppingCart) {
            const params: CatalogParameters = this._params;
            if ((params.categoryId != this.selectedCategoryId) || (params.search != this.search)) {
                params.categoryId = this.selectedCategoryId;
                params.search = this.search;

                const route: PageRoute = this.getPage().route;
                route.parameters = params;

                const title: string = this.title;

                PageRouteProvider.pushHistoryState(route, title);
            }
        }
    }

    private getAvailableParentCategories(category: Category): Category[] {
        return this.categoriesWithoutProducts.filter((emptyCategory: Category) => {
            let parent: Category | null = emptyCategory;
            while (parent) {
                if (parent.id === category.id) {
                    return false;
                }
                parent = parent.parent;
            }
            return true;
        });
    }

    private getCanRemoveCategory(category: Category): boolean {
        const hasChildCategories: boolean = this.categories.some((childCategory: Category) => childCategory.parentId === category.id && !childCategory.deleted);
        const hasProducts: boolean = this.products.some((product: Product) => product.categoryId === category.id && !product.deleted);
        const isAlreadyDeleted: boolean = category.deleted;

        return !hasChildCategories && !hasProducts && !isAlreadyDeleted;
    }
    
    // Async-methods

    private async onChangeItemAsync(item: WorkOrderEquipment | WorkOrderExtraCharge | WorkOrderRentalItemModel): Promise<void> {
        if (this.props.onChange) {
            await this.props.onChange(this, item);
        }
        await this.refreshAsync();
    }

    private async refreshAsync(): Promise<void> {
        if (this.displayingShoppingCart) {
            this.state.displayedEquipment = await this.dataProvider.getAddedEquipmentAsync(this, this.search);
            this.state.displayedExtraCharges = await this.dataProvider.getAddedExtraChargesAsync(this);
            this.state.displayedRentalEquipments = await this.dataProvider.getAddedRentalEquipmentsAsync(this);
            await this.reRenderAsync();
        }
    }

    private async onAddCustomEquipment(item: WorkOrderEquipment): Promise<void> {
        await this.dataProvider.addCustomEquipmentAsync(item);

        await this.onChangeItemAsync(item);
    }

    private async onAddExtraChargeAsync(item: WorkOrderExtraCharge): Promise<void> {
        await this.dataProvider.addExtraChargeAsync(item);

        await this.onChangeItemAsync(item);
    }

    private async onAddRentalEquipmentAsync(rentalEquipment: WorkOrderRentalItemModel): Promise<void> {
        await this.dataProvider.addRentalEquipmentAsync(rentalEquipment);

        await this.onChangeItemAsync(rentalEquipment);
    }

    private async onAddEquipmentAsync(equipmentItems: WorkOrderEquipment[]): Promise<void> {
        await equipmentItems.forEachAsync(async (item : WorkOrderEquipment) : Promise<void> => {
            const alreadyAdded : boolean = this.displayedEquipment.some((displayedItem : WorkOrderEquipment) => displayedItem.productId === item.productId 
                && displayedItem.rentDate === item.rentDate && displayedItem.actionType === item.actionType);
            if (!alreadyAdded) {
                await this.dataProvider.addEquipmentAsync(item);
                await this.onChangeItemAsync(item);
            }
        })
    }

    private async onRemoveRentalEquipmentAsync(rentalEquipment: WorkOrderRentalItemModel): Promise<void> {
        await this.dataProvider.removeRentalEquipmentAsync(rentalEquipment);
        await this.refreshAsync();
    }

    private async onEquipmentDescriptionToggle(equipment: WorkOrderEquipment, expanded: boolean): Promise<void> {
        this.setState({
            expandedEquipmentProductId: (expanded)
                ? equipment.productId
                : null
        });
    }

    private async onExtraChargeDescriptionToggle(extraCharge: WorkOrderExtraCharge, expanded: boolean): Promise<void> {
        const expandedExtraChargeId: string | null = (expanded)
            ? extraCharge.id
            : null;

        await this.setState({ expandedExtraChargeId });
    }

    private async onRentalItemDescriptionToggle(rentalEquipment: WorkOrderRentalItemModel, expanded: boolean): Promise<void> {
        const expandedRentalItemId: string | null = (expanded)
            ? rentalEquipment.id
            : null;

        await this.setState({ expandedRentalItemId });
    }

    private async onFavoriteChangeAsync(productId: string, favorite: boolean): Promise<void> {
        if (productId) {
            if (this.displayingShoppingCart) {
                await this.dataProvider.setFavoriteAsync(this, productId, favorite);
            }
            if (this.props.onFavoriteChange) {
                await this.props.onFavoriteChange(this, productId, favorite);
            }
        }
    }

    private async onSearchValueChangedAsync(value: string, done: boolean): Promise<void> {
        if (done) {
            await this.searchAsync();
        } else if (this.searchValue !== value) {
            await this.setState({searchValue: value});
        }
    }

    private async onSelectCategoryAsync(category: Category): Promise<void> {
        if (((this.selectedCategoryId !== category.id) || (!!this.search)) && (!category.deleted)) {
            await this.reloadAsync("", category.id);
        }
    }

    private async onHomeAsync(): Promise<void> {
        await this.reloadAsync();
    }

    private async searchAsync(): Promise<void> {
        await this.reloadAsync(this.searchValue, this.selectedCategoryId);
    }

    private async openSearchModalAsync(catalogType: CatalogType): Promise<void> {
        await this._catalogModalRef.current!.openAsync(catalogType);
    }

    private async openMassRentalSearchModalAsync(): Promise<void> {
        await this._massRentalCatalogModalRef.current!.openAsync();
    }

    private async openNewCustomProductModalAsync(): Promise<void> {
        await this._customEquipmentModalRef.current!.openAsync();
    }

    private async openAddExtraChargeModalAsync(): Promise<void> {
        await this._extraChargeModalRef.current!.openAsync();
    }

    private async openAddRentalEquipmentModalAsync(): Promise<void> {
        await this._addRentalEquipmentModalRef.current!.openAsync();
    }

    private async createCategoryAsync(category: Category): Promise<void> {
        await this.newCategoryModal.closeAsync();
        const response: SaveCategoryResponse = await this.dataProvider.createCategoryAsync(this, category);

        if (response.categoryAlreadyExists) {
            await ch.flyoutErrorAsync(Localizer.catalogManagementAlertCategoryCreationFailure.format(Localizer.categoryItemValidationCategoryWithSameNameAlreadyExists));
        }

        await this.fetchDataAsync();
        await ch.flyoutMessageAsync(Localizer.catalogManagementAlertCategoryCreatedSuccessfully.format(category.name));
    }

    private async saveCategoryAsync(category: Category): Promise<void> {
        if (category.id) {
            const response: SaveCategoryResponse = await this.dataProvider.editCategoryAsync(this, category);

            if (response.categoryAlreadyExists) {
                await ch.flyoutErrorAsync(Localizer.catalogManagementAlertCategoryEditFailure.format(Localizer.categoryItemValidationCategoryWithSameNameAlreadyExists));
            }

            await this.fetchDataAsync();
            await ch.flyoutMessageAsync(Localizer.catalogManagementAlertCategoryEditedSuccessfully.format(category.name));
        }
        else {
            await this.createCategoryAsync(category);
        }
    }

    private async removeCategoryAsync(category: Category): Promise<DeleteCategoryResponse | null> {
        const confirmed: boolean =
            await this.confirmationDialog.confirmAsync(Localizer.catalogManagementConfirmationModalRemoveCategory.format(category.name));

        if (confirmed) {
            const response: DeleteCategoryResponse = await this.dataProvider.removeCategoryAsync(this, category.id);
            await this.fetchDataAsync();

            if (response.removedPermanently){
                await ch.flyoutMessageAsync(Localizer.catalogManagementAlertCategoryRemovedSuccessfully.format(category.name));
            } else {
                await ch.flyoutMessageAsync(Localizer.catalogFlyoutMessageCategoryMarkedAsDeleted.format(category.name));
            }
            
            return response;
        }
        
        return null;
    }

    private async createProductAsync(product: Product): Promise<void> {
        await this.newProductModal.closeAsync();
        const response: SaveProductResponse = await this.dataProvider.createProductAsync(this, product);

        if (response.productAlreadyExists) {
            await ch.flyoutErrorAsync(Localizer.catalogManagementAlertProductCreationFailure.format(Localizer.productItemValidationProductWithSameNameAlreadyExists));
        }

        await this.fetchDataAsync();
        await ch.flyoutMessageAsync(Localizer.catalogManagementAlertProductCreatedSuccessfully.format(product.name));
    }

    private async saveProductAsync(product: Product): Promise<void> {
        if (product.id) {
            const response: SaveProductResponse = await this.dataProvider.editProductAsync(this, product);

            if (response.productAlreadyExists) {
                await ch.flyoutErrorAsync(Localizer.catalogManagementAlertProductEditFailure.format(Localizer.productItemValidationProductWithSameNameAlreadyExists));
            }

            await this.fetchDataAsync();
            await ch.flyoutMessageAsync(Localizer.catalogManagementAlertProductEditedSuccessfully.format(product.name));
        }
        else {
            await this.createProductAsync(product);
        }
    }

    private async onShowDeletedAsync(showDeleted: boolean): Promise<void> {
        await this.setState({showDeleted});
        await this.fetchDataAsync();
    }

    private async removeProductAsync(product: Product): Promise<DeleteProductResponse | null> {
        const confirmed: boolean = await this.confirmationDialog.confirmAsync(Localizer.catalogManagementConfirmationModalRemoveProduct.format(product.name));

        if (confirmed) {
            const response: DeleteProductResponse = await this.dataProvider.removeProductAsync(this, product.id);
            await this.fetchDataAsync();

            if (response.removedPermanently){
                await ch.flyoutMessageAsync(Localizer.get(Localizer.catalogFlyoutMessageProductPermanentlyRemoved, product.name));
            } else {
                await ch.flyoutMessageAsync(Localizer.get(Localizer.catalogFlyoutMessageProductMarkedAsDeleted, product.name));
            }

            return response;
        }

        return null;
    }

    private async restoreProductAsync(product: Product): Promise<Product> {
            const restoredProduct: Product = await this.dataProvider.restoreProductAsync(this, product.id);
            await this.fetchDataAsync();

            await ch.flyoutMessageAsync(Localizer.get(Localizer.catalogFlyoutMessageProductRestored, product.name));

            return restoredProduct
    }

    private getButtonContainerSize(isExtraChargesEnabled: boolean, isRentalEquipmentEnabled: boolean): string {
        let gridVariant = styles.twoColumnsGrid;

        if ((!isExtraChargesEnabled && isRentalEquipmentEnabled) || (isExtraChargesEnabled && !isRentalEquipmentEnabled)) {
            gridVariant = styles.threeColumnsGrid;
        }

        return this.css(styles.addButtonsContainer, gridVariant)
    }

    private async restoreCategoryAsync(category: Category): Promise<Category> {
        const restoredCategory: Category = await this.dataProvider.restoreCategoryAsync(this, category.id);
        await this.fetchDataAsync();

        await ch.flyoutMessageAsync(Localizer.get(Localizer.catalogFlyoutMessageCategoryRestored, Category.name));

        return restoredCategory
    }

    // Renders

    private get renderNewCategoryModal(): JSX.Element {

        const newCategory: Category = {
            parentId: this.selectedCategoryId,
            parent: this.selectedCategory,
            catalogType: this.props.type,
            isCategory: true,
            name: "",
            icon: "",
            id: "",
            deleted: false,
        };

        return (
            <Modal id={"addCategoryModal"}
                   key={ch.getId()}
                   ref={this._newCategoryModalRef}
                   className={styles.createModal}
                   title={Localizer.catalogManagementAddCategory}
            >
                <CategoryItem category={newCategory}
                              mode={CategoryItemMode.Creating}
                              availableParentCategories={this.getAvailableParentCategories(newCategory)}
                              existingNames={this.existingCategoryNames}
                              onSaveChanges={async (category: Category) => await this.createCategoryAsync(category)}
                />
            </Modal>
        );
    }

    private get renderNewProductModal(): JSX.Element {

        const newProduct: Product = new Product();
        newProduct.categoryId = this.selectedCategoryId!;
        newProduct.category = this.selectedCategory!;

        return (
            <Modal id={"addProductModal"}
                   key={ch.getId()}
                   ref={this._newProductModalRef}
                   className={styles.createModal}
                   title={Localizer.catalogManagementAddProduct}
            >
                <ProductItem mode={ProductItemMode.Creating}
                             product={newProduct}
                             availableParentCategories={this.categoriesWithoutSubCategories}
                             existingNames={this.existingProductNames}
                             existingExternalIds={this.existingProductExternalIds}
                             onSaveChanges={async (product: Product) => await this.createProductAsync(product)}
                />
            </Modal>
        );
    }

    private get renderEditButtons(): JSX.Element | null {
        if (!this.editable) {
            return null;
        }
        return (
            <div className={styles.editButtons}>

                <Button id={"editButton"}
                        label={Localizer.genericActionEdit}
                        icon={{name: "edit", size: IconSize.Normal}}
                        type={ButtonType.Danger}
                        onClick={async () => this.setState({editing: (!this.editing)})}
                />

                {
                    (this.editing) && (this.displayingProducts) &&
                    (
                        <React.Fragment>

                            <Button id={"addProduct"}
                                    label={Localizer.catalogManagementAddProduct}
                                    icon={{name: "plus", size: IconSize.Normal}}
                                    onClick={async () => await this.newProductModal.openAsync()}
                            />

                            {
                                this.renderNewProductModal
                            }

                        </React.Fragment>
                    )
                }

                {
                    (this.editing) && (this.displayingCategories) &&
                    (
                        <React.Fragment>

                            <Button id={"addCategory"}
                                    label={Localizer.catalogManagementAddCategory}
                                    icon={{name: "plus", size: IconSize.Normal}}
                                    onClick={async () => await this.newCategoryModal.openAsync()}
                            />

                            {
                                this.renderNewCategoryModal
                            }

                        </React.Fragment>
                    )
                }

            </div>
        );
    }

    private get renderSearchButton(): React.ReactNode {
        return (
            <Icon id={"searchButton"}
                  name={"far search"}
                  onClick={() => this.searchAsync()}
            />
        );
    }

    private get renderList(): React.ReactNode {

        return (
            <div id={"categoriesAndProductsList"}
                 className={styles.list}>

                {
                    (this.displayingCategories)
                        ?
                        (
                            this.displayedCategories.map((category: Category, index: number) =>
                                <CategoryItem key={category.id + index}
                                              category={category}
                                              onClick={() => this.onSelectCategoryAsync(category)}
                                              mode={(this.editing) ? CategoryItemMode.Editing : CategoryItemMode.Displaying}
                                              availableParentCategories={this.getAvailableParentCategories(category)}
                                              canRemove={this.getCanRemoveCategory(category)}
                                              existingNames={this.existingCategoryNames}
                                              onRemove={async () => await this.removeCategoryAsync(category)}
                                              onSaveChanges={async (newCategory: Category) => await this.saveCategoryAsync(newCategory)}
                                              onRestore={async () => await this.restoreCategoryAsync(category)}
                                />
                            )
                        )
                        : (this.props.mode === CatalogMode.Editor)
                            ?
                            (
                                this.displayedEquipment.map((equipment: WorkOrderEquipment) => equipment.product!).map((product: Product, index: number) =>
                                    <ProductItem key={product.id! + index}
                                                 className={this.css(product.deleted && styles.deleted)}
                                                 mode={(this.editing) ? ProductItemMode.Editing : ProductItemMode.Displaying}
                                                 product={product}
                                                 availableParentCategories={this.categoriesWithoutSubCategories}
                                                 existingNames={this.existingProductNames}
                                                 existingExternalIds={this.existingProductExternalIds}
                                                 onRemove={async () => await this.removeProductAsync(product)}
                                                 onRestore={async () => await this.restoreProductAsync(product)}
                                                 onSaveChanges={async (newProduct: Product) => await this.saveProductAsync(newProduct)}
                                    />
                                )
                            )
                            :
                            (
                                this.displayedEquipment.map((equipment: WorkOrderEquipment, index: number) =>
                                    <EquipmentItem key={equipment.id + "equipment" + index}
                                                   equipment={equipment}
                                                   expanded={(equipment.productId) ? (this.expandedEquipmentProductId === equipment.productId) : false}
                                                   shoppingCart={this.displayingShoppingCart}
                                                   catalogType={equipment.product?.category?.catalogType ?? this.props.type}
                                                   onChange={async () => await this.onChangeItemAsync(equipment)}
                                                   onDescriptionToggle={async (_, equipment, expanded) => await this.onEquipmentDescriptionToggle(equipment, expanded)}
                                                   onFavoriteChange={async (_, productId: string, favorite: boolean) => await this.onFavoriteChangeAsync(productId, favorite)}
                                                   editing={this.editing}
                                    />
                                )
                            )
                }

                <FeatureSwitch flagName={FeatureFlags.ExtraCharge}>
                {
                    (this.displayedExtraCharges.map((extraCharge: WorkOrderExtraCharge, index: number) =>
                        <ExtraChargeItem key={`extraChargeItem_${index}`}
                                         extraCharge={extraCharge}
                                         expanded={(extraCharge.id) ? (this.expandedExtraChargeId === extraCharge.id) : false}
                                         shoppingCart={this.displayingShoppingCart}
                                         onChange={async () => await this.onChangeItemAsync(extraCharge)}
                                         onDescriptionToggle={async (_, __, expanded) => await this.onExtraChargeDescriptionToggle(extraCharge, expanded)}
                                         editing={this.editing}

                        />
                    ))
                }
                </FeatureSwitch>

                <FeatureSwitch flagName={FeatureFlags.RentalEquipment}>
                    {
                        (this.displayedRentalEquipments.map((rentalEquipment: WorkOrderRentalItemModel, index: number) =>
                            <RentalEquipmentItem className="rental-equipment-item"
                                                 key={`rentalEquipmentItem_${index}`}
                                                 rentalEquipment={rentalEquipment}
                                                 expanded={(rentalEquipment.id) ? (this.expandedExtraChargeId === rentalEquipment.id) : false}
                                                 shoppingCart={this.displayingShoppingCart}
                                                 onChange={async () => await this.onChangeItemAsync(rentalEquipment)}
                                                 onRemove={async (_, item) => await this.onRemoveRentalEquipmentAsync(item)}
                                                 onDescriptionToggle={async (_, __, expanded) => await this.onRentalItemDescriptionToggle(rentalEquipment, expanded)}
                                                 editing={this.editing}

                            />
                        ))
                    }
                </FeatureSwitch>
            </div>
        );
    }
    private renderAddButtonsContainer(): React.ReactNode {
        const isExtraChargesEnabled: boolean = UnleashHelper.isEnabled(FeatureFlags.ExtraCharge);
        const isRentalEquipmentEnabled: boolean = UnleashHelper.isEnabled(FeatureFlags.RentalEquipment);

        return (
            <div id={"addSalesEquipmentButtonsContainer"} className={this.getButtonContainerSize(isExtraChargesEnabled, isRentalEquipmentEnabled)}>
                <div id={"addEquipmentButton"} className={this.css(styles.add, "w-100")} onClick={() => this.openSearchModalAsync(CatalogType.SalesProduct)}>
                    <Icon name={"fa-plus"} size={IconSize.Large}/>
                    <span>{Localizer.rentaTasksAddEquipmentCategoriesOrProductsAddEquipment}</span>
                </div>

                <FeatureSwitch flagName={FeatureFlags.RentalMassProducts}>
                    <div id={"addMassRentalEquipmentButton"} className={this.css(styles.add, "w-100")}
                         onClick={() => this.openMassRentalSearchModalAsync()}>
                        <Icon name={"fa-plus"} size={IconSize.Large}/>
                        <span>{Localizer.rentaTasksAddEquipmentCategoriesOrProductsAddRentalMassEquipment}</span>
                    </div>
                </FeatureSwitch>

                <div id={"addCustomEquipmentButton"} className={this.css(styles.add, "w-100")} onClick={() => this.openNewCustomProductModalAsync()}>
                    <Icon name={"fa-plus"} size={IconSize.Large}/>
                    <span>{Localizer.rentaTasksAddEquipmentCategoriesOrProductsAddCustomEquipment}</span>
                </div>

                {
                    (isExtraChargesEnabled) &&
                    (
                        <div id={"addExtraChargeButton"} className={this.css(styles.add, "w-100")} onClick={() => this.openAddExtraChargeModalAsync()}>
                            <Icon name={"fa-plus"} size={IconSize.Large} />
                            <span>{Localizer.rentaTasksAddEquipmentCategoriesOrProductsAddExtraCharge}</span>
                        </div>
                    )
                }

                {
                    isRentalEquipmentEnabled &&
                    (
                        <div id="addRentalEquipmentButton" className={this.css(styles.add, "w-100")} onClick={() => this.openAddRentalEquipmentModalAsync()}>
                            <Icon name={"fa-plus"} size={IconSize.Large}/>
                            <span>{Localizer.rentalEquipmentAddRentalEquipment}</span>
                        </div>
                    )
                }

            </div>
        )
    }

    public render(): React.ReactNode {
        const shoppingCartStyle: any = (this.displayingShoppingCart) && styles.shoppingCart;

        return (
            <div id={this.props.id} className={this.css(styles.catalog, shoppingCartStyle, this.props.className)}>

                <ConfirmationDialog ref={this._confirmationDialogRef}/>

                {
                    (this.editable) &&
                    (
                        this.renderEditButtons
                    )
                }

                {
                    (this.needsBreadCrumbs) &&
                    (
                        <BreadCrumb items={this.breadCrumbs}
                                    className={styles.breadCrumbs}
                                    onClick={(_, category) => this.onSelectCategoryAsync(category)}
                                    onHome={() => this.onHomeAsync()}
                        />
                    )
                }

                <TextInput trim
                           id={"search"}
                           className={styles.search}
                           placeholder={Localizer.rentaTasksAddEquipmentCategoriesOrProductsTextInputPlaceholderSearch}
                           autoComplete={false}
                           value={this.searchValue}
                           onChange={(_, value, __, done) => this.onSearchValueChangedAsync(value, done)}
                           append={() => this.renderSearchButton}
                />

                {
                    (this.search) &&
                    (
                        <div className={styles.searchTitle}>

                            <h3>
                                {Localizer.rentaTasksAddEquipmentCategoriesOrProductsSearchResult.format(this.search)}
                            </h3>

                            <p>
                                {this.searchResult}
                            </p>

                        </div>
                    )
                }

                {
                    (this.hasData) && (this.displayingShoppingCart) &&
                    (
                        this.renderAddButtonsContainer()
                    )
                }

                {
                    (this.props.mode === CatalogMode.Editor && (this.mode === InternalCatalogMode.Products || this.mode === InternalCatalogMode.Categories)) &&
                    (
                        <Checkbox inline
                                  label={Localizer.genericShowDeleted}
                                  value={this.showDeleted}
                                  onChange={async (_, value) => await this.onShowDeletedAsync(value)}
                        />
                    )
                }

                {
                    (this.hasData) && (this.displayingProducts) && (this.displayedEquipment.length <= 0) && (!this.search) &&
                    (
                        <div className={styles.noProducts}>
                            <span>{Localizer.rentaTasksAddEquipmentCategoriesOrProductsNoProducts}</span>
                        </div>
                    )
                }

                {
                    (this.hasData) &&
                    (
                        this.renderList
                    )
                }

                {
                    (this.isLoading) &&
                    (
                        <Spinner/>
                    )
                }

                {
                    (this.displayingShoppingCart) &&
                    (
                        <React.Fragment>

                            <CatalogModal ref={this._catalogModalRef}
                                          dataProvider={this.dataProvider}
                                          onChange={(_, item) => this.onChangeItemAsync(item)}
                                          onFavoriteChange={(_, productId: string, favorite) => this.onFavoriteChangeAsync(productId, favorite)}
                                          onModalChange={() => this.openAddRentalEquipmentModalAsync()}
                            />

                            <MassRentalCatalogModal ref={this._massRentalCatalogModalRef}
                                          dataProvider={this.dataProvider}
                                          onChange={(_, item) => this.onChangeItemAsync(item)}
                                          onFavoriteChange={(_, productId: string, favorite) => this.onFavoriteChangeAsync(productId, favorite)}
                                          addEquipmentItem={(_, equipmentItems: WorkOrderEquipment[]) => this.onAddEquipmentAsync(equipmentItems)}
                                          onModalChange={() => this.openAddRentalEquipmentModalAsync()}
                            />

                            <AddCustomEquipmentModal ref={this._customEquipmentModalRef}
                                                     addCustomProduct={(_, item: WorkOrderEquipment) => this.onAddCustomEquipment(item)}
                            />

                            <AddExtraChargeModal ref={this._extraChargeModalRef}
                                                 addExtraCharge={(_, item: WorkOrderExtraCharge) => this.onAddExtraChargeAsync(item)}
                            />

                            <AddRentalEquipmentModal ref={this._addRentalEquipmentModalRef}
                                                     addRentalEquipmentItem={(rentalEquipment: WorkOrderRentalItemModel) => this.onAddRentalEquipmentAsync(rentalEquipment)}
                            />

                        </React.Fragment>
                    )
                }

                
                
            </div>
        );
    }
};