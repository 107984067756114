/**
 *  Keys for feature flags. There's a matching .cs file.
 */
export default class FeatureFlags {

    /**
     * Enables calendar planner in application UI
     */
    public static readonly Calendar = "CALENDAR" as const;

    /**
     * Controls Admin CRUD view of extra charge types, and adding extra charges on a work order
     */
    public static readonly ExtraCharge = "EXTRA_CHARGE" as const;

    /**
     * Enables Sync service
     */
    public static readonly Sync = "SYNC" as const;

    /**
     * Enables displaying and modification of users employee number in UserManagement page.
     */
    public static readonly EmployeeNumber = "EMPLOYEE_NUMBER" as const;

    /**
     * Enables option to add rental equipment to work order.
     */
    public static readonly RentalEquipment = "RENTAL_EQUIPMENT" as const;

    /**
     * Enables new full-text-search functionality for construction site page.
     */
    public static readonly ConstructionSiteFullTextSearch = "CS_FULLTEXT_SEARCH" as const;

    /**
     * Enables MySqlMatchSearchMode.Boolean for {@see ConstructionSiteFullTextSearch} 
     */
    public static readonly ConstructionSiteFullTextSearchBooleanMode = "CS_FULLTEXT_SEARCH_BOOLEAN_MODE" as const;

    /**
     * Enables and displays alarm job functions on the UI.
     */
    public static readonly AlarmJobs = "ALARM_JOBS" as const;

    /**
     * Enables and displays comment field for user salary hours on the UI.
     */
    public static readonly UserSalaryHoursComment = "HOURS_COMMENT" as const;

    /**
     * Displays rental mass products on the catalog and on work order pages.
     */
    public static readonly RentalMassProducts = "RENTAL_MASS_PRODUCTS" as const;

    /**
     * If enabled when a cost pool is selected from a dropdown in the related mounter dropdown all mounters on pages that use Work Order Panel
     * who have that cost pool set will be automatically selected.
     */
    public static readonly CostPoolMounterAutoSelectInWorkOrdersPage = "COST_POOL_MOUNTER_AUTO_SELECT_IN_WORK_ORDERS_PAGE" as const;


    /**
     * If enabled when a cost pool is selected from a dropdown in the related mounter dropdown all mounters on pages that don't use Work Order Panel
     * who have that cost pool set will be automatically selected.
     */
    public static readonly CostPoolMounterAutoSelect = "COST_POOL_MOUNTER_AUTO_SELECT" as const;
    
    /**
     * Enables sending work order notifications to external personnel
     */
    public static readonly ExternalNotifications = "EXTERNAL_NOTIFICATIONS" as const;

    /**
     * If enabled, it will be possible to assign a cost pool to the work order
     */
    public static readonly WorkOrderCostPool: string = "WORK_ORDER_COST_POOL";

    /**
     * If enabled, will display a "debug" view of organization contract external ids in user management for contact people.
     */
    public static readonly ShowContactPersonExternalIds: string = "SHOW_CONTACT_PERSON_EXTERNAL_IDS" as const;

    /**
     * If enabled, construction sites  are integrated from Task to ERP.
     */
    public static readonly ConstructionSiteIntegration: string = "CONSTRUCTION_SITE_INTEGRATION" as const;

    /// <summary>
    /// If enabled, create order request will be sent upon work order invoicing.
    /// </summary>
    public static readonly SendCreateOrderRequest: string = "SEND_CREATE_ORDER_REQUEST" as const;

    /**
     * If enabled, work order's date/time fields will have 15 minute accuracy instead of one hour.
     */
    public static readonly WorkOrder15MinuteAccuracy: string = "WORK_ORDER_15_MINUTE_ACCURACY" as const;

    /**
     * If enabled, users can log in using Azure Active Directory single sign on.
     */
    public static readonly AzureAdSso: string = "AZURE_AD_SSO" as const;

    /**
     * If enabled, users can get a work report with comments.
     * Typically, this will be enabled together with
     * @see UserSalaryHoursComment
     */
    public static readonly WorkReportWithComments: string = "WORK_REPORT_WITH_COMMENTS" as const;

    /**
     * If enabled, sales person user can use the app.
     */
    public static readonly SalesPerson: string = "SALES_PERSON" as const;

    /**
     * If enabled an information bar will be displayed to the users.
     * Text is controlled in admin settings.
     */
    public static readonly InfoBar: string = "INFO_BAR" as const;

    /**
     * If enabled it's indicating that an update has been completed recently and an information
     * text should be displayed to the user if INFO_BAR is enabled also.
     * Text is controlled in admin settings.
     */
    public static readonly ApplicationUpdated: string = "APPLICATION_UPDATED" as const;

    /**
     * If enabled mounters can edit only their personal hours and not others.
     */
    public static readonly PersonalMounterHours: string = "PERSONAL_MOUNTER_HOURS" as const;

    /**
     * If enabled the user will be redirected to the copied work order when copying from the work order page.
     */
    public static readonly RedirectToCopiedWorkOrder: string = "REDIRECT_TO_COPIED_WORK_ORDER" as const;
}