import {AthenaeumConstants, GeoCoordinate} from "@renta-apps/athenaeum-toolkit";

export default class RentaTaskConstants extends AthenaeumConstants {

    public static readonly applicationName: string = "RentaTask";

    public static readonly maxHoursPerDay = 24;

    public static readonly canModifyHoursRange = 24;
    
    public static readonly newsItemDescriptionMaxLength = 512;

    // 30 MB
    public static readonly maxFileUploadSizeInBytes: number = 30 * 1024 * 1024;

    // 50 MB
    public static readonly maxImageRequestSizeInBytes: number = 50 * 1024 * 1024;

    public static readonly imageFileTypes: string[] = ["image/gif", "image/jpeg", "image/png"];

    public static readonly pdfFileTypes: string[] = ["application/pdf"];

    public static readonly microsoftWordFileTypes: string[] = [
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
    ];

    public static readonly microsoftExcelFileTypes: string[] = [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
    ];

    public static readonly microsoftPowerPointFileTypes: string[] = [
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.template",
    ];


    public static readonly microsoftOfficeFileTypes: string[] =
        RentaTaskConstants.microsoftWordFileTypes
            .concat(RentaTaskConstants.microsoftExcelFileTypes)
            .concat(RentaTaskConstants.microsoftPowerPointFileTypes);

    public static readonly openOfficeTextFileTypes: string[] = [
        "application/vnd.oasis.opendocument.text",
        "application/vnd.oasis.opendocument.text-template",
        "application/vnd.oasis.opendocument.text-web",
        "application/vnd.oasis.opendocument.text-master",
    ];

    public static readonly openOfficeSpreadsheetFileTypes: string[] = [
        "application/vnd.oasis.opendocument.spreadsheet",
        "application/vnd.oasis.opendocument.spreadsheet-template",
    ];

    public static readonly openOfficePresentationFileTypes: string[] = [
        "application/vnd.oasis.opendocument.presentation",
        "application/vnd.oasis.opendocument.presentation-template",
    ];

    public static readonly openOfficeFileTypes: string[] =
        RentaTaskConstants.openOfficeTextFileTypes
            .concat(RentaTaskConstants.openOfficeSpreadsheetFileTypes)
            .concat(RentaTaskConstants.openOfficePresentationFileTypes);
    
    public static readonly allAttachmentFileTypes: string[] = 
        RentaTaskConstants.imageFileTypes
            .concat(RentaTaskConstants.pdfFileTypes)
            .concat(RentaTaskConstants.microsoftOfficeFileTypes)
            .concat(RentaTaskConstants.openOfficeFileTypes);

    /**
     * Types which can be previewed in browser.
     */
    public static readonly previewFileTypes: string[] = RentaTaskConstants.imageFileTypes.concat(RentaTaskConstants.pdfFileTypes);
    
    public static readonly alertCloseDelay = 5000;

    public static readonly alertAnimationDelay = 500;

    public static readonly defaultLocation: GeoCoordinate = new GeoCoordinate(60.192059, 24.945831);

    // "If user has been signed in more than 15 hours straight throw user out"
    public static readonly signOutExpirationTimeOut: number = 15;

    public static readonly userInteraction = {
        workOrderEquipmentModified: "workOrderEquipmentModified",

        initialWorkOrderHashCode: "initialWorkOrderHashCode",

        currentWorkOrderHashCode: "currentWorkOrderHashCode",

        initialFormHashCode: "initialFormHashCode",

        currentFormHashCode: "currentFormHashCode",
    };

    // 255
    public static readonly maxTitleDescriptionLength: number = 255;

    public static readonly bigStringLength = 10000 as const;

    public static readonly dbKeyLength = 255 as const;

    /**
     * Extensions of image file types supported by the back end.
     */
    public static readonly supportedImageFileExtensions = [
        ".png",
        ".tiff",
        ".jpg",
        ".jpeg",
        ".pbm",
        ".tga",
        ".webp",
        ".gif",
        ".bmp"
    ] as const;

    /** Default number for pagination in grids when using pagination. */
    public static readonly paginationNumber = 100 as const;
}