import React from "react";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import {ch} from "@renta-apps/athenaeum-react-common";
import { Button, ButtonType, BaseExpandableWidget, IBaseWidgetProps } from "@renta-apps/athenaeum-react-components";
import UserSalaryHour from "@/models/server/UserSalaryHour";
import DailyHoursModal from "@/pages/RentaTasks/HoursAndDistances/DailyHoursWidget/DailyHoursModal/DailyHoursModal";
import User from "@/models/server/User";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "@/localization/Localizer";
import {FeatureSwitch} from "@/components/FeatureSwitch/FeatureSwitch";
import FeatureFlags from "@/helpers/FeatureFlags";
import SaveUserSalaryHourRequest from "@/models/server/requests/SaveUserSalaryHourRequest";
import AddMounterHoursRequest from "@/models/server/requests/AddMounterHoursRequest";
import WorkOrderModel from "@/models/server/WorkOrderModel";
import RentaTasksController from "@/pages/RentaTasks/RentaTasksController";
import UnleashHelper from "@/helpers/UnleashHelper";

import styles from "./DailyHoursWidget.module.scss";

interface IDailyHoursWidgetProps extends IBaseWidgetProps {
    workOrder: WorkOrderModel;
    hours: UserSalaryHour[];
    myHours?: UserSalaryHour;
    mounters: User[];
}

export default class DailyHoursWidget extends BaseExpandableWidget<IDailyHoursWidgetProps> {

    private readonly _modalRef: React.RefObject<DailyHoursModal> = React.createRef();
    private _hours: UserSalaryHour[] = this.processHours(this.props.hours);
    private _lastSelectedUser: User = ch.getUser();

    private get canEditOnlyOwnHours(): boolean {
        return UnleashHelper.isEnabled(FeatureFlags.PersonalMounterHours) && !RentaTasksController.isManagerOrHigher;
    }
    
    private processHours(hours: UserSalaryHour[]): UserSalaryHour[] {
        const myHours: UserSalaryHour | null = this.myHours;
        if ((myHours) && (!hours.some(item => (item.userId == myHours.userId) && (item.day.equals(myHours.day))))) {
            hours.unshift(myHours);
        }
        const emptyItems: UserSalaryHour[] = hours.where(item => (item.normalHours == 0) && (item.overtime50Hours == 0) && (item.overtime100Hours == 0));
        hours.remove(emptyItems);
        hours.order(item => -item.day.valueOf(), item => TransformProvider.toString(item.user));
        return hours;
    }

    private async editHourAsync(hour: UserSalaryHour): Promise<void> {
        const currentUserEditingSelf: boolean = hour.userId === ch.getUserId();

        if (!this.canEditOnlyOwnHours || currentUserEditingSelf) {
            await this._modalRef.current!.openAsync(hour);
        }
    }

    private async newHourAsync(): Promise<void> {
        const lastSelectedUser: User = this._lastSelectedUser;

        const hour = new UserSalaryHour();
        hour.workOrderId = this.props.workOrder.id;
        hour.day = Utility.today();
        hour.user = lastSelectedUser;
        hour.userId = lastSelectedUser.id;
        hour.normalHours = 8;

        await this._modalRef.current!.openAsync(hour);
    }

    private async saveHourAsync(hour: UserSalaryHour): Promise<void> {

        this._lastSelectedUser = hour.user || ch.getUser();

        const hourToDelete: boolean = (hour.normalHours === 0) && (hour.overtime50Hours === 0) && (hour.overtime100Hours === 0);
        const emptyHour: boolean = hourToDelete && !hour.id;
        if (emptyHour) {
            return;
        }

        if (this.hours.includes(hour)) {
            this.hours.remove(hour);
        }
        
        if (hourToDelete) {
            await this.postAsync("api/rentaTasks/deletePermanentlyUserSalaryHour", hour.id);
        } else {
            if (!hour.id) {
                const request: AddMounterHoursRequest = {
                    day: hour.day,
                    userId: hour.userId,
                    workOrderId: hour.workOrderId!,
                    normalHours: hour.normalHours,
                    overtime50Hours: hour.overtime50Hours!,
                    overtime100Hours: hour.overtime100Hours!,
                    hoursPrice: this.props.workOrder.hoursPrice,
                    alarmJobPrice: hour.alarmJobPrice,
                    isAlarmJob: hour.isAlarmJob,
                    comment: hour.comment?.trim() || null
                };

                hour = await this.postAsync<Promise<UserSalaryHour>>("api/rentaTasks/addMounterHours", request);
            } else {
                const request: SaveUserSalaryHourRequest = {
                    userSalaryDayId: hour.userSalaryDay!.id,
                    userSalaryHourId: hour.id,
                    constructionSiteOrWarehouseId: hour.ownerId,
                    workOrderId: hour.workOrderId!,
                    normalHours: hour.normalHours,
                    overtime50Hours: hour.overtime50Hours,
                    overtime100Hours: hour.overtime100Hours,
                    hoursPrice: hour.hoursPrice,
                    isAlarmJob: hour.isAlarmJob,
                    AlarmJobPrice: hour.alarmJobPrice,
                    comment: hour.comment?.trim() || null
                };

                await this.postAsync("api/rentaTasks/saveUserSalaryHour", request);
            }
        }

        const sameHour: UserSalaryHour | null = this.hours.find(item => (hour.userId == item.userId) && (hour.day.equals(item.day))) || null;
        if (sameHour) {
            // update existing day
            sameHour.normalHours = hour.normalHours;
            sameHour.overtime50Hours = hour.overtime50Hours;
            sameHour.overtime100Hours = hour.overtime100Hours;
            sameHour.comment = hour.comment;
        } else if (!this.hours.includes(hour)) {
            // add new day
            this.hours.push(hour);
        }

        this._hours = this.processHours(this._hours);

        await this.reRenderAsync();
    }

    private get isModalOpen(): boolean {
        return ((this._modalRef.current != null) && (this._modalRef.current!.isOpen));
    }

    protected getLabel(): string | null {
        return super.getLabel() || Localizer.dailyHoursWidgetLabel;
    }

    protected getDescription(): string | null {
        return super.getDescription() || Localizer.dailyHoursWidgetDescription;
    }

    protected getNumber(): string {
        const totalHours: number = this.totalNormalHours + this.overtimeTotalHours;

        return (this.minimized)
            ? "{0:0.0}".format(this.totalNormalHours + this.overtimeTotalHours)
            : "{0:0.0}<small>/{1:0.0}</small> {2}".format(this.totalNormalHours, totalHours, Localizer.genericAbbreviationsHours);
    }

    protected getInnerClassName(): string {
        return styles.dailyHoursWidget;
    }

    protected async onClickAsync(e: React.MouseEvent): Promise<void> {
        const canToggle: boolean = (!this.isModalOpen) && ((!this.contentVisible) || (Utility.clickedOutside(e.target as Node, `${this.id}_extended`)));
        if (canToggle) {
            await super.onClickAsync(e);
        }
    }

    public async onGlobalClick(e: React.SyntheticEvent): Promise<void> {
        const canCollapse: boolean = (this.contentVisible) && (!this.isModalOpen);
        if (canCollapse) {
            await super.onGlobalClick(e);
        }
    }

    public get myHours(): UserSalaryHour | null {
        return this.props.myHours || null;
    }

    public get hours(): UserSalaryHour[] {
        return this._hours;
    }

    public get mounters(): User[] {
        const currentUserId: string = ch.getUserId();
        return this.props.mounters?.filter(mounter => !this.canEditOnlyOwnHours || mounter.id === currentUserId) || [];
    }

    public get totalNormalHours(): number {
        return this.hours.sum(item => item.normalHours);
    }

    public get overtimeTotalHours(): number {
        const totalOvertime50hours: number = this.hours.sum(item => item.overtime50Hours);
        const totalOvertime100hours: number = this.hours.sum(item => item.overtime100Hours);

        return totalOvertime50hours + totalOvertime100hours
    }

    protected renderHour(index: number, hour: UserSalaryHour, displayAlarmJob: boolean): React.ReactNode {
        return (
            <div key={index} className={styles.hourItem} onClick={async () => await this.editHourAsync(hour)}>
                <div className={this.css(styles.row1)}>
                    <span className={styles.user}>{TransformProvider.toString(hour.user)}</span>
                    <span className={styles.day}>{"{0:dd.MM.yyyy}".format(hour.day)}</span>
                </div>
                <div className={styles.row2}>
                    <div className={styles.hours}>
                        <span>{"{0:0.0} {1}".format(hour.normalHours, Localizer.genericAbbreviationsHours)}</span>
                        <span>{Localizer.dailyHoursWidgetLabelsNormal}</span>
                    </div>
                    <div className={styles.hours}>
                        <span>{"{0:0.0} {1}".format(hour.overtime50Hours, Localizer.genericAbbreviationsHours)}</span>
                        <span>50%</span>
                    </div>
                    <div className={styles.hours}>
                        <span>{"{0:0.0} {1}".format(hour.overtime100Hours, Localizer.genericAbbreviationsHours)}</span>
                        <span>100%</span>
                    </div>
                    {
                        (displayAlarmJob) &&
                        (
                            <FeatureSwitch flagName={FeatureFlags.AlarmJobs}>
                                <div className={styles.hours}>
                                    <span>{(hour.isAlarmJob) ? "✓" : "-"}</span>
                                    <span>{Localizer.genericAlarmJob}</span>
                                </div>
                            </FeatureSwitch>
                        )
                    }
                </div>
                <FeatureSwitch flagName={FeatureFlags.UserSalaryHoursComment}>
                    {
                        (hour.comment) &&
                        <div className={styles.row3}>
                            <div className={styles.comment}>
                                <span>{hour.comment}</span>
                                <span>{Localizer.genericComment}</span>
                            </div>
                        </div>
                    } 
                </FeatureSwitch>
            </div>
        )
    }

    protected renderExpanded(): React.ReactNode {
        const noDistancesStyle = (this.hours.length == 0) && (styles.noDistances);
        const anyAlarmJobs: boolean =  this.hours.some(hour => hour.isAlarmJob);
        return (
            <div id={`${this.id}_extended`} className={styles.extended}>

                <div className={styles.distances}>
                    { this.hours.map((hour, index) => this.renderHour(index, hour, anyAlarmJobs)) }
                </div>

                <div id="addUserHours" className={this.css(styles.addItem, noDistancesStyle)} onClick={async () => await this.newHourAsync()}>
                    <Button icon={{name: "fas plus"}} type={ButtonType.Orange} />
                    <span>{Localizer.dailyHoursWidgetButtonsAddHours}</span>
                </div>

                <DailyHoursModal ref={this._modalRef}
                                 workOrder={this.props.workOrder}
                                 mounters={this.mounters}
                                 onChange={async (_, hour) => await this.saveHourAsync(hour)}
                />

            </div>
        )
    }
};