import React from "react";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import {BaseAsyncComponent, ch, IBaseAsyncComponentState, IBaseComponent} from "@renta-apps/athenaeum-react-common";
import WorkOrderFiltersData from "@/models/server/WorkOrderFiltersData";
import WorkOrdersFiltersModal from "@/pages/RentaTasks/WorkOrders/WorkOrdersFiltersModal/WorkOrdersFiltersModal";
import WorkOrderModel from "@/models/server/WorkOrderModel";
import ListActiveWorkOrdersRequest from "@/models/server/requests/ListActiveWorkOrdersRequest";
import {Button, ButtonType, Icon, IconSize} from "@renta-apps/athenaeum-react-components";
import UserContext from "@/models/server/UserContext";
import UserInteractionDataStorage from "@/providers/UserInteractionDataStorage";
import TransformProvider from "@/providers/TransformProvider";
import RentaTasksController from "@/pages/RentaTasks/RentaTasksController";
import Localizer from "@/localization/Localizer";
import throwIfFalsy from "@/functions/ThrowIfFalsy";

import styles from "./WorkOrdersList.module.scss";

interface IWorkOrdersListProps {
    title: string;
    modalTitle: string;
    noDataText: string;
    request: ListActiveWorkOrdersRequest;
    filtersData: WorkOrderFiltersData;

    fetchData(sender: IBaseComponent, request: ListActiveWorkOrdersRequest): Promise<WorkOrderModel[]>;

    onWorkOrderOpen(sender: IBaseComponent, workOrder: WorkOrderModel): Promise<void>;
}

export interface IWorkOrdersListState extends IBaseAsyncComponentState<WorkOrderModel[]> {
    request: ListActiveWorkOrdersRequest;
}

export default class WorkOrdersList extends BaseAsyncComponent<IWorkOrdersListProps, IWorkOrdersListState, WorkOrderModel[]> {

    state: IWorkOrdersListState = {
        data: null,
        isLoading: false,
        request: this.props.request
    };

    private readonly _filtersModalRef: React.RefObject<WorkOrdersFiltersModal> = React.createRef();

    private getDate(workOrder: WorkOrderModel): string | null {
        const date: Date | null = this.props.request.getDate(workOrder);
        return (date)
            ? Utility.toDateShortTimeString(date)
            : "";
    }

    private async openFiltersAsync(): Promise<void> {
        await this._filtersModalRef.current!.openAsync();
    }

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    private async submitFiltersAsync(request: ListActiveWorkOrdersRequest): Promise<void> {

        if (this.userContext.userSpecificFilters) {
            UserInteractionDataStorage.set("filter-name", request.name);
            UserInteractionDataStorage.set("filter-managersIds", request.managersIds);
            UserInteractionDataStorage.set("filter-employeesIds", request.employeesIds);
            UserInteractionDataStorage.set("filter-constructionSiteOrWarehouseId", request.constructionSiteOrWarehouseId);
            UserInteractionDataStorage.set("filter-statuses", request.statuses);
            UserInteractionDataStorage.set("filter-organizationContractId", request.organizationContractId);
            UserInteractionDataStorage.set("filter-blockedByForm", request.hideBlockedByForm);
        }

        await this.setState({request});
        await this.reloadAsync();
    }
    
    private async submitMyWorkOrdersFilter(): Promise<void> {
        this.state.request.clear();
        this.state.request.employeesIds.push(this.userContext.user!.id);
        await this.submitFiltersAsync(this.state.request);
    }

    private async onItemClickAsync(workOrder: WorkOrderModel): Promise<void> {
        throwIfFalsy(workOrder, nameof(workOrder));

        await this.props.onWorkOrderOpen(this, workOrder);
    }

    protected async fetchDataAsync(): Promise<WorkOrderModel[]> {
        return this.props.fetchData(this, this.state.request);
    }

    protected getEndpoint(): string {
        return "";
    }

    protected getIconName(): string {
        return (this.state.request.isEmpty())
            ? "far filter"
            : "fas filter";
    }

    public isAsync(): boolean {
        return true;
    }

    public renderRow(workOrder: WorkOrderModel): React.ReactNode {
        if (!workOrder) {
            return null;
        }

        const address: string = (workOrder.owner?.location)
            ? TransformProvider.toString(workOrder.owner.location)
            : Localizer.workOrdersRowsNoAddress;

        const organizationInfo: string = (workOrder.owner?.organizationContract)
            ? `${TransformProvider.toString(workOrder.owner.organizationContract.name)}, ${TransformProvider.toString(workOrder.owner.name)}`
            : "";

        const isSignedIn: boolean | null = (RentaTasksController.isSignedIn) && (RentaTasksController.mounterContextWorkOrder?.id === workOrder.id);

        const signedInStyle: string | false | null = (isSignedIn) && (styles.signedIn);

        return (
            <div key={workOrder.id}
                 className={this.css(styles.workOrderListItem, signedInStyle, "cursor-pointer", "work-order-list-item")}
                 onClick={async () => await this.onItemClickAsync(workOrder)}
            >

                <div className={styles.left}>
                    <div className={this.css(styles.multiLines, styles.topSpace)}>
                        <span>{workOrder.name}</span>
                        <span>{organizationInfo}</span>
                        <span>{address}</span>
                    </div>
                </div>

                <div className={styles.icons}>
                    <Icon {...WorkOrderModel.getStateWithBlockingIcon(workOrder, true)}
                          size={IconSize.Large}
                    />
                </div>

                <span>
                    {this.getDate(workOrder)}
                </span>
            </div>
        )
    }

    public render(): React.ReactNode {

        return (
            <div id="workOrdersList" className={this.css(styles.workOrdersList)}>

                <div>
                    <div className={this.css(styles.header)}>
                        <span>
                            {this.toMultiLines(this.props.title)}
                        </span>
                        
                        <div>
                            <Button label={Localizer.workOrdersButtonMyWorkOrders}
                                    type={ButtonType.Blue}
                                    small
                                    className={this.css(styles.filter)}
                                    onClick={async () => await this.submitMyWorkOrdersFilter()}
                            />
                            
                            <Button icon={{name: this.getIconName(), size: IconSize.X2}}
                                    type={ButtonType.Blue}
                                    small
                                    className={this.css(styles.filter)}
                                    onClick={async () => await this.openFiltersAsync()}
                            />
                        </div>
                    </div>
                </div>

                {
                    (!this.isLoading) && ((!this.state.data) || (this.state.data.length <= 0)) &&
                    (
                        <div className={this.css(styles.workOrderListItem, styles.noItems)}>
                            {this.props.noDataText}
                        </div>
                    )
                }

                {
                    (this.state.data) &&
                    (
                        <div>
                            {
                                this.state.data.map((workOrder) => this.renderRow(workOrder))
                            }
                        </div>
                    )
                }

                <WorkOrdersFiltersModal ref={this._filtersModalRef}
                                        title={this.props.modalTitle}
                                        request={this.state.request}
                                        filtersData={this.props.filtersData}
                                        onSubmit={async (_, request) => await this.submitFiltersAsync(request)}
                />

            </div>
        );
    }
};