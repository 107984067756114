import React from "react";
import {BasePageParameters, ch, PageRoute, PageRouteProvider, ServerError} from "@renta-apps/athenaeum-react-common";
import AnonymousPage from "../../models/base/AnonymousPage";
import PageDefinitions from "../../providers/PageDefinitions";
import {IContactSupportParameters} from "../ContactSupport/ContactSupport";
import Localizer from "../../localization/Localizer";
import {Button, ButtonType, PageContainer, PageHeader, PageRow} from "@renta-apps/athenaeum-react-components";
import {Utility} from "@renta-apps/athenaeum-toolkit";

import styles from "./Error.module.scss";

export interface IErrorPageParameters extends BasePageParameters {
    error: ServerError | null;
}

export default class ErrorPage extends AnonymousPage<IErrorPageParameters> {

    private get error(): ServerError | null {
        return (this.props.parameters != null) && (this.props.parameters.error != null)
            ? this.props.parameters.error
            : null;
    }

    private async redirectToContactPage(): Promise<void> {
        const route = new PageRoute(PageDefinitions.contactSupportRouteName);
        if (this.error) {
            const parameters = {} as IContactSupportParameters;
            parameters.requestId = this.error!.requestId;
            route.parameters = parameters;
        }
        await PageRouteProvider.redirectAsync(route);
    }

    public render(): React.ReactNode {

        return (
            <PageContainer className={styles.error}>
                <PageHeader title={Localizer.errorPageTitle} />

                <PageRow>
                    <div className="col">
                        {
                            (this.error) &&
                            (
                                <React.Fragment>
                                    <div>
                                        <p>{Localizer.errorPageErrorMessage}</p>

                                        <ul>
                                            {
                                                (ch.findUser()) &&
                                                <li>{Localizer.errorPageUserName}{ch.getUser().username}</li>
                                            }
                                            {
                                                (this.error.requestId) &&
                                                <li>{Localizer.errorPageRequestId}{this.error.requestId}</li>
                                            }
                                            <li>{Localizer.errorPageDateAndTime}{Utility.toLongISOString(new Date())}</li>
                                        </ul>

                                            <Button
                                                
                                                type={ButtonType.Orange}
                                                label={Localizer.errorPageContactSupport}
                                                onClick={async () => await this.redirectToContactPage()}
                                            />
                                    </div>
                                    {
                                        (this.error!.debugDetails) &&
                                        (
                                            <p className={styles.debugDetails}>{this.error!.debugDetails}</p>
                                        )
                                    }
                                </React.Fragment>
                            )
                        }
                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}