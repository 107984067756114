import React from "react";
import {FileModel, IPagedList, Utility} from "@renta-apps/athenaeum-toolkit";
import {BasePageParameters, ch} from "@renta-apps/athenaeum-react-common";
import {CellAction, CellModel, ColumnActionType, ColumnDefinition, ColumnType, Grid, GridHoveringType, GridModel, GridOddType, ImageModal, ModalSize, PageContainer, PageHeader, PageRow, RowModel} from "@renta-apps/athenaeum-react-components";
import User from "@/models/server/User";
import AnonymousPage from "../../models/base/AnonymousPage";
import {ActionType, CustomerApprovalType, WorkOrderStatus} from "@/models/Enums";
import WorkOrderModel from "../../models/server/WorkOrderModel";
import {WorkOrderAttachmentsGrid} from "@/components/WorkOrderAttachmentsGrid/WorkOrderAttachmentsGrid";
import WorkReportAttachment from "@/models/server/WorkReportAttachment";
import ListWorkOrderAttachmentsRequest from "@/models/server/requests/ListWorkOrderAttachmentsRequest";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";
import DeclineMyWorkOrderResponse from "@/models/server/responses/DeclineMyWorkOrderResponse";
import ApproveMyWorkOrderResponse from "@/models/server/responses/ApproveMyWorkOrder";
import GetContactPersonWorkOrdersRequest from "@/models/server/requests/GetContactPersonWorkOrdersRequest";
import WorkOrderChangeStatusModal, {ChangeStatusAction} from "@/components/WorkOrderChangeStatusModal/WorkOrderChangeStatusModal";
import Comparator from "@/helpers/Comparator";
import {DeclineWorkOrderRequest} from "@/models/server/requests/DeclineWorkOrderRequest";
import WorkOrderProvider from "@/providers/WorkOrderProvider";
import Localizer from "../../localization/Localizer";
import isNullOrDefaultGuid from "@/functions/IsNullOrDefaultGuid";

export interface IMyWorkReportsParameters extends BasePageParameters {
    workOrderId?: string;
    openConfirmationModal?: boolean;
}

export default class MyWorkReports extends AnonymousPage<IMyWorkReportsParameters> {

    // Fields

    private readonly _workReportsGridRef: React.RefObject<Grid<WorkOrderModel>> = React.createRef();
    private readonly _previewPictureDocumentRef: React.RefObject<ImageModal> = React.createRef();
    private readonly _changeStatusModalRef: React.RefObject<WorkOrderChangeStatusModal> = React.createRef();

    private readonly _workReportsColumns: ColumnDefinition[] = [
        {
            header: Localizer.tasksPanelCodeLanguageItemName,
            accessor: nameof<WorkOrderModel>(wo => wo.code),
            minWidth: 65,
            noWrap: true,
            className: "grey",
            actions: [
                {
                    title: Localizer.workOrdersShowDetailsTitleLanguageItemName,
                    type: ColumnActionType.Details,
                    callback: async (cell) => await this.toggleDetailsAsync(cell)
                }
            ]
        },
        {
            header: Localizer.tasksPanelNameLanguageItemName,
            accessor: nameof<WorkOrderModel>(wo => wo.name),
            minWidth: 140,
        },
        {
            header: Localizer.genericNameAndProjectLanguageItemName,
            group: Localizer.genericConstructionsiteLanguageItemName,
            accessor: nameof.full<WorkOrderModel>(wo => wo.owner!.name),
            minWidth: 140,
            maxWidth: 140,
            noWrap: true,
            settings: {
                infoAccessor: "owner.externalId"
            },
        },
        {
            header: Localizer.formInputAddressLanguageItemName,
            group: Localizer.genericConstructionsiteLanguageItemName,
            accessor: nameof.full<WorkOrderModel>(wo => wo.owner!.location!.formattedAddress),
            type: ColumnType.Address,
            minWidth: 140,
        },
        {
            header: Localizer.tasksPanelTypeLanguageItemName,
            group: Localizer.genericApprovalLanguageItemName,
            accessor: nameof<WorkOrderModel>(wo => wo.approvalType),
            format: nameof<CustomerApprovalType>(),
            minWidth: 80,
        },
        {
            accessor: nameof<WorkOrderModel>(wo => wo.sentAt),
            header: Localizer.tasksPanelSentAtLanguageItemName,
            group: Localizer.genericApprovalLanguageItemName,
            format: "D",
            minWidth: 80,
        },
        {
            accessor: nameof<WorkOrderModel>(wo => wo.approvedAt),
            header: Localizer.myWorkReportApprovedAtLanguageItemName,
            group: Localizer.genericApprovalLanguageItemName,
            format: "D",
            minWidth: 80,
        },
        {
            header: Localizer.tasksPanelActionsLanguageItemName,
            minWidth: 140,
            init: (cell) => this.initWorkReportOperations(cell),
            actions: [
                {
                    name: "approve",
                    title: Localizer.tasksPanelApproveWorkReportInvoiceLanguageItemName,
                    icon: "far thumbs-up",
                    type: ActionType.Create,
                    confirm: (cell: CellModel<WorkOrderModel>) => Utility.format(Localizer.myWorkReportsConfirmationApprove, cell.model.code),
                    callback: async (cell, action) => await this.processWorkReportOperationAsync(cell, action)
                },
                {
                    name: "decline",
                    title: Localizer.myWorkReportDeclineWorkReportLanguageItemName,
                    icon: "far thumbs-down",
                    type: ActionType.Delete,
                    callback: async (cell, action) => await this.processWorkReportOperationAsync(cell, action)
                },
                {
                    title: Localizer.tasksPanelPreviewWorkReportLanguageItemName,
                    type: ColumnActionType.Preview,
                    right: true,
                    callback: async (cell, action) => await this.processWorkReportOperationAsync(cell, action)
                },
                {
                    title: Localizer.tasksPanelDownloadWorkReportLanguageItemName,
                    type: ColumnActionType.Download,
                    right: true,
                    callback: async (cell, action) => await this.processWorkReportOperationAsync(cell, action)
                }
            ]
        },
    ];


    // Properties

    private get workReportsGrid(): GridModel<WorkOrderModel> {
        return this._workReportsGridRef.current!.model;
    }

    private get changeStatusModal(): WorkOrderChangeStatusModal {
        return this._changeStatusModalRef.current!;
    }

    public get parameters(): IMyWorkReportsParameters | null {
        return (this.props.parameters != null)
            ? this.props.parameters
            : null;
    }


    // Methods

    private initWorkReportsRow(row: RowModel<WorkOrderModel>): void {

        const model: WorkOrderModel = row.model;

        const approved: boolean = model.approved;
        const declined: boolean = model.declined;

        row.className = (approved)
            ? "bg-approved"
            : (declined)
                ? "bg-declined"
                : "";
    }

    private initWorkReportOperations(cell: CellModel<WorkOrderModel>): void {
        const model: WorkOrderModel = cell.row.model;
        const canBeApprovedOrRejected: boolean = model.currentStatus === WorkOrderStatus.SentToCustomer;
        const approved: boolean = model.approved;
        const declined: boolean = model.declined;

        const approveAction: CellAction<WorkOrderModel> = cell.actions[0];
        const declineAction: CellAction<WorkOrderModel> = cell.actions[1];
        const previewAction: CellAction<WorkOrderModel> = cell.actions[2];
        const downloadAction: CellAction<WorkOrderModel> = cell.actions[3];

        approveAction.visible = (canBeApprovedOrRejected) && (!approved) && (!declined);
        declineAction.visible = (canBeApprovedOrRejected) && (!approved) && (!declined);
        previewAction.visible =  true;
        downloadAction.visible = true;
    }

    private async getWorkReportsAsync(pageNumber: number, pageSize: number): Promise<IPagedList<WorkOrderModel>> {
        const request: GetContactPersonWorkOrdersRequest = new GetContactPersonWorkOrdersRequest();

        request.pageNumber = pageNumber;
        request.pageSize = pageSize;

        return WorkOrderProvider.getContactPersonWorkOrdersPagedListAsync(this.workReportsGrid, request);
    }

    private async getAttachmentsAsync(workOrderId: string, pageNumber: number, pageSize: number): Promise<IPagedList<WorkReportAttachment>> {
        const request = new ListWorkOrderAttachmentsRequest();
        request.workOrderId = workOrderId;
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;

        return await this.postAsync("/api/myWorkOrders/getWorkOrderAttachments", request);
    }

    private async toggleDetailsAsync(cell: CellModel<WorkOrderModel>): Promise<void> {
        const spannedRows: RowModel<WorkOrderModel>[] = cell.spannedRows;
        const rowToExpand: RowModel<WorkOrderModel> = spannedRows[spannedRows.length - 1];
        await rowToExpand.toggleAsync();
    }

    private async processWorkReportOperationAsync(cell: CellModel<WorkOrderModel>, action: CellAction<WorkOrderModel>): Promise<void> {

        const model: WorkOrderModel = cell.model;

        switch (action.action.name) {
            case "preview":
                await this.previewWorkReportPdfAsync(cell);
                break;
            case "download":
                const file: FileModel = await this.postAsync("api/myWorkOrders/getWorkReportPdf", model.id);
                ch.download(file);
                break;
            case "approve":
                model.approved = true;
                model.approvedAt = Utility.now();
                model.approvedBy = ch.getUser<User>();
                model.declined = false;
                model.declinedAt = null;
                model.declinedBy = null;

                const approveResponse: ApproveMyWorkOrderResponse = await cell.grid.postAsync("api/myWorkOrders/approveMyWorkOrder", model.id);

                if (approveResponse.success) {

                    await this.alertMessageAsync(approveResponse.message);

                } else {

                    await this.alertErrorAsync(approveResponse.message);

                }

                await cell.row.setModelAsync(approveResponse.workOrder);
                break;
            case "decline":
                await this.changeStatusModal.openAsync(model);
                break;
        }
    }

    private async declineWorkOrderAsync(workOrderId: string, comment: string | null): Promise<void> {
        const request = new DeclineWorkOrderRequest();
        request.workOrderId = workOrderId;
        request.comment = comment;
        const declineResponse: DeclineMyWorkOrderResponse = await this.postAsync("api/myWorkOrders/declineMyWorkOrder", request);

        if (declineResponse.success) {
            await this.alertMessageAsync(declineResponse.message);
        } else {
            await this.alertErrorAsync(declineResponse.message);
        }

        const workOrderRow: RowModel<WorkOrderModel> | null = this.workReportsGrid.rows.find(row => Comparator.isEqual(row.model, declineResponse.workOrder)) || null;

        if (!workOrderRow) {
            return;
        }

        workOrderRow.model = declineResponse.workOrder;

        await workOrderRow.setModelAsync(declineResponse.workOrder);
    }

    private async processAttachmentOperationAsync(cell: CellModel<WorkReportAttachment>, action: CellAction<WorkReportAttachment>): Promise<void> {

        const fileEndpoint = "/api/myWorkOrders/getWorkOrderAttachmentFile" as const;
        const fileId: string = cell.model.fileId;

        switch (action.action.name) {
            case "preview":
                const previewFile: FileModel = await this.postAsync(fileEndpoint, fileId);

                if (RentaTaskConstants.imageFileTypes.includes(previewFile.type)) {
                    await this._previewPictureDocumentRef.current?.openAsync(previewFile);
                }
                else {
                    await ch.documentPreviewAsync(fileEndpoint, fileId);
                }
                break;
            case "download":
                const downloadFile: FileModel = await this.postAsync(fileEndpoint, fileId);
                ch.download(downloadFile);
                break;
            default:
                throw new Error(`Unsupported operation '${action.action.name}'`);
        }
    }

    private async previewWorkReportPdfAsync(cell: CellModel<WorkOrderModel>): Promise<void> {
        const workReportId: string = cell.model.id;
        await ch.documentPreviewAsync("api/myWorkOrders/getWorkReportPdf", workReportId, Localizer.documentPreviewModalWorkReportTitle);
    }

    private renderDetailsContent(row: RowModel<WorkOrderModel>) {
        return (
            <WorkOrderAttachmentsGrid readonly
                                      fetchAttachments={async (pageNumber, pageSize) => this.getAttachmentsAsync(row.model.id, pageNumber, pageSize)}
                                      processAttachmentOperation={async (cell, action) => await this.processAttachmentOperationAsync(cell, action)}
            />
        );
    }

    public async initializeAsync(): Promise<void> {
        if ((this.parameters != null) && (this.parameters.workOrderId && !isNullOrDefaultGuid(this.parameters.workOrderId)) && (this.parameters.openConfirmationModal)) {

            const workOrder: WorkOrderModel = await this.postAsync("/api/myWorkOrders/getContactPersonWorkOrder", this.parameters.workOrderId);

            if (this._changeStatusModalRef.current && !workOrder.declined) {
                await this._changeStatusModalRef.current!.openAsync(workOrder);
            }
        }
    }

    public render(): React.ReactNode {
        return (
            <PageContainer>
                <PageHeader title={Localizer.myWorkReportMyReport}/>

                <PageRow>
                    <div className="col">
                        <Grid pagination={RentaTaskConstants.paginationNumber}
                              ref={this._workReportsGridRef}
                              hovering={GridHoveringType.Row}
                              odd={GridOddType.None}
                              noDataText={Localizer.myWorkReportNoData}
                              columns={this._workReportsColumns}
                              initRow={(row) => this.initWorkReportsRow(row)}
                              renderDetails={(row) => this.renderDetailsContent(row)}
                              fetchData={async (_, pageNumber, pageSize) => await this.getWorkReportsAsync(pageNumber, pageSize)}
                        />

                    </div>
                </PageRow>

                <ImageModal ref={this._previewPictureDocumentRef}
                            size={ModalSize.Large}
                            title={Localizer.formInputFilePreview}
                />

                <WorkOrderChangeStatusModal forceComment
                                            ref={this._changeStatusModalRef}
                                            action={ChangeStatusAction.Decline}
                                            onConfirmAsync={(workOrderId, _, comment) => this.declineWorkOrderAsync(workOrderId, comment)}
                />
            </PageContainer>
        );
    }
}