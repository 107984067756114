import React from "react";
import {BaseComponent, IBaseComponent} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonContainer, ButtonType, Checkbox, Dropdown, DropdownAlign, Form, InlineType, Modal, TextInput, TwoColumns} from "@renta-apps/athenaeum-react-components";
import WorkOrderFiltersData from "@/models/server/WorkOrderFiltersData";
import {Dictionary} from "typescript-collections";
import {WorkOrderStatus} from "@/models/Enums";
import OrganizationContract from "@/models/server/OrganizationContract";
import ConstructionSiteOrWarehouse from "@/models/server/ConstructionSiteOrWarehouse";
import User from "@/models/server/User";
import ListActiveWorkOrdersRequest from "@/models/server/requests/ListActiveWorkOrdersRequest";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "./WorkOrdersFiltersModal.module.scss";

interface IWorkOrdersFiltersModalProps {
    request: ListActiveWorkOrdersRequest;
    filtersData: WorkOrderFiltersData;
    title: string;
    onSubmit(sender: IBaseComponent, request: ListActiveWorkOrdersRequest): Promise<void>;
}

interface IWorkOrdersFiltersModalState {
    request: ListActiveWorkOrdersRequest;
}

export default class WorkOrdersFiltersModal extends BaseComponent<IWorkOrdersFiltersModalProps, IWorkOrdersFiltersModalState> {
    state: IWorkOrdersFiltersModalState = {
        request: this.props.request
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();
    private readonly _ownersRef: React.RefObject<Dropdown<ConstructionSiteOrWarehouse>> = React.createRef();
    private readonly _organizationContractRef: React.RefObject<Dropdown<OrganizationContract>> = React.createRef();
    private readonly _statusesRef: React.RefObject<Dropdown<WorkOrderStatus>> = React.createRef();
    private readonly _employeesRef: React.RefObject<Dropdown<User>> = React.createRef();
    private readonly _managersRef: React.RefObject<Dropdown<User>> = React.createRef();

    private get hasOrganisationContracts(): boolean {
        return this.props.filtersData.organizationContracts.length > 1;
    }

    private async clearAsync(): Promise<void> {
        this.state.request.clear();

        await this.setState({request: this.state.request});

        this._ownersRef.current!.reRender();
        if (this.hasOrganisationContracts){
            this._organizationContractRef.current!.reRender();
        }
        this._statusesRef.current!.reRender();
        this._employeesRef.current!.reRender();
        this._managersRef.current!.reRender();
    }

    private async submitAsync(data: Dictionary<string, any>): Promise<void> {
        this.copyTo(data, this.state.request);

        await this._modalRef.current!.closeAsync();

        await this.props.onSubmit(this, this.state.request);
    }

    public async openAsync(): Promise<void> {
        await this._modalRef.current!.openAsync();
    }

    public render(): React.ReactNode {

        return (
            <Modal id={this.id}
                   ref={this._modalRef}
                   className={styles.workOrdersFiltersModal}
                   title={this.props.title}
            >

                <Form className={styles.form}
                      onSubmit={async (_, data) => await this.submitAsync(data)}
                >

                    <TwoColumns
                        leftClassName="col"
                        rightClassName="col"
                    >
                        <TextInput id="name"
                                   name="name"
                                   label={Localizer.workOrdersFiltersName}
                                   value={this.state.request.name || ""}
                        />

                        <Dropdown multiple noSubtext
                                  name={`statuses`}
                                  ref={this._statusesRef}
                                  label={Localizer.workOrdersFiltersStatuses}
                                  nothingSelectedText={"-"}
                                  transform={(value) => EnumProvider.getWorkOrderStatusItem(value)}
                                  items={this.props.filtersData.statuses}
                                  selectedItems={this.state.request.statuses}
                        />
                    </TwoColumns>

                    <TwoColumns
                        leftClassName="col"
                        rightClassName="col"
                    >
                        {
                            (this.hasOrganisationContracts) &&
                            (
                                <Dropdown autoGroupSelected noSubtext
                                          name={`organizationContractId`}
                                          ref={this._organizationContractRef}
                                          noWrap={this.desktop}
                                          label={Localizer.workOrdersFiltersCustomer}
                                          nothingSelectedText={"-"}
                                          align={DropdownAlign.Left}
                                          items={this.props.filtersData.organizationContracts}
                                          selectedItem={this.state.request.organizationContractId || undefined}
                                />
                            )
                        }

                        {
                            (this.props.filtersData.managers.length > 1) &&
                            (
                                <Dropdown multiple autoGroupSelected noSubtext
                                          name={`managersIds`}
                                          ref={this._managersRef}
                                          label={Localizer.workOrdersFiltersManagers}
                                          nothingSelectedText={"-"}
                                          align={DropdownAlign.Right}
                                          items={this.props.filtersData.managers}
                                          selectedItems={this.state.request.managersIds}
                                />
                            )
                        }
                    </TwoColumns>

                    <TwoColumns
                        leftClassName="col"
                        rightClassName="col"
                    >
                        <Dropdown autoGroupSelected noSubtext
                                  name={`constructionSiteOrWarehouseId`}
                                  ref={this._ownersRef}
                                  noWrap={this.desktop}
                                  label={Localizer.workOrdersFiltersConstructionSite}
                                  nothingSelectedText={"-"}
                                  align={DropdownAlign.Left}
                                  items={this.props.filtersData.owners}
                                  selectedItem={this.state.request.constructionSiteOrWarehouseId || undefined}
                        />

                        <Dropdown multiple autoGroupSelected noSubtext
                                  name={`employeesIds`}
                                  ref={this._employeesRef}
                                  label={Localizer.workOrdersFiltersEmployees}
                                  nothingSelectedText={"-"}
                                  align={DropdownAlign.Right}
                                  items={this.props.filtersData.employees}
                                  selectedItems={this.state.request.employeesIds}
                        />
                    </TwoColumns>

                    <TwoColumns>

                        <Checkbox inline
                                  id="hideBlockedByForm"
                                  name="hideBlockedByForm"
                                  inlineType={InlineType.Right}
                                  label={Localizer.workOrdersFiltersCheckboxHideBlockedByForms}
                                  value={this.state.request.hideBlockedByForm}
                        />

                    </TwoColumns>

                    <ButtonContainer className={this.css(styles.buttons, this.mobile && styles.mobile)}>
                        <Button id={"apply-filters-button"}
                                submit type={ButtonType.Orange}
                                label={Localizer.workOrdersFiltersButtonApply}
                        />

                        <Button id={"clear-filters-button"}
                                type={ButtonType.Blue}
                                label={Localizer.workOrdersFiltersButtonClear}
                                onClick={async () => await this.clearAsync()}
                        />
                    </ButtonContainer>

                </Form>

            </Modal>
        );
    }
};