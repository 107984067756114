import {PageRoute} from "@renta-apps/athenaeum-react-common";
import WorkOrderModel from "../../../models/server/WorkOrderModel";
import {RentaTasksAction} from "../RentaTasksController";
import ConstructionSiteOrWarehouse from "@/models/server/ConstructionSiteOrWarehouse";
import WorkOrderEquipment from "@/models/server/WorkOrderEquipment";
import {CustomerApprovalType} from "@/models/Enums";
import UserSalaryHour from "@/models/server/UserSalaryHour";
import Organization from "@/models/server/Organization";
import WorkOrderExtraCharge from "@/models/server/WorkOrderExtraCharge";
import WorkOrderRentalItemModel from "@/models/server/WorkOrderRentalItemModel";

export default class WizardContext {

    public owner: ConstructionSiteOrWarehouse | null = null;

    public workOrder: WorkOrderModel | null = null;

    public initialWorkOrderData: WorkOrderModel | null = null;

    public nextWorkOrder: WorkOrderModel | null = null;

    public action: RentaTasksAction = RentaTasksAction.None;

    public actionInitialPageRoute: PageRoute | null = null;

    public addEquipment: boolean = false;

    public equipment: WorkOrderEquipment[] | null = null;

    public extraCharges: WorkOrderExtraCharge[] | null = null;

    public rentalEquipments: WorkOrderRentalItemModel[] | null = null;

    public myHours: UserSalaryHour = new UserSalaryHour();

    public mounters: string[] | null = null;

    public approvalType: CustomerApprovalType = CustomerApprovalType.Email;

    /** @see ApproveWorkOrderRequest.signatureSrc */
    public signatureSrc: string | null = null;

    /** @see ApproveWorkOrderRequest.signatureNameClarification */
    public signatureNameClarification: string | null = null;

    public organization: Organization | null = null;
}