import React from "react";
import {ActionType} from "@/models/Enums";
import Subcontractor from "../../../models/server/Subcontractor";
import User from "../../../models/server/User";
import AddSubcontractorModal from "./AddSubcontractorModal/AddSubcontractorModal";
import {
    Button,
    ButtonType,
    CellAction,
    CellModel,
    Checkbox,
    ColumnActionDefinition,
    ColumnDefinition,
    ColumnType,
    DropdownAlign,
    Grid,
    GridHoveringType,
    GridModel,
    GridOddType,
    IconSize,
    Inline,
    Panel,
    RowModel
} from "@renta-apps/athenaeum-react-components";
import ListSubcontractorsRequest from "../../../models/server/requests/ListSubcontractorsRequest";
import SaveSubcontractorRequest from "../../../models/server/requests/SaveSubcontractorRequest";
import {BaseComponent, ch, IBaseComponent, Justify, PageCacheProvider, TextAlign} from "@renta-apps/athenaeum-react-common";
import AddSubcontractorRequest from "@/models/server/requests/AddSubcontractorRequest";
import AddSubcontractorResponse from "@/models/server/responses/AddSubcontractorResponse";
import ConstructionSite from "@/models/server/ConstructionSite";
import {IPagedList, SortDirection, Utility} from "@renta-apps/athenaeum-toolkit";
import UserInteractionDataStorage from "@/providers/UserInteractionDataStorage";
import Localizer from "../../../localization/Localizer";
import GetAssignedSubcontractorMountersRequest from "@/models/server/requests/GetAssignedSubcontractorMountersRequest";
import AssignedMountersWarningModal from "@/pages/ConstructionSiteManagement/SubcontractorsPanel/AssignedMountersWarningModal/AssignedMountersWarningModal";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";

interface ISubcontractorsPanelProps {
    constructionSite: ConstructionSite;
    readonly?: boolean;
    onChange(sender: IBaseComponent): Promise<void>;
}

interface ISubcontractorsPanelState {
    showDeleted: boolean;
    assignedSubcontractorMounters: User[];
}

export default class SubcontractorsPanel extends BaseComponent<ISubcontractorsPanelProps, ISubcontractorsPanelState> {

    state: ISubcontractorsPanelState = {
        showDeleted: false,
        assignedSubcontractorMounters: []
    };

    private readonly _subcontractorsGridRef: React.RefObject<Grid<Subcontractor>> = React.createRef();
    private readonly _addSubcontractorModalRef: React.RefObject<AddSubcontractorModal> = React.createRef();
    private readonly _assignedSubcontractorMountersWarningModal: React.RefObject<AssignedMountersWarningModal> = React.createRef();

    private readonly _subcontractorsColumns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            init: (cell) => this.initNumerationColumn(cell),
            minWidth: 50,
            noWrap: true,
            className: "grey",
            textAlign: TextAlign.Center,
            removable: false,
            settings: {
                descriptionAccessor: (model: Subcontractor) => Subcontractor.deletedSubcontractorInfo(model),
                descriptionJustify: Justify.Right,
                descriptionIcon: "far info",
            },
        } as ColumnDefinition,
        {
            header: Localizer.genericVatIdLanguageItemName,
            accessor: "organization.vatId",
            minWidth: 120,
            sorting: true,
            editable: false,
        } as ColumnDefinition,
        {
            header: Localizer.genericNameLanguageItemName,
            accessor: "name",
            minWidth: 250,
            maxWidth: 350,
            type: ColumnType.Text,
            sorting: true,
            editable: true,
        } as ColumnDefinition,
        {
            header: Localizer.genericAddressLanguageItemName,
            accessor: "location.formattedAddress",
            type: ColumnType.Address,
            minWidth: 250,
            sorting: true,
            noWrap: true,
            editable: true
        } as ColumnDefinition,
        {
            header: Localizer.addConstructionsiteManagerLanguageItemName,
            type: ColumnType.Dropdown,
            settings: {
                fetchItems: async (cell: CellModel<Subcontractor>) => this.getSubcontractorManagersAsync(cell.model.organizationContractId),
                nothingSelectedText: " ",
                align: DropdownAlign.Right
            },
            accessor: "manager",
            minWidth: 200,
            className: "blue",
            editable: true,
            sorting: true,
            visible: false,
        } as ColumnDefinition,
        {
            header: Localizer.tasksPanelActionsLanguageItemName,
            minWidth: 100,
            removable: false,
            init: (cell) => this.initSubcontractorOperations(cell),
            actions: [
                {
                    name: "save",
                    title: Localizer.genericSaveLanguageItemName,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: async (cell, action) => await this.processSubcontractorOperationAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "cancel",
                    title: Localizer.genericActionCancelLanguageItemName,
                    icon: "far ban",
                    type: ActionType.Delete,
                    callback: async (cell, action) => await this.processSubcontractorOperationAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "delete",
                    title: Localizer.subcontractorPanelDeleteSubcontractorLanguageItemName,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    right: true,
                    confirm: (cell: CellModel<ConstructionSite>) => Utility.format(Localizer.subcontractorsPanelConfirmationDeleteSubcontractor, cell.model.name),
                    callback: async (cell, action) => await this.processSubcontractorOperationAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "restore",
                    title: Localizer.subcontractorPanelRestoreSubcontractorLanguageItemName,
                    icon: "far undo-alt",
                    type: ActionType.Create,
                    right: true,
                    callback: async (cell, action) => await this.processSubcontractorOperationAsync(cell, action)
                } as ColumnActionDefinition,
            ]
        } as ColumnDefinition,
    ];

    private async getSubcontractorManagersAsync(organizationContractId: string): Promise<User[]> {
        return await PageCacheProvider.getAsync(`getSubcontractorManagersAsync:${organizationContractId}`, async () => await this.subcontractorsGrid.postAsync("api/constructionSiteManagement/getSubcontractorManagers", organizationContractId));
    }

    private async getAssignedSubcontractorMounters(request: GetAssignedSubcontractorMountersRequest): Promise<User[]> {
        return await this.postAsync("api/constructionSiteManagement/getAssignedSubcontractorMounters", request);
    }

    private initNumerationColumn(cell: CellModel<Subcontractor>): void {
        if (cell.descriptionAction) {
            cell.descriptionAction.readonly = true;
            cell.descriptionAction.visible = (cell.row.deleted);
        }
    }

    private isSubcontractorValid(model: Subcontractor): boolean {
        return !!model.name;
    }

    private initSubcontractorOperations(cell: CellModel<Subcontractor>) {
        const model: Subcontractor = cell.row.model;
        const isValid: boolean = this.isSubcontractorValid(model);
        const modified: boolean = cell.row.modified;
        const deleted: boolean = cell.row.deleted;

        const saveAction: CellAction<Subcontractor> = cell.actions[0];
        const cancelAction: CellAction<Subcontractor> = cell.actions[1];
        const deleteAction: CellAction<Subcontractor> = cell.actions[2];
        const restoreAction: CellAction<Subcontractor> = cell.actions[3];

        saveAction.visible = (modified) && (!deleted) && (isValid);
        cancelAction.visible = (modified) && (!deleted);
        deleteAction.visible = (!deleted);
        restoreAction.visible = (deleted);
    }

    private async processSubcontractorOperationAsync(cell: CellModel<Subcontractor>, action: CellAction<Subcontractor>) {
        const model: Subcontractor = cell.model;

        if (action.action.name === "save") {

            const request = new SaveSubcontractorRequest();
            request.id = model.id;
            request.organizationName = model.name;
            request.organizationFormattedAddress = (model.location) ? model.location.formattedAddress : null;
            request.managerUserId = (model.manager != null) ? model.manager.id : null;

            cell.row.model = await cell.grid.postAsync("api/constructionSiteManagement/saveSubcontractor", request);

            await cell.row.bindAsync();

            await this.subcontractorsGrid.reloadAsync();

        } else if (action.action.name === "cancel") {

            await cell.row.cancelAsync();

        } else if (action.action.name === "delete") {
            if (model.constructionSiteId) {
                const request: GetAssignedSubcontractorMountersRequest = {
                    constructionSiteId: model.constructionSiteId,
                    subcontractorOrganizationContractId: model.organizationContractId,
                };

                const assignedSubcontractorMounters: User[] = await this.getAssignedSubcontractorMounters(request);
                
                this.setState({assignedSubcontractorMounters: assignedSubcontractorMounters})

                if (this.assignedSubcontractorMounters.length) {
                    await this.assignedSubcontractorMountersWarningModal.openAsync();
                    return;
                }
            }

            cell.row.model = await cell.grid.postAsync("api/constructionSiteManagement/deleteConstructionSiteSubcontractor", model.id);

            await cell.row.bindAsync();
            
        } else if (action.action.name === "restore") {

            cell.row.model = await cell.grid.postAsync("api/constructionSiteManagement/restoreConstructionSiteSubcontractor", model.id);

            await cell.row.bindAsync();
        }
    }

    private initRow(row: RowModel<Subcontractor>): void {
        const model: Subcontractor = row.model;
        row.deleted = model.deleted;
        row.className = "";
    }

    private get readonly(): boolean {
        return !!this.props.readonly;
    }

    private get constructionSite(): ConstructionSite | null {
        return this.props.constructionSite;
    }

    private get constructionSiteId(): string {
        return this.props.constructionSite.id;
    }

    private get subcontractorsGrid(): GridModel<Subcontractor> {
        return this._subcontractorsGridRef.current!.model;
    }

    private get assignedSubcontractorMounters(): User[] {
        return this.state.assignedSubcontractorMounters;
    }

    private get assignedSubcontractorMountersWarningModal(): AssignedMountersWarningModal {
        return this._assignedSubcontractorMountersWarningModal.current!
    }

    private async addWarehouseSubcontractorAsync(sender: IBaseComponent, request: AddSubcontractorRequest): Promise<boolean> {
        const response: AddSubcontractorResponse = await sender.postAsync("api/constructionSiteManagement/addSubcontractor", request);

        if (response.subcontractorAlreadyExists) {
            return false;
        }

        const message = (response.subcontractorRestored)
            ? Localizer.get(Localizer.subcontractorPanelSubcontractorRestoredFlyout, response.subcontractor!.name)
            : Localizer.get(Localizer.subcontractorPanelSubcontractorAddedFlyout, response.subcontractor!.name);

        await ch.flyoutMessageAsync(message);

        PageCacheProvider.clear();

        await this.subcontractorsGrid.reloadAsync();
        
        await this.props.onChange(this);

        return true;
    }

    private async listSubcontractorsAsync(
        pageNumber: number,
        pageSize: number,
        sortColumnName: string | null,
        sortDirection: SortDirection | null): Promise<IPagedList<Subcontractor>> 
    {
        UserInteractionDataStorage.setFilters(sortColumnName, "SubcontractorsPanel.SortColumn");
        UserInteractionDataStorage.setFilters(sortDirection, "SubcontractorsPanel.SortDirection");

        const request = new ListSubcontractorsRequest();
        request.constructionSiteId = this.constructionSiteId;
        request.showDeleted = this.state.showDeleted;
        request.sortColumnName = sortColumnName;
        request.sortDirection = sortDirection;
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;

        return await this.subcontractorsGrid.postAsync("api/constructionSiteManagement/listSubcontractors", request);
    }

    private async setShowDeletedAsync(showDeleted: boolean): Promise<void> {
        await this.setState({showDeleted});
        await this.reloadAsync();
    }

    public async resetAsync(): Promise<void> {
        await this.setState({showDeleted: false});
        await this.reloadAsync();
    }

    public async reloadAsync(): Promise<void> {
        PageCacheProvider.clear();
        await this.subcontractorsGrid.reloadAsync();
    }

    private get sortColumn(): string {
        return UserInteractionDataStorage.getFilters("name", "SubcontractorsPanel.SortColumn");
    }

    private get sortDirection(): SortDirection {
        return UserInteractionDataStorage.getFilters(SortDirection.Asc, "SubcontractorsPanel.SortDirection");
    }

    private async openAddTaskModalAsync() {
        await this._addSubcontractorModalRef.current!.openAsync();
    }

    private getHeading(): React.ReactNode {
        return (
            <div id={"subcontractorsHeading"}
                 className="col-6 d-flex justify-content-sm-between">

                <h3 className="m-0">{Localizer.subContractorsTitle}</h3>

                <Inline>

                    <Checkbox inline
                              id="showDeletedSubcontractors"
                              label={Localizer.genericShowDeleted}
                              value={this.state.showDeleted}
                              onChange={async (_, value) => await this.setShowDeletedAsync(value)}
                    />

                    <Button id={"addSubcontractor"}
                            icon={{name: "plus", size: IconSize.Large}}
                            type={ButtonType.Orange}
                            onClick={async () => await this.openAddTaskModalAsync()}
                            disabled={this.readonly}
                    />

                    <Button id={"reset"}
                            title={Localizer.genericActionReload} className="ml-1"
                            icon={{name: "far history", size: IconSize.Large}}
                            type={ButtonType.Info}
                            onClick={async () => await this.resetAsync()}
                    />
                    
                    <Button id={"search"}
                            title={Localizer.genericSearch} className="ml-1"
                            icon={{name: "far search", size: IconSize.Large}}
                            type={ButtonType.Orange}
                            onClick={async () => await this.reloadAsync()}
                    />

                </Inline>
            </div>
        )
    }

    public render(): React.ReactNode {
        return (
            <Panel id={"subcontractorsPanel"}
                   title={Localizer.subContractorsTitle}
                   customHeading={this.getHeading()}
            >
                <div className="col-12">
                    {
                        (this.constructionSite) &&
                        (
                            <Grid ref={this._subcontractorsGridRef}
                                  id="subcontractorsGrid"
                                  pagination={RentaTaskConstants.paginationNumber}
                                  minWidth={"auto"}
                                  hovering={GridHoveringType.Row}
                                  odd={GridOddType.None}
                                  noDataText={Localizer.gridNoDataSubcontractors}
                                  columns={this._subcontractorsColumns}
                                  initRow={(row) => this.initRow(row)}
                                  fetchData={async (_: Grid<Subcontractor>, pageNumber: number, pageSize: number, sortColumnName: string | null, sortDirection: SortDirection | null) => await this.listSubcontractorsAsync(pageNumber, pageSize, sortColumnName, sortDirection)}
                                  readonly={this.readonly}
                                  defaultSortColumn={this.sortColumn}
                                  defaultSortDirection={this.sortDirection}
                            />
                        )
                    }

                    <AddSubcontractorModal ref={this._addSubcontractorModalRef}
                                           constructionSiteId={this.constructionSiteId}
                                           addWarehouseSubcontractor={async (sender, request) => await this.addWarehouseSubcontractorAsync(sender, request)}
                    />
                    
                    <AssignedMountersWarningModal
                        ref={this._assignedSubcontractorMountersWarningModal}
                        assignedMounters={this.assignedSubcontractorMounters}
                    />
                </div>
            </Panel>
        );

    }
}