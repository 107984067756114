import Warehouse from "@/models/server/Warehouse";
import ConstructionSite from "@/models/server/ConstructionSite";
import {CalendarUnitTime, TaskStatusFilter, WorkOrderContractType} from "@/models/Enums";
import CostPool from "@/models/server/CostPool";
import User from "@/models/server/User";
import Region from "@/models/server/Region";

/**
 * Note: update UserInteractionDataStorage's id if model has new fields or other changes.
 * E.g. new fields will be received as undefined for existing values from storage.
 */
export default class BaseCalendarDataRequest {
    /** @see GetCalendarWorkOrdersRequest.warehouseIds */
    public warehouses: Warehouse[] = [];

    /** @see GetCalendarWorkOrdersRequest.constructionSiteIds */
    public constructionSites: ConstructionSite[] = [];

    /** @see GetCalendarWorkOrdersRequest.mounterCostPoolIds */
    public mounterCostPools: CostPool[] = [];

    /** @see GetCalendarWorkOrdersRequest.workOrderStatuses */
    public statuses: TaskStatusFilter[] = [];

    /** @see GetCalendarWorkOrdersRequest.contractTypes */
    public contractTypes: WorkOrderContractType[] = [];

    /** @see GetCalendarWorkOrdersRequest.search */
    public search: string | null = null;

    /** @see GetCalendarWorkOrdersRequest.managers */
    public managers: User[] = [];

    /** @see GetCalendarWorkOrdersRequest.calendarUnitType */
    public calendarUnitType: CalendarUnitTime =  CalendarUnitTime.Week;

    /** @see GetCalendarWorkOrdersRequest.mounters */
    public mounters: User[] = [];

    /** @see GetCalendarWorkOrdersRequest.regions */
    public regions: Region[] = [];
}