import FormContent from "@/models/server/forms/FormContent";
import {CustomerApprovalType} from "@/models/Enums";
import FormItem from "@/models/server/forms/FormItem";
import User from "@/models/server/User";
import ConstructionSite from "@/models/server/ConstructionSite";
import {IIconProps} from "@renta-apps/athenaeum-react-components";
import FormDefinition from "@/models/server/forms/FormDefinition";
import WorkOrderModel from "@/models/server/WorkOrderModel";
import Localizer from "@/localization/Localizer";
import UserSignatureModel from "@/models/server/UserSignatureModel";
import {FileModel} from "@renta-apps/athenaeum-toolkit";

export default class FormModel extends FormContent {

    public static readonly emailReceiversSeparator = ", " as const;

    /**
     * Empty for unprocessed anytime forms
     */
    public id: string = "";

    public startedAt: Date | null = null;

    /**
     * Has the Form been submitted.
     */
    public processed: boolean = false;

    public processedAt: Date | null = null;

    public static isProcessed(form: FormModel): boolean {
        return (form != null) && (FormModel.isValid(form));
    }

    /**
     * Were all the checks in the submitted Form positive.
     */
    public passed: boolean = false;

    public constructionSite: ConstructionSite | null = null;

    /**
     * WorkOrder's customer approver or WorkOrder's customer orderer
     */
    public contactUser: User | null = null;

    public createdAt: Date = new Date();

    /**
     *  Whether the form has save pictures in storage
     *  (note: dont change this variable in frontend).
     */
    public hasPictures: boolean = false;

    /**
     * Does the Form block Task sign-in and completion if it is not submitted.
     *
     * This value overrides {@link FormDefinition.mapping.require}.
     */
    public required: boolean = false;

    public userId: string | null = null;

    public user: User | null = null;

    public approverEmail: string | null = null;

    public workOrderId: string = "";

    public workOrder: WorkOrderModel | null = null;

    public formDefinitionId: string = "";

    public formDefinition: FormDefinition | null = null;

    public approvalType: CustomerApprovalType = CustomerApprovalType.Email;

    public sent: boolean = false;

    public sentAt: Date | null = null;

    public sentBy: User | null = null;

    public sentToId: string | null = null;

    public sentTo: User | null = null;

    public emailReceivers: string = "";

    /**
     * Has the submitted Form been approved.
     */
    public approved: boolean = false;

    public approvedAt: Date | null = null;

    public approvedById: string | null = null;

    public approvedBy: User | null = null;

    /**
     * Is the Form locked, preventing users other than {@link user} from editing it.
     *
     * The Form can be unlocked by submitting it, manually by Manager or automatically after 48 hours have passed from {@link lockedAt}.
     */
    public locked: boolean = false;

    /**
     * When the Form was {@link locked}.
     *
     * The Form is unlocked automatically after 48 hours have passed from this value.
     */
    public lockedAt: Date | null = null;

    public displayLocalTime: string = "";

    public displayName: string = "";

    /**
     * User signatures and name clarifications
     */
    public usersSignatures: UserSignatureModel[] | null = null;
    
    public approverSignature: FileModel | null = null;

    public approverNameClarification: string | null = null;

    public readonly isFormModel: true = true;

    public static isPassed(form: FormModel): boolean {
        return (form.items != null) && (form.items.length > 0) && (form.items.every(item => FormItem.isOk(item)));
    }
    
    public static canBeCompleted(form: FormModel): boolean {
        if (!form) return false;
        
        let signaturesAreValid : boolean = true;
        if (form.mapping!.mounterSignaturesRequired) {
            signaturesAreValid = UserSignatureModel.areSignaturesValid(form.usersSignatures)
        }
        return (signaturesAreValid) && (FormModel.isValid(form));
    }

    public static isValid(form: FormModel): boolean {
        return (form.items != null) && (form.items.length > 0) && (form.items.every(item => FormItem.isValid(item)));
    }

    public static getFormStatusIcon(form: FormModel): IIconProps {
        return (form.processed)
            ? (form.approved)
                ? {name: "far thumbs-up", className: "text-success", tooltip: Localizer.formModelApproved}
                : (form.sent)
                    ? {name: "fas mail-bulk", className: "text-primary", tooltip: Localizer.formModelSent}
                    : {name: "fas clipboard-check", className: "orange", tooltip: Localizer.formModelProcessed}
            : (form.startedAt)
                ? {name: "fas running", className: "text-primary", tooltip: Localizer.formModelInProgress}
                : {name: "fas hourglass-start", className: "orange", tooltip: Localizer.formModelNew}
    }

    public static getFormStatus(form: FormModel): string {
        return (form.processed)
            ? (form.approved)
                ? Localizer.formModelApproved
                : (form.sent)
                    ? Localizer.formModelSent
                    : Localizer.formModelProcessed
            : (form.startedAt)
                ? Localizer.formModelInProgress
                : Localizer.formModelNew
    }

    public static canBeSent(form: Readonly<FormModel>): boolean {
        return (!!form.emailReceivers?.trim());
    }
}