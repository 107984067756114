import React, {ReactNode} from "react";
import {BasePageParameters, ch, IBaseComponent, PageRoute, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {
    Button,
    ButtonAction,
    ButtonType,
    DateInput,
    Dropdown,
    DropdownOrderBy,
    DropdownRequiredType,
    Form,
    FourColumns,
    Icon,
    IconSize,
    IIconProps,
    Inline,
    NumberInput,
    PageContainer,
    PageHeader,
    Spinner,
    TextAreaInput,
    TextInput,
    ThreeColumns,
    TwoColumns
} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import WorkOrderModel, {IWorkOrderEditability, WorkOrderAction} from "@/models/server/WorkOrderModel";
import GetWorkOrderRequest from "@/models/server/requests/GetWorkOrderRequest";
import WorkOrderDetailsPanel from "@/pages/WorkOrders/WorkOrderDetailsPanel/WorkOrderDetailsPanel";
import ConstructionSiteOrWarehouse from "@/models/server/ConstructionSiteOrWarehouse";
import User from "@/models/server/User";
import Product from "@/models/server/Product";
import {CustomerApprovalType, WorkOrderContactPersonType, WorkOrderContractType, WorkOrderStatus} from "@/models/Enums";
import CompleteWorkOrderResponse from "@/models/server/responses/CompleteWorkOrderResponse";
import ApproveWorkOrderModal from "@/pages/WorkOrders/WorkOrdersPanel/ApproveWorkOrderModal/ApproveWorkOrderModal";
import SendWorkOrderModal, {SendWorkOrderModalAction} from "@/components/SendWorkOrderModal/SendWorkOrderModal";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import WorkOrderType from "@/models/server/WorkOrderType";
import Comparator from "@/helpers/Comparator";
import FormModal from "@/pages/ConstructionSiteManagement/FormModal/FormModal";
import GetWorkOrderFormsRequest from "@/models/server/requests/GetWorkOrderFormsRequest";
import FormModel from "@/models/server/forms/FormModel";
import SaveFormRequest from "@/models/server/requests/SaveFormRequest";
import ApproveWorkOrderByEmailRequest from "@/models/server/requests/ApproveWorkOrderByEmailRequest";
import UsersInfoModal from "@/pages/WorkOrder/UsersInfoModal/UsersInfoModal";
import {ActivateWorkOrderRequest} from "@/models/server/requests/ActivateWorkOrderRequest";
import WorkOrderChangeStatusModal, {ChangeStatusAction} from "@/components/WorkOrderChangeStatusModal/WorkOrderChangeStatusModal";
import StatusMessageModal from "@/components/StatusMessageModal/StatusMessageModal";
import GetEmployeesRequest from "@/models/server/requests/GetEmployeesRequest";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";
import PauseWorkOrderRequest from "@/models/server/requests/PauseWorkOrderRequest";
import PauseWorkOrderResponse from "@/models/server/responses/PauseWorkOrderResponse";
import FeatureFlags from "@/helpers/FeatureFlags";
import UnleashHelper from "@/helpers/UnleashHelper";
import UserProvider from "@/providers/UserProvider";
import EnumProvider from "@/providers/EnumProvider";
import WorkOrderProvider from "@/providers/WorkOrderProvider";
import TransformProvider from "@/providers/TransformProvider";
import RentaTasksController from "@/pages/RentaTasks/RentaTasksController";
import Localizer from "@/localization/Localizer";
import clone from "@/functions/Clone";
import isNullOrDefaultGuid from "@/functions/IsNullOrDefaultGuid";
import SubcontractorAssignmentModal from "@/components/SubcontractorAssignmentModal/SubcontractorAssignmentModal";
import CostPool from "@/models/server/CostPool";
import SetInvoicedResponse from "@/models/server/responses/SetInvoicedResponse";
import {FeatureSwitch} from "@/components/FeatureSwitch/FeatureSwitch";
import PageDefinitions from "@/providers/PageDefinitions";

import style from "./WorkOrder.module.scss";

/**
 * {@link WorkOrder} parameters
 */
export interface IWorkOrderParameters extends BasePageParameters {
    /**
     * ConstructionSite Id to predefine {@link workOrderSiteOrWarehouse} dropdown item,
     * when WorkOrder creation is triggered from the ConstructionSiteManagement page.
     */
    ownerId?: string;
}

/** @private */
interface IWorkOrderState {

    /**
     * Currently allowed actions
     */
    actions?: Record<WorkOrderAction, boolean>;

    /**
     * (Construction Site or Warehouse)s displayed in the respective dropdown
     */
    constructionSitesOrWarehouses?: readonly Readonly<ConstructionSiteOrWarehouse>[];

    /**
     * {@link workOrder} with changes made by the user
     */
    editedWorkOrder?: WorkOrderModel;

    /**
     * Users displayed in the Managers dropdown
     */
    managers?: readonly Readonly<User>[];

    /**
     * Users displayed in the Sales persons dropdown
     */
    salesPersons?: readonly Readonly<User>[];

    /**
     * Types displayed in the work order type dropdown.
     */
    workOrderTypes?: readonly Readonly<WorkOrderType>[];

    /**
     * Products for {@link WorkOrderDetailsPanel}
     */
    products?: readonly Readonly<Product>[];

    /**
     * Mounters displayed on the Mounters dropdown
     */
    mounters?: readonly Readonly<User>[];

    /**
     * Cost pools displayed on the Cost pool dropdown
     */
    costPools?: readonly Readonly<CostPool>[];

    /**
     * WorkOrder being displayed on the page
     */
    workOrder?: Readonly<WorkOrderModel>;

    /**
     * Ids of the construction site or warehouse subcontractors organization contracts.
     */
    subcontractorsOrganizationContractIds: string[];

    /**
     * Ids of the construction site or warehouse subcontractors organization contracts of currently selected mounters from subcompany not yet assigned to the site.
     */
    selectedMountersSubcontractorsOrganizationContractIds: string[];
}

/**
 * Page for displaying and editing a {@link WorkOrderModel}
 */
export default class WorkOrder extends AuthorizedPage<IWorkOrderParameters, IWorkOrderState> {

    public state: IWorkOrderState = {
        subcontractorsOrganizationContractIds: [],
        selectedMountersSubcontractorsOrganizationContractIds: []
    };

    private readonly _formRef: React.RefObject<Form> = React.createRef();
    private readonly _approveWorkOrderModalRef: React.RefObject<ApproveWorkOrderModal> = React.createRef();
    private readonly _formModalRef: React.RefObject<FormModal> = React.createRef();
    private readonly _usersInfoModalRef: React.RefObject<UsersInfoModal> = React.createRef();

    // Used to assign/create/update work order' customer orderers and approvers.
    private readonly _sendWorkOrderModalRef: React.RefObject<SendWorkOrderModal> = React.createRef();
    private readonly _workOrderDetailsPanelRef: React.RefObject<WorkOrderDetailsPanel> = React.createRef();
    private readonly _changeStatusModalRef: React.RefObject<WorkOrderChangeStatusModal> = React.createRef();
    private readonly _statusMessageModalRef: React.RefObject<StatusMessageModal> = React.createRef();
    private readonly _subcontractorAssignmentModal: React.RefObject<SubcontractorAssignmentModal> = React.createRef();
    private readonly _mountersDropdown: React.RefObject<Dropdown<User>> = React.createRef();
    private readonly _costPoolsDropdown: React.RefObject<Dropdown<CostPool>> = React.createRef();

    private readonly _parameters: IWorkOrderParameters | null = this.parameters as IWorkOrderParameters | null;

    private readonly _workOrderAccuracyMinutes: number = UnleashHelper.isEnabled(FeatureFlags.WorkOrder15MinuteAccuracy) ? 15 : 60;
    private readonly _today: Date = Utility.today().date();
    private readonly _minDate: Date = new Date(1900, 1, 1);
    private readonly _maxDate: Date = new Date(2100, 1, 1);
    private readonly _beginningOfTheDay: Date = new Date(1900, 1, 1, 0, 0, 0, 0);
    private readonly _endOfTheDay: Date = new Date(2100, 1, 1, 23, 59, 59, 999);

    /**
     * Work Order displayed in the page.
     *
     * NOTE: Is null/undefined before page initialization, but is never null/undefined after that.
     */
    private get workOrder(): Readonly<WorkOrderModel> {
        return this.state.editedWorkOrder ?? this.state.workOrder!;
    }

    private get workOrderStatus(): string {
        if (WorkOrderModel.isNew(this.workOrder)) {
            return Localizer.genericNew;
        }

        if (WorkOrderModel.hasBlockingForms(this.workOrder)) {
            return Localizer.workOrderStatusBlockedByForm;
        }

        return EnumProvider.getWorkOrderStatusText(this.workOrder.currentStatus);
    }

    private get title(): string {
        if (!this.workOrder) {
            return Localizer.genericWorkOrder;
        }

        return (this.creatingNew)
            ? Localizer.genericWorkOrderNew
            : Localizer.genericWorkOrderEdit;
    }

    private get hasChanges(): boolean {
        return (!!this.state.editedWorkOrder);
    }

    private get creatingNew(): boolean {
        return (WorkOrderModel.isNew(this.workOrder));
    }

    private get actions(): Record<WorkOrderAction, boolean> {
        return WorkOrderModel.getActions(
            this.workOrder,
            this.hasChanges,
            false
        );
    }

    private get editability(): Readonly<IWorkOrderEditability> {
        return WorkOrderModel.getEditability(this.workOrder);
    }

    private get canAssignContactPerson(): boolean {
        return (!this.readonly || this.creatingNew);
    }

    private get readonly(): boolean {
        return (!this.editability.editable);
    }

    private get hasOwner(): boolean {
        return (!!this.workOrder.owner);
    }

    private get constructionSiteOrWarehouseSelectionDisabled(): boolean {
        return (this.readonly) || (!this.creatingNew) || (this._parameters != null && this._parameters.ownerId != null);
    }

    private get workOrderNumber(): string {
        return (this.workOrder.number <= 0)
            ? ""
            : this.workOrder.number.toString();
    }

    private get approvalType(): string {
        return (this.workOrder.approvedAt)
            ? EnumProvider.getCustomerApprovalTypeText(this.workOrder.approvalType)
            : "";
    }

    private get form(): Form {
        return this._formRef.current!;
    }

    private get sendWorkOrderModal(): SendWorkOrderModal {
        return this._sendWorkOrderModalRef.current!;
    }

    private get changeStatusModal(): WorkOrderChangeStatusModal {
        return this._changeStatusModalRef.current!;
    }

    private get subcontractorAssignmentModal(): SubcontractorAssignmentModal {
        return this._subcontractorAssignmentModal.current!;
    }

    private get ordererLabel(): string {
        return (this.contactPersonDataMissing(this.workOrder.customerOrderer))
            ? Localizer.get(Localizer.validatorsRequired, Localizer.genericOrderer)
            : "{0} *".format(Localizer.genericOrderer);
    }

    private get approverLabel(): string {
        return (this.contactPersonDataMissing(this.workOrder.customerApprover))
            ? Localizer.get(Localizer.validatorsRequired, Localizer.genericApprover)
            : "{0} *".format(Localizer.genericApprover);
    }

    private get contactPersonButtonType(): ButtonType {
        return (this.canAssignContactPerson)
            ? ButtonType.Success
            : ButtonType.Info;
    }

    private get contactPersonButtonIcon(): IIconProps {
        return (this.canAssignContactPerson)
            ? {name: "far user-edit"}
            : {name: "far info"};
    }

    private get workOrderDuplicate(): WorkOrderModel {
        const workOrderDuplicate: WorkOrderModel = clone(this.workOrder);
        return Utility.restoreDate(workOrderDuplicate);
    }

    private get formModal(): FormModal {
        return this._formModalRef.current!;
    }

    private get showStatusMessageIcon(): boolean {
        return ((this.workOrder.currentStatusComment != null && this.workOrder.currentStatusComment.length > 0) &&
            (
                (WorkOrderModel.isReactivated(this.workOrder)) ||
                this.workOrder.currentStatus == WorkOrderStatus.DeclinedByCustomer
            )
        );
    }

    private get hasMounters(): boolean {
        return (this.workOrder != null && this.workOrder.mounters.length > 0);
    }

    /** Should the inputs for fixed costs be displayed? */
    private get displayFixedCostInputs(): boolean {
        const isCreated: boolean = !this.creatingNew;
        const isBusinessManagerOrAdmin: boolean = (this.isBusinessManager || this.isAdmin);

        return isCreated && isBusinessManagerOrAdmin && WorkOrderModel.isFixedPrice(this.workOrder);
    }

    private get firstAvailablePauseStartTimeslot(): Date {
        const valueOfPauseStartDate: number | null = this.workOrder.pauseStartDate?.date().valueOf() ?? null;
        const valueOfPauseEndDate: number | null = this.workOrder.pauseEndDate?.date().valueOf() ?? null;
        const valueOfActivationDate: number | null = this.workOrder.activationDate?.date().valueOf() ?? null;

        if (!valueOfPauseStartDate && !valueOfPauseEndDate) {
            return (valueOfActivationDate !== this._today.valueOf())
                ? this._beginningOfTheDay
                : this.workOrder.activationDate!;

        } else if (!valueOfPauseStartDate && valueOfPauseEndDate) {
            return (valueOfPauseEndDate === valueOfActivationDate)
                ? this.workOrder.activationDate!
                : this._beginningOfTheDay

        } else if (valueOfActivationDate === valueOfPauseStartDate) {
            return this.workOrder.activationDate!;
        }

        return this._beginningOfTheDay;
    }

    /** Activation date can be as far in to the past as wanted. */
    private get minActivationDate(): Date {
        return this._minDate;
    }

    /** Activation time can be as far in to the past as wanted. */
    private get minActivationTime(): Date {
        return this._minDate;
    }

    /** Activation date can't be before completion or pause start. */
    private get maxActivationDate(): Date {
        return this.workOrder.completionDate ?? this.workOrder.pauseStartDate ?? this._maxDate;
    }

    /** Activation time can't be after maximum if set. */
    private get maxActivationTime(): Date {
        const hasActivationDate: boolean = !!this.workOrder.activationDate;
        const activationDateIsMaxDate: boolean = hasActivationDate && this.maxActivationDate.date().valueOf() === this.workOrder.activationDate!.date().valueOf(); 
        
        return activationDateIsMaxDate
            ? this.maxActivationDate
            : this._endOfTheDay;
    }

    /** Completion date can't be before activation. */
    private get minCompletionDate(): Date {
        return this.workOrder.activationDate ?? this._minDate;
    }

    /** Completion time can't be before minimum if set. */
    private get minCompletionTime(): Date {
        const completionDateIsMin: boolean = this.minCompletionDate.date().valueOf() === this.workOrder.completionDate?.date().valueOf();
        return completionDateIsMin
            ? this.minCompletionDate
            : this._beginningOfTheDay;
    }

    /** Completion date today or earlier. */
    private get maxCompletionDate(): Date {
        return this._today;
    }
    
    /**
     * Completion time can't be in the future (when date is today).
     * 
     * If completion date is not set, the default for date input is current date,
     * and in that case a time in the future is not allowed either.
     * I.e. the same case as if completion date would be set for today.
     */
    private get maxCompletionTime(): Date {
        const completionDateNotSet: boolean = !this.workOrder.completionDate;
        const completionDateIsToday: boolean = this.workOrder.completionDate?.date().valueOf() === this._today.valueOf();
        return completionDateNotSet || completionDateIsToday
            ? Utility.now()
            : this._endOfTheDay;
    }

    /** Planned completion date can't be before pause end, pause start or activation. */
    private get minPlannedCompletionDate(): Date {
        return this.workOrder.pauseEndDate ?? this.workOrder.pauseStartDate ?? this.workOrder.activationDate ?? this._minDate
    }

    /** Planned completion time can't be before minimum, if set. */
    private get minPlannedCompletionTime(): Date {
        const plannedCompletionNotSet: boolean = !this.workOrder.plannedCompletionDate;
        const plannedCompletionDateIsMin: boolean = this.minPlannedCompletionDate.date().valueOf() === this.workOrder.plannedCompletionDate?.date().valueOf();
        return plannedCompletionNotSet || plannedCompletionDateIsMin
            ? this.minPlannedCompletionDate.addMilliseconds(this._workOrderAccuracyMinutes * 60 * 1000) 
            : this._beginningOfTheDay;
    }

    /** Planned completion date can be as far in the future as wanted. */
    private get maxPlannedCompletionDate(): Date {
        return this._maxDate;
    }

    /** Planned completion time can be as far in the future as wanted. */
    private get maxPlannedCompletionTime(): Date {
        return this._endOfTheDay;
    }

    private get lastAvailablePauseStartTimeslot(): Date {
        const valueOfPauseStartDate: number | null = this.workOrder.pauseStartDate?.date().valueOf() ?? null;
        const valueOfPauseEndDate: number | null = this.workOrder.pauseEndDate?.date().valueOf() ?? null;
        const valueOfPlannedCompletionDate: number | null = this.workOrder.plannedCompletionDate?.date().valueOf() ?? null;
        
        if (!valueOfPauseStartDate && valueOfPauseEndDate) {
            return (valueOfPauseEndDate > this._today.valueOf())
                ? this._endOfTheDay
                : this.workOrder.pauseEndDate!;
        }

        const isPauseWithinOneDay: boolean = (!!valueOfPauseStartDate && !!valueOfPauseEndDate) &&
            (valueOfPauseStartDate === valueOfPauseEndDate);

        if (isPauseWithinOneDay) {
            return this.workOrder.pauseEndDate!;
        }

        const isDayOfPlannedCompletion: boolean = (!!valueOfPauseStartDate && !!valueOfPlannedCompletionDate) &&
            (valueOfPauseStartDate === valueOfPlannedCompletionDate);
        
        if (isDayOfPlannedCompletion) {
            return this.workOrder.plannedCompletionDate!;
        }

        return this._endOfTheDay;
    }

    private get firstAvailablePauseEndTimeslot(): Date {
        const valueOfPauseStartDate: number | null = this.workOrder.pauseStartDate?.date().valueOf() ?? null;
        const valueOfPauseEndDate: number | null = this.workOrder.pauseEndDate?.date().valueOf() ?? null;
        const valueOfActivationDate: number | null = this.workOrder.activationDate?.date().valueOf() ?? null;

        if (!valueOfPauseStartDate && !valueOfPauseEndDate) {
            return (valueOfActivationDate !== this._today.valueOf())
                ? this._beginningOfTheDay
                : this.workOrder.activationDate!;

        } else if (valueOfPauseStartDate && !valueOfPauseEndDate) {
            return (valueOfPauseStartDate < this._today.valueOf())
                ? this._beginningOfTheDay
                : this.workOrder.pauseStartDate!;

        } else if (!valueOfPauseStartDate && valueOfPauseEndDate) {
            return (valueOfActivationDate === valueOfPauseEndDate)
                ? this.workOrder.activationDate!
                : this._beginningOfTheDay;

        } else if (valueOfPauseStartDate === valueOfPauseEndDate) {
            return this.workOrder.pauseStartDate!
        }

        return this._beginningOfTheDay;
    }

    private get lastAvailablePauseEndTimeslot(): Date {
        const valueOfPauseEndDate: number | null = this.workOrder.pauseEndDate?.date().valueOf() ?? null;
        const valueOfPlannedCompletionDate: number | null = this.workOrder.plannedCompletionDate?.date().valueOf() ?? null;

        const isDayOfPlannedCompletion: boolean = (!!valueOfPauseEndDate && !!valueOfPlannedCompletionDate) &&
            (valueOfPauseEndDate === valueOfPlannedCompletionDate);

        return (isDayOfPlannedCompletion)
            ? this.workOrder.plannedCompletionDate!
            : this._endOfTheDay;
    }
    
    /**
     * Clear edited Work Order and reload the displayed Work Order from server
     */

    private async reloadWorkOrderDetailsAsync(): Promise<void> {
        await this._workOrderDetailsPanelRef.current?.reloadAsync();
    }

    private async reloadWorkOrderAsync(): Promise<void> {
        const workOrderId: string | null = this.routeId;

        let workOrder: WorkOrderModel = new WorkOrderModel();

        if (workOrderId) {
            const getWorkOrderRequest: GetWorkOrderRequest = new GetWorkOrderRequest();

            getWorkOrderRequest.workOrderId = workOrderId;
            getWorkOrderRequest.excludeDeletedUserSalaryHours = true;
            getWorkOrderRequest.includeMounters = true;

            workOrder = await WorkOrderProvider.getWorkOrderAsync(this, getWorkOrderRequest);
        }

        const workOrderTypes: WorkOrderType[] = await RentaTasksController.getActiveWorkOrderTypesAsync(this, workOrder);

        await this.setState({
            workOrder,
            workOrderTypes: workOrderTypes
        });

        if (this.hasChanges) {
            await this.cancelAsync();
        }
    }

    private async navigateBackAsync(): Promise<void> {
        if ((this.hasChanges) && !(await this.confirmAsync(Localizer.confirmationDialogDiscardUnsavedChanges))) {
            return;
        }

        PageRouteProvider.back();
    }

    private async flyoutSuccessAsync(message: string): Promise<void> {
        await this.alertMessageAsync(message, true, true);
    }

    private async flyoutErrorAsync(message: string): Promise<void> {
        await this.alertErrorAsync(message, true, true);
    }

    private async editAsync<TKey extends keyof WorkOrderModel>(key: TKey, value: WorkOrderModel[TKey]): Promise<void> {
        // Some prices can  be edited even if WO is otherwise readonly due to invoicing process.
        const allowEditingWhenReadonly: string[] = [
            "mileagePrice",
            "fixedTransportationCost",
            "fixedHoursCost",
            "fixedProductCost",
            "fixedCost",
            "costPool",
        ];
        const canEditEvenIfReadonly: boolean = allowEditingWhenReadonly.includes(key) && (this.editability.pricesEditable);

        if ((this.readonly) && (!canEditEvenIfReadonly)) {
            throw new Error("Cannot edit readonly model: " + key);
        }
        
        if (key === "mountersIds") {
            await this.validateSubcontractorMounters(value as string[]);
            await this.updateSelectedSubcontractorMountersCompanyIds(value as string[])
        }

        const editedWorkOrder: WorkOrderModel = this.state.editedWorkOrder ?? clone(this.workOrder);

        if (key.valueOf() === "pauseStartDate" || key.valueOf() === "pauseEndDate") {
            const valueOfNewDate: number = (value as Date).valueOf();
            const valueOfActivationDate: number = editedWorkOrder.activationDate!.valueOf();

            if (valueOfNewDate < valueOfActivationDate) {
                value = editedWorkOrder.activationDate! as WorkOrderModel[TKey];
            }
        }

        editedWorkOrder[key] = value;

        const originalHash: number = Utility.getHashCode(this.state.workOrder);
        const editedHash: number = Utility.getHashCode(editedWorkOrder);

        if (originalHash === editedHash) {
            await this.cancelAsync();
        } else {
            await this.setState({
                editedWorkOrder,
            });

            // no need to await
            // noinspection ES6MissingAwait
            this.form.validateAsync();
        }
    }

    private async isMounterWithoutContract(mounterId: string): Promise<boolean> {
        const mounterWithoutContract: Readonly<User> | undefined = this.getMounterWithoutContracts([mounterId]);
        return !mounterWithoutContract
    }

    private async validateSubcontractorMounters(mountersIds: string[]): Promise<void> {
        const mounterWithoutContract: Readonly<User> | undefined = this.getMounterWithoutContracts(mountersIds);

        if (mounterWithoutContract) {
            await this.subcontractorAssignmentModal.openAsync(mounterWithoutContract);
        }
    }

    private getMounterWithoutContracts(mountersIds: string[]) {
        const subcontractorMounters: Readonly<User>[] = this.state
            .mounters!.filter((mounter) => mounter.isSubcontractorMounter && mountersIds.includes(mounter.id));

        const allSubcontractorIds: string[] = this.state.subcontractorsOrganizationContractIds
            .concat(this.state.selectedMountersSubcontractorsOrganizationContractIds);

        const mounterWithoutContract: Readonly<User> | undefined = subcontractorMounters
            .find((mounter) => !allSubcontractorIds.includes(mounter.role.organizationContractId));
        return mounterWithoutContract;
    }

    private async unassignNewlyAssignedSubcontractorMounter(mounter: User) {
        const mounterIndex: number = this.workOrder.mountersIds.indexOf(mounter.id);
        this.state.editedWorkOrder!.mountersIds.splice(mounterIndex, 1);
        await this.subcontractorAssignmentModal.closeAsync();
        await this.updateSelectedSubcontractorMountersCompanyIds(this.state.editedWorkOrder!.mountersIds)
        await this._mountersDropdown.current!.reRenderAsync();
    }

    private async updateSelectedSubcontractorMountersCompanyIds(mountersIds: string[]): Promise<void> {
        const selectedSubcontractorMountersCompanyIds: string[] = this.state
            .mounters!.filter((mounter) => mounter.isSubcontractorMounter && mountersIds.includes(mounter.id))
            .map(mounter => { return mounter.role.organizationContractId });
        
        let selectedMountersSubcontractorsOrganizationContractIds: string[] = this.state.selectedMountersSubcontractorsOrganizationContractIds;
        
        const idsToAdd: string[] = selectedSubcontractorMountersCompanyIds
            .filter(selectedId => !selectedMountersSubcontractorsOrganizationContractIds.includes(selectedId));

        idsToAdd.forEach(id => {
            selectedMountersSubcontractorsOrganizationContractIds.push(id);
        });

        const idsToRemove: string[] = selectedMountersSubcontractorsOrganizationContractIds
            .filter(subcontractorId => !selectedSubcontractorMountersCompanyIds.includes(subcontractorId));
        
        idsToRemove.forEach(id => {
            const idIndex= selectedMountersSubcontractorsOrganizationContractIds.indexOf(id);
            selectedMountersSubcontractorsOrganizationContractIds.splice(idIndex, 1);
        })

        // Unique company ids of selected mounters;
        selectedMountersSubcontractorsOrganizationContractIds = selectedMountersSubcontractorsOrganizationContractIds
            .filter((id, index, ids) => (ids.indexOf(id) === index));
        
        this.setState({selectedMountersSubcontractorsOrganizationContractIds})
    }    

    private async editConstructionSiteOrWarehouseAsync(cow: Readonly<ConstructionSiteOrWarehouse>): Promise<void> {
        await this.editAsync("owner", cow);

        // Contact Persons can be different, reset current selection
        this.state.editedWorkOrder!.customerOrderer = null;
        this.state.editedWorkOrder!.customerApprover = null;

        // Get and set default prices
        const hoursPrice = this.workOrder.owner!.hoursPrice || this.userContext.defaultPrices.defaultHoursPrice;
        const mileagePrice = this.workOrder.owner!.mileagePrice || this.userContext.defaultPrices.defaultMileagePrice;
        const alarmJobPrice = this.workOrder.owner!.alarmJobPrice || this.userContext.defaultPrices.defaultAlarmJobPrice;
        await this.editAsync("hoursPrice", hoursPrice);
        await this.editAsync("mileagePrice", mileagePrice);
        await this.editAsync("alarmJobPrice", alarmJobPrice);

        // Refresh mounters list
        let mounters: User[] = await this.postAsync("/api/workOrders/getMounters", new GetEmployeesRequest());

        // Refresh GetConstructionSiteSubcontractorsOrganizationContractIds list
        let subcontractorsOrganizationContractIds: string[] = await this.postAsync("/api/constructionSiteManagement/getConstructionSiteSubcontractorsOrganizationContractIds", cow.id);

        await this.setState({ 
            mounters: mounters,
            subcontractorsOrganizationContractIds: subcontractorsOrganizationContractIds,

            // Clear mounter data because sub contractors change per site
            selectedMountersSubcontractorsOrganizationContractIds: [],
        });

        // Clear mounter data because sub contractors change per site
        await this.editAsync("mountersIds", []);

        await this.reRenderAsync();
    }
    
    private async pauseAsync(turnOnPause: boolean): Promise<void> {
        const request = new PauseWorkOrderRequest();
        request.workOrderId = this.workOrder!.id;

        const plannedCompletionDate : Date | null = this.workOrder!.plannedCompletionDate;
        
        if (turnOnPause) {
            request.pauseStartDate = Utility.now();

            if (plannedCompletionDate != null && request.pauseStartDate < plannedCompletionDate) {
                request.pauseEndDate = plannedCompletionDate;
            }
        } else {
            request.pauseStartDate = this.workOrder.pauseStartDate;
            request.pauseEndDate = Utility.now();
        }

        const response: PauseWorkOrderResponse = await WorkOrderProvider.pauseWorkOrderAsync(this, request);

        if (turnOnPause) {
            if (response.success){
                await this.flyoutSuccessAsync(Localizer.genericWorkOrderPausedSuccessfully);
            } else {
                await this.flyoutErrorAsync(Localizer.genericWorkOrderPauseFailed);
            }
        } else {
            if (response.success){
                await this.flyoutSuccessAsync(Localizer.genericWorkOrderUnpausedSuccessfully);
            } else {
                await this.flyoutErrorAsync(Localizer.genericWorkOrderUnpauseFailed);
            }
        }
        
        await this.reloadWorkOrderAsync();
        await this.reloadWorkOrderDetailsAsync();
    }

    private async cancelAsync(): Promise<void> {
        if (!this.hasChanges) {
            return;
        }

        const clearAsync: () => Promise<void> = async () => {
            await this.setState({
                editedWorkOrder: undefined,
            });
        };

        await clearAsync();

        // Needed to reset DD state(s)
        setTimeout(clearAsync, 1);
    }

    private async saveAsync(): Promise<void> {
        if (!this.hasChanges) {
            throw new Error("Cannot save without changes");
        }

        const createNew: boolean = this.creatingNew;

        const workOrder: WorkOrderModel = clone(this.workOrder);

        WorkOrderModel.initializeEmptyGuids(workOrder);

        const savedWorkOrder: WorkOrderModel = await WorkOrderProvider.saveWorkOrderAsync(this, workOrder);

        if (createNew) {
            const route: PageRoute = this.route;

            route.id = savedWorkOrder.id;

            // set new id and reload page (only way to replace current route id in history)
            await PageRouteProvider.redirectAsync(route, true);
        } else {
            await this.reloadWorkOrderAsync();

            await this.flyoutSuccessAsync(Localizer.genericWorkOrderSavedSuccessfully);
        }

        await this.reloadWorkOrderDetailsAsync();
    }

    private async deleteAsync(): Promise<void> {

        // New WO, no changes
        if ((this.creatingNew) && (!this.hasChanges)) {
            await this.navigateBackAsync();
            return;
        }

        // Delete cancelled
        if (!(await this.confirmAsync(Localizer.confirmationDialogAreYouSure))) {
            return;
        }

        // Existing WO
        if (!this.creatingNew) {
            await WorkOrderProvider.deleteWorkOrderAsync(this, this.workOrder);
        }

        // New WO
        await this.navigateBackAsync();
    }

    private async listFormsInModalAsync(): Promise<void> {
        if (this.hasChanges) {
            throw new Error("Cannot list forms for edited model");
        }

        const request = new GetWorkOrderFormsRequest();
        request.workOrderId = this.workOrder.id;

        const forms: FormModel[] = await this.getWorkOrderFormsAsync(this, request);

        if (forms && forms.length > 0) {
            await this.formModal.openAsync(this.workOrder, forms);
        } else {
            await ch.flyoutWarningAsync(Localizer.get(Localizer.workOrdersPanelFlyoutWarningTaskNotMappedToFormDefinition, this.workOrder.name));
        }
    }

    private async copyAsync(): Promise<void> {
        if (this.hasChanges) {
            throw new Error("Cannot copy edited model");
        }

        const copy: WorkOrderModel | null = await WorkOrderProvider.copyWorkOrderAsync(this, this.workOrder);
        if (copy !== null) {
            if (UnleashHelper.isEnabled(FeatureFlags.RedirectToCopiedWorkOrder)) {
                await PageRouteProvider.redirectAsync(PageDefinitions.workOrderRoute(copy.id));
            }

            await this.flyoutSuccessAsync(Localizer.genericWorkOrderCopiedSuccessfully);
        }
    }

    private async previewAsync(): Promise<void> {
        if (this.hasChanges) {
            throw new Error("Cannot preview edited model");
        }

        await WorkOrderProvider.previewWorkOrderPdfAsync(this.workOrder);
    }

    private async previewWithPricesAsync(): Promise<void> {
        if (this.hasChanges) {
            throw new Error("Cannot preview edited model");
        }

        await WorkOrderProvider.previewWorkOrderPdfWithPricesAsync(this.workOrder);
    }

    private async previewWithCommentsAsync(): Promise<void> {
        if (this.hasChanges) {
            throw new Error("Cannot preview edited model");
        }

        await WorkOrderProvider.previewWorkOrderPdfWithCommentsAsync(this.workOrder);
    }

    private async lockAsync(): Promise<void> {
        if (this.hasChanges) {
            throw new Error("Cannot lock edited model");
        }

        const workOrder: WorkOrderModel = await WorkOrderProvider.lockWorkOrderAsync(this, this.workOrder.id);

        await this.setState({workOrder});

        await this.flyoutSuccessAsync(Localizer.genericWorkOrderLockedSuccessfully);
    }

    private async unlockAsync(): Promise<void> {
        if (this.hasChanges) {
            throw new Error("Cannot unlock edited model");
        }

        const workOrder: WorkOrderModel = await WorkOrderProvider.unlockWorkOrderAsync(this, this.workOrder);

        await this.setState({workOrder});

        await this.flyoutSuccessAsync(Localizer.genericWorkOrderUnlockedSuccessfully);
    }

    private async onActivateAsync(): Promise<void> {
        if (WorkOrderModel.canReactivate(this.workOrder)) {
            await this.changeStatusModal.openAsync(this.workOrder);
        } else {
            await this.activateAsync(this.workOrder.id, false, null);
        }
    }

    private async activateAsync(workOrderId: string, sendNotification: boolean, comment: string | null): Promise<void> {
        if (this.hasChanges) {
            throw new Error("Cannot activate edited model");
        }

        const request = new ActivateWorkOrderRequest();
        request.workOrderId = workOrderId;
        request.sendNotification = sendNotification;
        request.comment = comment;

        await WorkOrderProvider.activateWorkOrderAsync(this, request);

        await this.flyoutSuccessAsync(Localizer.genericWorkOrderActivatedSuccessfully);

        await this.reloadWorkOrderAsync();
        await this.reloadWorkOrderDetailsAsync();
    }

    private async completeAsync(): Promise<void> {
        if (this.hasChanges) {
            throw new Error("Cannot complete edited model");
        }

        const response: CompleteWorkOrderResponse = await WorkOrderProvider.completeWorkOrderAsync(this, this.workOrder);
        const workOrder: WorkOrderModel | null = response.workOrder;

        if (!workOrder) {
            await this.flyoutErrorAsync(Localizer.genericWorkOrderCompletionFailed);
        } else {
            await this.flyoutSuccessAsync(Localizer.genericWorkOrderCompletedSuccessfully);
        }

        await this.reloadWorkOrderAsync();
        await this.reloadWorkOrderDetailsAsync();
    }

    private async invoiceAsync(createOrder: boolean): Promise<void> {
        if (this.hasChanges) {
            throw new Error("Cannot invoice edited model");
        }

        if (UnleashHelper.isEnabled(FeatureFlags.WorkOrderCostPool) && (!this.workOrder.costPool || this.workOrder.costPool.deleted)) {
            await this.flyoutErrorAsync(Localizer.genericWorkOrderRequiredCostPool);
            return;
        }
        
        const setInvoicedResponse: SetInvoicedResponse = await WorkOrderProvider.invoiceWorkOrderAsync(this, this.workOrder, createOrder);
        
        if (setInvoicedResponse.success) {
            const workOrder: WorkOrderModel = setInvoicedResponse.workOrderModel!;

            await this.setState({workOrder});

            await this.flyoutSuccessAsync(Localizer.genericWorkOrderInvoicedSuccessfully);
        }
        else {
            await this.flyoutErrorAsync(setInvoicedResponse.errorMessage!);
        }
        
    }

    private async unInvoiceAsync(): Promise<void> {
        if (this.hasChanges) {
            throw new Error("Cannot uninvoice edited model");
        }

        const workOrder: WorkOrderModel = await WorkOrderProvider.uninvoiceWorkOrderAsync(this, this.workOrder);

        await this.setState({workOrder});

        await this.flyoutSuccessAsync(Localizer.genericWorkOrderUninvoicedSuccessfully);
    }

    private async sendAsync(approveOrSend: "approve" | "send"): Promise<void> {
        if (this.hasChanges) {
            throw new Error("Cannot approve or send edited model");
        }

        if ((!this.workOrder.customerApprover) || (isNullOrDefaultGuid(this.workOrder.customerApprover.id))) {
            throw new Error(`Cannot send/approve work order without customer approver`);
        }

        const approveWorkOrder: boolean = (approveOrSend === "approve");

        const approveWorkOrderRequest = new ApproveWorkOrderByEmailRequest();

        approveWorkOrderRequest.workOrderId = this.workOrder.id;
        approveWorkOrderRequest.approved = (approveWorkOrder);
        approveWorkOrderRequest.customerApprover = this.workOrder.customerApprover;

        approveWorkOrderRequest.approvalType = (approveWorkOrder)
            ? CustomerApprovalType.Phone
            : CustomerApprovalType.Email;

        const resp = await WorkOrderProvider.approveWorkOrderByEmailAsync(this, approveWorkOrderRequest);

        if (resp.successfully) {
            const message: string = (approveWorkOrder)
                ? Localizer.genericWorkOrderApprovedSuccessfully
                : Localizer.genericWorkOrderSentSuccessfully;

            await this.flyoutSuccessAsync(message)

            await this.reloadWorkOrderAsync();
            await this.reloadWorkOrderDetailsAsync();
        } else {
            const message: string = (approveWorkOrder)
                ? Localizer.genericWorkOrderApprovalFailed
                : Localizer.genericWorkOrderSendingFailed;

            await this.flyoutErrorAsync(message);
        }
    }

    private readonly approveSentWorkOrderAsync = async (): Promise<void> => {
        if (await this.confirmAsync(Localizer.workOrderActionsConfirmApproveSent)) {
            await WorkOrderProvider.approveSentWorkOrderAsync(this, this.workOrder.id);
            await this.reloadWorkOrderAsync();
            await this.reloadWorkOrderDetailsAsync();
            await this.flyoutSuccessAsync(Localizer.genericWorkOrderApprovedSuccessfully);
        }
    }

    private async getWorkOrderFormsAsync(sender: IBaseComponent, request: GetWorkOrderFormsRequest): Promise<FormModel[]> {
        return await sender.postAsync("api/constructionSiteManagement/getWorkOrderForms", request);
    }

    private async saveFormAsync(sender: IBaseComponent, request: SaveFormRequest): Promise<void> {
        await sender.postAsync("api/constructionSiteManagement/saveForm", request);

        await ch.flyoutMessageAsync(Localizer.workOrdersPanelFlyoutMessageFormSaved);

        await this.reloadWorkOrderAsync();
        await this.reloadWorkOrderDetailsAsync();
    }

    private async onDetailsChangedAsync(): Promise<void> {
        if (!this.editability.pricesEditable) {
            throw new Error("Cannot change details of invoiced work order");
        }

        if (this.hasChanges) {
            throw new Error("Cannot change details of edited work order");
        }

        await this.reloadWorkOrderAsync();
    }

    private async openSendWorkOrderModalAsync(contactPersonType: WorkOrderContactPersonType): Promise<void> {
        let action: SendWorkOrderModalAction;

        if (contactPersonType == WorkOrderContactPersonType.Orderer) {
            action = (this.canAssignContactPerson)
                ? SendWorkOrderModalAction.SelectCreateEditWorkOrderOrderer
                : SendWorkOrderModalAction.DisplayWorkOrderOrderer
            ;
        } else {
            action = (this.canAssignContactPerson)
                ? SendWorkOrderModalAction.SelectCreateEditWorkOrderApprover
                : SendWorkOrderModalAction.DisplayWorkOrderApprover
        }

        const workOrder: WorkOrderModel = clone(this.workOrder);

        await this.sendWorkOrderModal.openAsync({
            workOrder: workOrder,
            action: action
        });
    }

    private async onSendWorkOrderModalCloseAsync(
        workOrder: WorkOrderModel,
        success: boolean
    ): Promise<void> {
        if (!success) {
            return;
        }

        const customerOrdererChanged: boolean = !Comparator.isEqual(this.workOrder.customerOrderer, workOrder.customerOrderer);
        const customerApproverChanged: boolean = !Comparator.isEqual(this.workOrder.customerApprover, workOrder.customerApprover);

        if (customerOrdererChanged) {
            await this.editAsync("customerOrderer", workOrder.customerOrderer);
        }

        if (customerApproverChanged) {
            await this.editAsync("customerApprover", workOrder.customerApprover);
        }
    }

    private async onConfirmAsync(workOrderId: string, sendNotification: boolean, comment: string | null): Promise<void> {
        if (this._changeStatusModalRef.current) {
            await this.activateAsync(workOrderId, sendNotification, comment);
        }
    }

    private async openStatusMessage(): Promise<void> {
        if (this._statusMessageModalRef.current && this.workOrder) {
            await this._statusMessageModalRef.current!.openAsync(this.workOrder);
        }
    }

    private contactPersonDataMissing(contactPerson: User | null): boolean {
        return ((contactPerson == null || !User.isContactPersonValid(contactPerson)) && (this.hasChanges || !this.creatingNew));
    }

    private contactPersonButtonTitle(contactPersonType: WorkOrderContactPersonType): string {
        switch (contactPersonType) {
            case WorkOrderContactPersonType.Orderer:
                return (this.canAssignContactPerson)
                    ? Localizer.workOrderButtonTitleAssignOrderer
                    : Localizer.workOrderButtonTitleOrdererDetails;
            case WorkOrderContactPersonType.Approver:
                return (this.canAssignContactPerson)
                    ? Localizer.workOrderButtonTitleAssignApprover
                    : Localizer.workOrderButtonTitleApproverDetails;
            default:
                throw new TypeError(`Invalid contact person type: ${contactPersonType}`);
        }
    }
    
    private renderDefaultPrices(): ReactNode {
        const content: ReactNode = (
            <React.Fragment>
                <NumberInput id="workOrderPricePerKilometer"
                             readonly={(!this.editability.pricesEditable)}
                             label={`${Localizer.genericDefault} ${this.moneySign}/${Localizer.genericAbbreviationsKilometers}`}
                             value={this.workOrder.mileagePrice}
                             min={0}
                             step={0.01}
                             format="0.00"
                             onChange={async (_, value) => await this.editAsync("mileagePrice", value)}
                />
    
                <NumberInput id="workOrderPricePerHour"
                             readonly={(this.readonly)}
                             label={`${Localizer.genericDefault} ${this.moneySign}/${Localizer.genericAbbreviationsHours}`}
                             value={this.workOrder.hoursPrice}
                             min={0}
                             onChange={async (_, value) => await this.editAsync("hoursPrice", value)}
                />

                {
                    // <FeatureSwitch> not used here because it takes up one column even if the flag is disabled.
                    UnleashHelper.isEnabled(FeatureFlags.AlarmJobs) &&
                    (
                        <NumberInput id="workOrderAlarmJobPrice"
                                     readonly={(this.readonly)}
                                     label={Localizer.genericDefaultAlarmJobPrice}
                                     value={this.workOrder.alarmJobPrice}
                                     min={0}
                                     onChange={async (_, value) => await this.editAsync("alarmJobPrice", value)}
                        />
                    )
                }
    
                <NumberInput readonly
                             id="workOrderCost"
                             label={Localizer.genericCost}
                             value={this.workOrder.cost}
                />
            </React.Fragment>
        );
        
        return UnleashHelper.isEnabled(FeatureFlags.AlarmJobs)
            ? (<FourColumns>{content}</FourColumns>)
            : (<ThreeColumns>{content}</ThreeColumns>);
    }
    
    private renderInvoiceButton(): ReactNode {
        if (UnleashHelper.isEnabled(FeatureFlags.SendCreateOrderRequest)) {
            const invoiceWithOrderLabel: string = ch.isFinland ? Localizer.finlandWorkOrderInvoiceAction : Localizer.genericActionInvoice;
            const invoiceWithoutOrderLabel: string = ch.isFinland ? Localizer.finlandWorkOrderMarkAsInvoicedAction : Localizer.workOrderInvoiceWithoutCreatingOrder;
            
            return (
                <Button id="workOrderInvoice"
                           label={Localizer.genericActionInvoice}
                           type={ButtonType.Success}
                           icon={{name: "file-invoice"}}>
                    <ButtonAction
                        title={invoiceWithOrderLabel}
                        onClick={async () => this.invoiceAsync(true)}
                    />
    
                    <ButtonAction
                        title={invoiceWithoutOrderLabel}
                        onClick={async () => this.invoiceAsync(false)}
                    />
                </Button>
            );       
        } else {
            // No options if order creation is disabled.
            return (
                <Button id="workOrderInvoice"
                    label={Localizer.genericActionInvoice}
                    type={ButtonType.Success}
                    icon={{name: "file-invoice"}}
                    onClick={async () => this.invoiceAsync(false)}
                />
            );
        }
        
        
     
    }

    private renderContent(): ReactNode {
        if (!this.workOrder) {
            return (
                <Spinner global/>
            );
        }

        return (
            <>
                <Form ref={this._formRef}
                      onSubmit={async () => await this.saveAsync()}
                >

                    <TwoColumns>
                        <Dropdown required autoGroupSelected
                                  id="workOrderSiteOrWarehouse"
                                  requiredType={DropdownRequiredType.Restricted}
                                  disabled={this.constructionSiteOrWarehouseSelectionDisabled}
                                  label={Localizer.checkInsPanelSiteOrWarehouseLanguageItemName}
                                  items={this.state.constructionSitesOrWarehouses ?? []}
                                  selectedItem={this.workOrder.owner || this._parameters?.ownerId}
                                  onChange={async (_, value) => await this.editConstructionSiteOrWarehouseAsync(value!)}
                        />

                        <TwoColumns>
                            <TextInput readonly
                                       id="workOrderNumber"
                                       label={Localizer.genericNumber}
                                       value={this.workOrderNumber}
                            />

                            <TextInput readonly
                                       id="workOrderStatus"
                                       label={Localizer.genericStatus}
                                       value={this.workOrderStatus}
                                       append={
                                           (this.showStatusMessageIcon) &&
                                           (
                                               <Icon id="statusMessageIcon"
                                                     className={style.bell}
                                                     name="fas fa-bell-exclamation"
                                                     size={IconSize.Large}
                                                     onClick={() => this.openStatusMessage()}
                                               />
                                           )}
                                       prepend={<Icon {...WorkOrderModel.getStateWithBlockingIcon(this.workOrder)} />}
                            />
                        </TwoColumns>
                    </TwoColumns>
                    
                    <TwoColumns>
                        <TextInput required
                                   id="workOrderName"
                                   readonly={(this.readonly)}
                                   label={Localizer.genericName}
                                   value={this.workOrder.name}
                                   onChange={async (_, value) => await this.editAsync("name", value)}
                        />

                        <TextInput id="workOrderInvoiceReference"
                                   readonly={(this.readonly)}
                                   label={Localizer.genericInvoiceReference}
                                   value={this.workOrder.invoiceReference}
                                   onChange={async (_, value) => await this.editAsync("invoiceReference", value)}
                        />
                    </TwoColumns>
                    
                    <TwoColumns>
                        {
                            (this.isManager || this.isBusinessManager || this.isAdmin) &&
                            (
                                <Dropdown required
                                          id="workOrderContractType"
                                          requiredType={DropdownRequiredType.Restricted}
                                          orderBy={DropdownOrderBy.None}
                                          label={Localizer.workOrderContractTypeDropdownLabel}
                                          items={EnumProvider.getWorkOrderContractTypeItems()}
                                          disabled={this.readonly}
                                          selectedItem={this.workOrder.contractType}
                                          onChange={async (_, value) => {
                                              const newType: WorkOrderContractType = parseInt(value!.value) as WorkOrderContractType;
                                              if (this.workOrder.contractType === newType) {
                                                  // Workaround to avoid breaking cancel logic of this component
                                                  // by unnecessary change events during data load.
                                                  return;
                                              }

                                              await this.editAsync("contractType", newType);
                                          }}
                                />
                            )
                        }
                        {
                            (UnleashHelper.isEnabled(FeatureFlags.WorkOrderCostPool)) &&
                            (
                                <Dropdown<CostPool>
                                    autoGroupSelected small noValidate noSubtext noWrap
                                    id="workOrderCostPool"
                                    ref={this._costPoolsDropdown}
                                    label={Localizer.genericCostPools}
                                    items={this.state.costPools ?? []}
                                    disabled={(!this.editability.costPoolEditable)}
                                    selectedItem={this.workOrder.costPool || undefined}
                                    onChange={async (dropdown) => await this.editAsync("costPool", dropdown.selectedItem)}
                                />
                            )
                        }
                    </TwoColumns>
                    
                    <TwoColumns>
                        <Dropdown required autoGroupSelected
                                  requiredType={DropdownRequiredType.Restricted}
                                  id="workOrderType"
                                  disabled={(!this.creatingNew)}
                                  label={Localizer.genericWorkOrderTypeLabel}
                                  items={this.state.workOrderTypes ?? []}
                                  selectedItem={this.workOrder.type}
                                  onChange={async (_, value) => await this.editAsync("type", value!)}
                        />

                        {
                            (this.workOrder.invoiced && UnleashHelper.isEnabled(FeatureFlags.SendCreateOrderRequest)) &&
                            (
                                <TextInput readonly
                                           id="contractNo"
                                           label={Localizer.workOrderContractNo}
                                           value={this.workOrder.contractNo}
                                />
                            )
                        }
                    </TwoColumns>
                    
                    <TextAreaInput id="workOrderDescription"
                                   readonly={(this.readonly)}
                                   label={Localizer.genericDescription}
                                   value={this.workOrder.description}
                                   maxLength={RentaTaskConstants.bigStringLength}
                                   onChange={async (_, value) => await this.editAsync("description", value)}
                    />

                    <TextAreaInput id="workOrderJobSummary"
                                   readonly={(this.readonly)}
                                   label={Localizer.workOrdersJobSummary}
                                   value={this.workOrder.jobSummary}
                                   maxLength={RentaTaskConstants.bigStringLength}
                                   onChange={async (_, value) => await this.editAsync("jobSummary", value)}
                    />

                    <ThreeColumns>
                        <div className={"d-flex align-items-center"}>
                            <Dropdown<User> multiple autoGroupSelected
                                      id="workOrderMounters"
                                      ref={this._mountersDropdown}
                                      className={this.css((this.workOrder.currentStatus > WorkOrderStatus.Completed) && style.dropdownMaxWidth)}
                                      disabled={((this.readonly) || (!this.workOrder.owner && !this._parameters?.ownerId) || this.isSalesPerson)}
                                      label={Localizer.genericMounters}
                                      items={this.state.mounters ?? []}
                                      selectedItems={this.workOrder.mountersIds}
                                      onChange={async (dropdown) => await this.editAsync("mountersIds", dropdown.selectedItems.map((user) => (user as unknown as User).id))}   // Automagic conversion happening inside the DD
                            />

                            {
                                (this.workOrder.currentStatus > WorkOrderStatus.Completed && this.workOrder.mounters.length > 0) &&
                                (
                                    <Button id="workOrderMountersInfo"
                                            className={style.infoButton}
                                            type={ButtonType.Info}
                                            icon={{name: "far info"}}
                                            onClick={async () => await this._usersInfoModalRef.current!.openAsync(this.workOrder.mounters)}
                                    />
                                )
                            }
                        </div>

                        <Dropdown required autoGroupSelected
                                  requiredType={DropdownRequiredType.Restricted}
                                  id="workOrderManager"
                                  disabled={((this.readonly) || (!this.workOrder.owner && !this._parameters?.ownerId))}
                                  label={Localizer.genericManager}
                                  items={this.state.managers ?? []}
                                  selectedItem={this.workOrder.manager}
                                  onChange={async (dropdown) => await this.editAsync("manager", dropdown.selectedItem)}
                        />

                        {
                            (UnleashHelper.isEnabled(FeatureFlags.SalesPerson)) &&
                            (
                                <Dropdown autoGroupSelected
                                          id="workOrderSalesPerson"
                                          disabled={((this.readonly) || (!this.workOrder.owner && !this._parameters?.ownerId))}
                                          label={Localizer.addConstructionsiteSalesPerson}
                                          items={this.state.salesPersons ?? []}
                                          selectedItem={this.workOrder.salesPerson}
                                          onChange={async (dropdown) => await this.editAsync("salesPerson", dropdown.selectedItem)}
                                />
                            )
                        }
                    </ThreeColumns>

                    <ThreeColumns>
                        <DateInput id="workOrderStart"
                                   showTime
                                   readonly={(this.readonly)}
                                   required={(this.workOrder.completionDate != null)}
                                   label={Localizer.genericActivation}
                                   value={this.workOrder.activationDate}
                                   minDate={this.minActivationDate}
                                   minTime={this.minActivationTime}
                                   maxDate={this.maxActivationDate}
                                   maxTime={this.maxActivationTime}
                                   timeCaption={Localizer.genericTime}
                                   timeFormat={"HH:mm"}
                                   timeIntervals={this._workOrderAccuracyMinutes}
                                   onChange={async (value) => await this.editAsync("activationDate", value)}
                        />

                        <DateInput id="workOrderEnd"
                                   showTime
                                   readonly={(this.readonly || this.workOrder.hasBlockingForms)}
                                   label={Localizer.genericCompletion}
                                   value={this.workOrder.completionDate}
                                   minDate={this.minCompletionDate}
                                   minTime={this.minCompletionTime}
                                   maxDate={this.maxCompletionDate}
                                   maxTime={this.maxCompletionTime}
                                   timeCaption={Localizer.genericTime}
                                   timeFormat={"HH:mm"}
                                   timeIntervals={this._workOrderAccuracyMinutes}
                                   onChange={async (value) => await this.editAsync("completionDate", value)}
                        />

                        <DateInput id="workOrderPlannedEnd"
                                   showTime
                                   readonly={(this.readonly)}
                                   label={Localizer.genericPlannedCompletion}
                                   value={this.workOrder.plannedCompletionDate}
                                   minDate={this.minPlannedCompletionDate}
                                   minTime={this.minPlannedCompletionTime}
                                   maxDate={this.maxPlannedCompletionDate}
                                   maxTime={this.maxPlannedCompletionTime}
                                   timeCaption={Localizer.genericTime}
                                   timeFormat={"HH:mm"}
                                   timeIntervals={this._workOrderAccuracyMinutes}
                                   onChange={async (value) => await this.editAsync("plannedCompletionDate", value)}
                        />
                    </ThreeColumns>

                    {
                        ((this.isBusinessManager || this.isManager || this.isAdmin) && WorkOrderModel.isActive(this.workOrder)) &&
                        <ThreeColumns>
                            <DateInput showTime
                                       id="workOrderPauseStart"
                                       readonly={(this.readonly)}
                                       label={Localizer.workOrderPauseStartLabel}
                                       value={this.workOrder.pauseStartDate}
                                       minDate={this.workOrder.activationDate}
                                       maxDate={this.workOrder.pauseEndDate ?? this.workOrder.plannedCompletionDate ?? this.workOrder.completionDate}
                                       minTime={this.firstAvailablePauseStartTimeslot}
                                       maxTime={this.lastAvailablePauseStartTimeslot}
                                       timeCaption={Localizer.genericTime}
                                       timeFormat={"HH:mm"}
                                       timeIntervals={this._workOrderAccuracyMinutes}
                                       onChange={async (value) => await this.editAsync("pauseStartDate", value)}
                            />

                            <DateInput showTime
                                       id="workOrderPauseEnd"
                                       readonly={(this.readonly)}
                                       label={Localizer.workOrderPauseEndLabel}
                                       value={this.workOrder.pauseEndDate}
                                       minDate={this.workOrder.pauseStartDate ?? this.workOrder.activationDate}
                                       maxDate={this.workOrder.plannedCompletionDate}
                                       minTime={this.firstAvailablePauseEndTimeslot}
                                       maxTime={this.lastAvailablePauseEndTimeslot}
                                       timeCaption={Localizer.genericTime}
                                       timeFormat={"HH:mm"}
                                       timeIntervals={this._workOrderAccuracyMinutes}
                                       onChange={async (value) => await this.editAsync("pauseEndDate", value)}
                            />
                        </ThreeColumns>
                    }

                    <h5 className={style.section}>{Localizer.workOrderHourlyPriceHeader}</h5>
                    
                    {this.renderDefaultPrices()}

                    {
                        (this.displayFixedCostInputs) &&
                        (
                            <React.Fragment>
                                <h5 className={style.section}>{Localizer.workOrderFixedPriceHeader}</h5>

                                <FourColumns>
                                    <NumberInput id="workOrderFixedTransportationCost"
                                                 readonly={(!this.editability.pricesEditable)}
                                                 label={Localizer.workOrderFixedTransportationCostLabel}
                                                 value={this.workOrder.fixedTransportationCost}
                                                 min={0}
                                                 step={0.01}
                                                 format="0.00"
                                                 onChange={async (_, value) => await this.editAsync("fixedTransportationCost", value)}
                                    />
    
                                    <NumberInput id="workOrderFixedHoursCost"
                                                 readonly={(!this.editability.pricesEditable)}
                                                 label={Localizer.workOrderFixedHoursCostLabel}
                                                 value={this.workOrder.fixedHoursCost}
                                                 min={0}
                                                 step={0.01}
                                                 format="0.00"
                                                 onChange={async (_, value) => await this.editAsync("fixedHoursCost", value)}
                                    />
                                    
                                    <NumberInput id="workOrderFixedProductCost"
                                                 readonly={(!this.editability.pricesEditable)}
                                                 label={Localizer.workOrderFixedProductCostLabel}
                                                 value={this.workOrder.fixedProductCost}
                                                 min={0}
                                                 step={0.01}
                                                 format="0.00"
                                                 onChange={async (_, value) => await this.editAsync("fixedProductCost", value)}
                                    />
    
                                    <NumberInput id="workOrderFixedCost"
                                                 readonly={(!this.editability.pricesEditable)}
                                                 label={Localizer.workOrderFixedCostLabel}
                                                 value={this.workOrder.fixedCost}
                                                 min={0}
                                                 step={0.01}
                                                 format="0.00"
                                                 onChange={async (_, value) => await this.editAsync("fixedCost", value)}
                                    />
                                </FourColumns>
                            </React.Fragment>
                        )
                    }


                    <h5 className={style.section}>{Localizer.genericContactPersonDetails}</h5>


                    <ThreeColumns>

                        <>
                            <Inline>

                                {/* The work order' orderer full name with custom validation. */}
                                <TextInput readonly
                                           id="ordererFullName"
                                           width={"30ch"}
                                           className={this.css((this.contactPersonDataMissing(this.workOrder.customerOrderer)) && style.notAssigned)}
                                           label={this.ordererLabel}
                                           value={(this.workOrder.customerOrderer != null) ? TransformProvider.userToString(this.workOrder.customerOrderer) : "-"}
                                />

                                <Button id="assignCustomerOrdererButton"
                                        className={style.assignContactPersonButton}
                                        disabled={(!this.hasOwner)}
                                        type={this.contactPersonButtonType}
                                        icon={this.contactPersonButtonIcon}
                                        title={this.contactPersonButtonTitle(WorkOrderContactPersonType.Orderer)}
                                        onClick={async () => await this.openSendWorkOrderModalAsync(WorkOrderContactPersonType.Orderer)}
                                />

                            </Inline>
                        </>

                        <>
                            <Inline>

                                {/* The work order' approver full name with custom validation. */}
                                <TextInput readonly
                                           id="approverFullName"
                                           width={"30ch"}
                                           className={this.css((this.contactPersonDataMissing(this.workOrder.customerApprover)) && style.notAssigned)}
                                           label={this.approverLabel}
                                           value={(this.workOrder.customerApprover != null) ? TransformProvider.userToString(this.workOrder.customerApprover) : "-"}
                                />

                                <Button id="assignCustomerApproverButton"
                                        className={style.assignContactPersonButton}
                                        disabled={(!this.hasOwner)}
                                        type={this.contactPersonButtonType}
                                        icon={this.contactPersonButtonIcon}
                                        title={this.contactPersonButtonTitle(WorkOrderContactPersonType.Approver)}
                                        onClick={async () => await this.openSendWorkOrderModalAsync(WorkOrderContactPersonType.Approver)}
                                />

                            </Inline>
                        </>

                    </ThreeColumns>

                    {
                        (this.workOrder.approved) &&
                        (
                            <>
                                <h5 className={style.section}>{Localizer.genericApproval}</h5>

                                <ThreeColumns>
                                    <DateInput readonly
                                               id="workOrderApprovedAt"
                                               label={Localizer.genericDate}
                                               value={this.workOrder.approvedAt}
                                    />

                                    <TextInput readonly
                                               id="workOrderApprovalType"
                                               width={"30ch"}
                                               label={Localizer.genericType}
                                               value={this.approvalType}
                                    />
                                </ThreeColumns>
                            </>
                        )
                    }

                    <Inline className={style.section}>
                        {
                            (this.actions.forms) &&
                            (
                                <Button id="forms"
                                        label={Localizer.workOrdersPanelWorkOrderForms}
                                        type={ButtonType.Success}
                                        icon={{name: "list-alt"}}
                                        onClick={async () => this.listFormsInModalAsync()}
                                />
                            )
                        }

                        {
                            (this.actions.copy) &&
                            (
                                <Button id="workOrderCopy"
                                        label={Localizer.genericActionCopy}
                                        type={ButtonType.Secondary}
                                        icon={{name: "copy"}}
                                        onClick={async () => this.copyAsync()}
                                />
                            )
                        }

                        {
                            (this.actions.preview) &&
                            (
                                <Button id="workOrderPreview"
                                        label={Localizer.genericActionPreview}
                                        type={ButtonType.Secondary}
                                        icon={{name: "search"}}
                                >
                                    <ButtonAction title={Localizer.genericWorkOrder}
                                                  onClick={async () => this.previewAsync()}
                                    />

                                    {
                                        (this.isBusinessManager || this.isAdmin) && (
                                            <>
                                                <ButtonAction title={Localizer.genericInvoice}
                                                              onClick={async () => this.previewWithPricesAsync()}
                                                />

                                                <FeatureSwitch flagName={FeatureFlags.WorkReportWithComments}>
                                                    <ButtonAction title={Localizer.workReportWithCommentsActionLabel}
                                                                  onClick={async () => this.previewWithCommentsAsync()}
                                                    />
                                                </FeatureSwitch>
                                            </>
                                        )
                                    }
                                </Button>
                            )
                        }

                        {
                            (this.actions.activate) &&
                            (
                                <Button id="workOrderActivate"
                                        label={Localizer.genericActionActivate}
                                        type={ButtonType.Blue}
                                        icon={{name: "play-circle"}}
                                        onClick={async () => this.onActivateAsync()}
                                />
                            )
                        }

                        {
                            (this.actions.complete) &&
                            (
                                <Button id="workOrderComplete"
                                        label={Localizer.genericActionComplete}
                                        type={ButtonType.Blue}
                                        icon={{name: "check-circle"}}
                                        onClick={async () => this.completeAsync()}
                                />
                            )
                        }

                        {
                            (this.actions.lock) &&
                            (
                                <Button id="workOrderApprove"
                                        label={Localizer.genericActionApprove}
                                        type={ButtonType.Success}
                                        icon={{name: "thumbs-up"}}
                                        onClick={async () => await this._approveWorkOrderModalRef.current!.openAsync(this.workOrder!)}
                                />
                            )
                        }

                        {
                            (this.actions.unlock) &&
                            (
                                <Button id="workOrderUnApprove"
                                        label={Localizer.genericActionUnlock}
                                        type={ButtonType.Warning}
                                        icon={{name: "unlock-alt"}}
                                        onClick={async () => this.unlockAsync()}
                                />
                            )
                        }

                        {
                            (this.actions.send) &&
                            (
                                <Button id={`${SendWorkOrderModal.modalId}FormSendOrApprove`}
                                        label={Localizer.sendWorkOrderModalButtonSendOrApprove}
                                        type={ButtonType.Blue}
                                        icon={{name: "envelope"}}
                                        disabled={this.readonly}
                                >
                                    {
                                        // Sending work report is not allowed for fixed price work orders
                                        (WorkOrderModel.allowCustomerApproval(this.workOrder)) &&
                                        (
                                            <ButtonAction title={Localizer.sendWorkReportTitle}
                                                          onClick={async () => this.sendAsync("send")}
                                            />
                                        )
                                    }

                                    <ButtonAction title={Localizer.approveWorkReportTitle}
                                                  onClick={async () => this.sendAsync("approve")}
                                    />
                                </Button>
                            )
                        }

                        {
                            (this.actions.approveSent) && (
                                <Button id="approveSentWorkOrder"
                                        label={Localizer.approveWorkReportTitle}
                                        type={ButtonType.Success}
                                        icon={{name: "check"}}
                                        onClick={this.approveSentWorkOrderAsync}
                                />
                            )
                        }

                        {
                            (this.actions.invoice) &&  (this.renderInvoiceButton())
                        }

                        {
                            (this.actions.cancelInvoice) &&
                            (
                                <Button id="workOrderUnInvoice"
                                        label={Localizer.genericActionUninvoice}
                                        type={ButtonType.Danger}
                                        icon={{name: "align-slash"}}
                                        onClick={async () => this.unInvoiceAsync()}
                                />
                            )
                        }

                        {
                            (this.actions.delete) &&
                            (
                                <Button id="workOrderRemove"
                                        label={Localizer.genericActionDelete}
                                        type={ButtonType.Danger}
                                        icon={{name: "trash-alt"}}
                                        onClick={async () => this.deleteAsync()}
                                />
                            )
                        }

                        {
                            (this.actions.cancel) &&
                            (
                                <Button id="workOrderCancel"
                                        label={Localizer.genericActionCancel}
                                        type={ButtonType.Warning}
                                        icon={{name: "ban"}}
                                        onClick={async () => this.cancelAsync()}
                                />
                            )
                        }

                        {
                            (this.actions.save) &&
                            (
                                <Button submit
                                        id="workOrderSave"
                                        label={Localizer.genericActionSave}
                                        type={ButtonType.Success}
                                        icon={{name: "save"}}
                                />
                            )
                        }

                        {
                            (this.actions.pause) &&
                            (
                                <Button 
                                        id="workOrderPause"
                                        label={Localizer.genericActionPauseTask}
                                        type={ButtonType.Warning}
                                        icon={{name: "pause"}}
                                        onClick={async () => this.pauseAsync(true)}
                                />
                            )
                        }

                        {
                            (this.actions.unpause) &&
                            (
                                <Button id="workOrderUnpause"
                                        label={Localizer.genericActionUnpauseTask}
                                        type={ButtonType.Success}
                                        icon={{name: "play"}}
                                        onClick={async () => this.pauseAsync(false)}
                                />
                            )
                        }
                    </Inline>

                </Form>

                <hr/>

                {
                    (!this.creatingNew) &&
                    (
                        <WorkOrderDetailsPanel ref={this._workOrderDetailsPanelRef}
                                               readonly={this.hasChanges}
                                               workOrder={this.workOrderDuplicate}   // The Panel mutates its inputs, perform defensive copying
                                               mounters={this.state.mounters as User[] ?? []}
                                               products={this.state.products as Product[] ?? []}
                                               defaultPrices={this.defaultPrices}
                                               onChange={async () => await this.onDetailsChangedAsync()}
                                               isSubContractorMounterValid={async (mounterId: string) => await this.isMounterWithoutContract(mounterId)}
                        />
                    )
                }
            </>
        );
    }

    public async initializeAsync(): Promise<void> {
        await this.reloadWorkOrderAsync();

        const data: [ConstructionSiteOrWarehouse[], User[], Product[], CostPool[], User[]] = await Promise.all([
            RentaTasksController.getConstructionSitesOrWarehousesAsync(this),
            UserProvider.getManagersAsync(this),
            RentaTasksController.getProductsAsync(this),
            RentaTasksController.getCostPoolsAsync(this),
            UserProvider.getSalesPersonsAsync(this),
        ]);
        const constructionSitesOrWarehouses: readonly Readonly<ConstructionSiteOrWarehouse>[] = data[0];
        const managers: readonly Readonly<User>[] = data[1];
        const products: readonly Readonly<Product>[] = data[2];
        const costPools: readonly Readonly<CostPool>[] = data[3];
        const salesPersons: readonly Readonly<User>[] = data[4];

        let mounters: User[] = [];
        let subcontractorsOrganizationContractIds: string[] = [];
        const constructionSiteId: string | null = this.props.parameters?.ownerId ?? this.workOrder?.constructionSiteId;

        if (constructionSiteId) {
            mounters = await RentaTasksController.getMountersAsync(this, new GetEmployeesRequest());
            subcontractorsOrganizationContractIds = await this.postAsync("/api/constructionSiteManagement/getConstructionSiteSubcontractorsOrganizationContractIds", constructionSiteId);
        }

        await this.setState({
            constructionSitesOrWarehouses,
            managers,
            products,
            mounters,
            costPools,
            subcontractorsOrganizationContractIds,
            salesPersons,
        });
    }

    public render(): ReactNode {
        return (
            <PageContainer
                className={style.workOrder}
                id="workOrderForm"
            >
                <PageHeader title={this.title}>
                    <Icon name={"times"}
                          size={IconSize.X2}
                          onClick={async () => this.navigateBackAsync()}
                    />
                </PageHeader>

                {this.renderContent()}

                <ApproveWorkOrderModal ref={this._approveWorkOrderModalRef}
                                       onApproveAsync={async () => this.lockAsync()}
                />

                <SendWorkOrderModal ref={this._sendWorkOrderModalRef}
                                    onClose={async (_, workOrder, __, success) => await this.onSendWorkOrderModalCloseAsync(workOrder, success)}
                />

                <FormModal ref={this._formModalRef}
                           onSubmit={async (sender, request) => this.saveFormAsync(sender, request)}
                           reloadData={async () => this.reloadWorkOrderAsync()}
                           getWorkOrderForms={async (sender, request) => this.getWorkOrderFormsAsync(sender, request)}
                />

                <UsersInfoModal ref={this._usersInfoModalRef}/>

                <WorkOrderChangeStatusModal ref={this._changeStatusModalRef}
                                            notificationTrigger={(this.hasMounters) ? true : undefined}
                                            forceComment={(this.hasMounters) ? true : undefined}
                                            action={ChangeStatusAction.Reactivate}
                                            onConfirmAsync={(workOrderId, sendNotification, comment) => this.onConfirmAsync(workOrderId, sendNotification, comment)}
                />

                <StatusMessageModal ref={this._statusMessageModalRef}/>

                <SubcontractorAssignmentModal 
                    unAssignMounter={async (mounter: User) => await this.unassignNewlyAssignedSubcontractorMounter(mounter)}
                    assignMounter={async (mounter: User) => await this._subcontractorAssignmentModal.current?.closeAsync()}
                    ref={this._subcontractorAssignmentModal}
                />

            </PageContainer>
        );
    }
}