import React from "react";
import AnonymousPage from "../../models/base/AnonymousPage";
import Localizer from "../../localization/Localizer";
import { PageContainer, PageHeader, PageRow} from "@renta-apps/athenaeum-react-components";

import styles from "./Offline.module.scss";

export interface IOfflinePageProps {
}

export default class ErrorPage extends AnonymousPage<IOfflinePageProps> {
    public render(): React.ReactNode {

        return (
            <PageContainer className={styles.error}>
                <PageHeader title={Localizer.offlinePageTitle} />

                <PageRow>
                    <div className="col">
                        <p>{Localizer.offlinePageBody}</p>
                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}