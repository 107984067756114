import User from "@/models/server/User";
import {IUser} from "@renta-apps/athenaeum-react-common";

export default class UserAssignedToWorkOrder extends User implements IUser  {
    public isAssigned: boolean = false;

    public static createFromUser(user: User, usersAssigned: User[]) {
        let userAssigned = new UserAssignedToWorkOrder();
        userAssigned.id = user.id;
        userAssigned.username = user.username;
        userAssigned.email = user.email;
        userAssigned.phone = user.phone;
        userAssigned.firstname = user.firstname;
        userAssigned.lastName = user.lastName;
        userAssigned.middleName = user.middleName;
        userAssigned.language = user.language;
        userAssigned.employeeNumber = user.employeeNumber;
        userAssigned.externalId = user.externalId;
        userAssigned.homeLocation = user.homeLocation;
        userAssigned.role = user.role;
        userAssigned.roles = user.roles;
        userAssigned.address = user.address;
        userAssigned.city = user.city;
        userAssigned.postalCode = user.postalCode;
        userAssigned.invitations = user.invitations;
        userAssigned.authType = user.authType;
        userAssigned.filtersBehavior = user.filtersBehavior;
        userAssigned.warehouseId = user.warehouseId;
        userAssigned.warehouse = user.warehouse;
        userAssigned.costPools = user.costPools;
        userAssigned.regions = user.regions;
        userAssigned.isLocked = user.isLocked;
        userAssigned.isAssigned = usersAssigned.some(u => u.id === user.id);
        return userAssigned;
    }
}