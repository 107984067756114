import React from "react";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {
    Button,
    ButtonType,
    CellAction,
    CellModel,
    Checkbox,
    ColumnDefinition,
    ColumnType,
    DropdownRequiredType,
    Grid, GridModel,
    IconSize,
    Inline,
    JustifyContent,
    PageContainer,
    PageHeader,
    RowModel,
    TextInput,
    TwoColumns,
} from "@renta-apps/athenaeum-react-components";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";
import {ActionType, ch, TextAlign} from "@renta-apps/athenaeum-react-common";
import CreateExtraChargeTypeRequest from "@/models/server/requests/CreateExtraChargeTypeRequest";
import SaveExtraChargeTypeRequest from "@/models/server/requests/SaveExtraChargeTypeRequest";
import {ExtraChargeTypeUnit} from "@/models/Enums";
import DeleteExtraChargeTypeRequest from "@/models/server/requests/DeleteExtraChargeTypeRequest";
import RestoreExtraChargeTypeRequest from "@/models/server/requests/RestoreExtraChargeTypeRequest";
import AdminExtraChargeType from "@/models/server/AdminExtraChargeType";
import DeleteExtraChargeTypeResponse from "@/models/server/responses/DeleteExtraChargeTypeResponse";
import {getCellAction} from "@/helpers/GridHelpers";
import Localizer from "@/localization/Localizer";
import UserInteractionDataStorage from "@/providers/UserInteractionDataStorage";
import {SortDirection} from "@renta-apps/athenaeum-toolkit";

import styles from "./ExtraChargeTypeManagement.module.scss"


interface IExtraChargeTypeManagementProps {
}

interface IExtraChargeTypeManagementState {
    /** Types to display. */
    filteredTypes: AdminExtraChargeType[];

    /** All types, including deleted ones. */
    allTypes: AdminExtraChargeType[];

    /** If set to true will display deleted types as well as non-deleted. */
    showDeleted: boolean;

    /** Search item by Name or ExternalId. */
    search: string | null;
}

export default class ExtraChargeTypeManagement extends AuthorizedPage<IExtraChargeTypeManagementProps, IExtraChargeTypeManagementState> {

    state: IExtraChargeTypeManagementState = {
        filteredTypes: [],
        allTypes: [],
        showDeleted: false,
        search: null,
    }

    private readonly _gridRef: React.RefObject<Grid<AdminExtraChargeType>> = React.createRef();

    private readonly _columns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            minWidth: 50,
            maxWidth: 50,
            className: "grey",
            textAlign: TextAlign.Center
        },
        {
            header: Localizer.genericNameLanguageItemName,
            accessor: nameof<AdminExtraChargeType>(type => type.name),
            editable: true,
            noWrap: true,
            type: ColumnType.Text,
            minWidth: 300,
            maxWidth: 300,
            sorting: true,
            callback: async (cell: CellModel<any>, _) => await this.onModelChangeAsync(cell),
            settings: {
                required: true,
                maxLength: RentaTaskConstants.dbKeyLength,
            }
        },
        {
            header: Localizer.genericUnitLanguageItemName,
            accessor: nameof<AdminExtraChargeType>(type => type.unit),
            type: ColumnType.Enum,
            format: nameof<ExtraChargeTypeUnit>(),
            editable: true,
            noWrap: true,
            minWidth: 150,
            maxWidth: 150,
            sorting: true,
            callback: async (cell: CellModel<any>, _) => await this.onModelChangeAsync(cell),
            settings: {
                required: true,
                requiredType: DropdownRequiredType.AutoSelect,
                noFilter: true,
            }
        },
        {
            header: Localizer.genericExternalIdLanguageItemName,
            accessor: nameof<AdminExtraChargeType>(type => type.externalId),
            editable: true,
            noWrap: true,
            minWidth: 300,
            maxWidth: 300,
            sorting: true,
            type: ColumnType.Text,
            callback: async (cell: CellModel<any>, _) => await this.onModelChangeAsync(cell),
            settings: {
                required: true,
                maxLength: RentaTaskConstants.dbKeyLength,
            }
        },
        {
            header: Localizer.genericPriceLanguageItemName,
            accessor: nameof<AdminExtraChargeType>(type => type.price),
            editable: true,
            noWrap: true,
            minWidth: 110,
            maxWidth: 110,
            sorting: true,
            format: "C",
            type: ColumnType.Number,
            callback: async (cell: CellModel<any>, _) => await this.onModelChangeAsync(cell),
        },
        {
            header: Localizer.genericStatusLanguageItemName,
            accessor: (model: AdminExtraChargeType) => AdminExtraChargeType.getUsageIcon(model),
            minWidth: 20,
            maxWidth: 20,
            sorting: true,
            textAlign: TextAlign.Center,
            type: ColumnType.Icon,
        },
        {
            init: (cell) => this.initTypeOperations(cell),
            stretch: true,
            removable: false,
            actions: [
                {
                    name: "cancel",
                    title: Localizer.genericActionCancelLanguageItemName,
                    icon: "far ban",
                    type: ActionType.Delete,
                    right: true,
                    callback: async (cell) => await cell.row.cancelAsync()
                },
                {
                    name: "save",
                    title: Localizer.genericActionSaveLanguageItemName,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: async (cell: CellModel<AdminExtraChargeType>, _) => await this.saveTypeAsync(cell)
                },
                {
                    name: "restore",
                    title: Localizer.genericRestoreLanguageItemName,
                    icon: "far undo-alt",
                    type: ActionType.Create,
                    right: true,
                    callback: async (cell: CellModel<AdminExtraChargeType>, _) => await this.restoreTypeAsync(cell)
                },
                {
                    name: "delete",
                    title: Localizer.genericDeleteLanguageItemName,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    right: true,
                    callback: async (cell: CellModel<AdminExtraChargeType>, _) => await this.deleteTypeAsync(cell),
                    confirm: (cell: CellModel<AdminExtraChargeType>) => (cell.model.id) && this.getDeleteConfirmation(cell),
                },
            ]
        }
    ];

    private get grid(): GridModel<AdminExtraChargeType> {
        return this._gridRef.current!.model;
    }

    private getDeleteConfirmation(cell: CellModel<AdminExtraChargeType>): string {
        const model: AdminExtraChargeType = cell.model;
        return (!model.isAssigned)
            ? Localizer.get(Localizer.extraChargeTypeManagementConfirmationMessageDeletePermanently, model.name)
            : Localizer.get(Localizer.extraChargeTypeManagementConfirmationMessageMarkAsDeleted, model.name)
    }

    private initRow(row: RowModel<AdminExtraChargeType>): void {
        const editedType = row.model;

        row.deleted = editedType.deleted;

        row.readonly = editedType.isAssigned;

        row.className = this.isTypeValid(editedType)
            ? ""
            : "bg-declined";
    }

    private initTypeOperations(cell: CellModel<AdminExtraChargeType>): void {
        const model: AdminExtraChargeType = cell.row.model;

        const isAssigned: boolean = model.isAssigned;
        const isNew: boolean = (model.id === "");
        const isValid: boolean = this.isTypeValid(model);

        const saveAction: CellAction<AdminExtraChargeType> = getCellAction(cell.actions, "save");
        saveAction.visible = ((!isAssigned) && ((cell.row.modified) && (isValid)));

        const restoreAction: CellAction<AdminExtraChargeType> = getCellAction(cell.actions, "restore");
        restoreAction.visible = ((model.deleted) && (!cell.row.modified));

        const deleteAction: CellAction<AdminExtraChargeType> = getCellAction(cell.actions, "delete");
        deleteAction.visible = (isNew) || ((!model.deleted) && (!cell.row.modified));

        const cancelAction: CellAction<AdminExtraChargeType> = getCellAction(cell.actions, "cancel");
        cancelAction.visible = ((!isNew) && (cell.row.modified));
    }

    private isTypeValid(model: AdminExtraChargeType): boolean {
        const hasDuplicate: boolean = this.state.allTypes.some(type =>
            (type.id !== model.id)
            && (type.name.trim() === model.name.trim())
            && (type.unit == model.unit)
            && (type.externalId.trim() == model.externalId.trim()));

        return ((!hasDuplicate) && (this.isNameValid(model)) && (this.isExternalIdValid(model)));
    }

    private isNameValid(model: AdminExtraChargeType): boolean {
        return (model.name.trim().length > 0);
    }

    private isExternalIdValid(model: AdminExtraChargeType): boolean {
        return (model.externalId.trim().length > 0);
    }

    private async onModelChangeAsync(cell: CellModel<AdminExtraChargeType>): Promise<void> {
        // Force re-setting model to run all validators and refresh operations.
        await cell.row.setModelAsync(cell.model, true);
    }

    private async getExtraChargeTypesAsync(): Promise<AdminExtraChargeType[]> {
        const types: AdminExtraChargeType[] = await this.getAsync("api/admin/getAdminExtraChargeTypes");
        return types
            .sort((a: AdminExtraChargeType, b: AdminExtraChargeType) => {
                if (a.deleted === b.deleted) {
                    // Sort by name
                    return a.name.localeCompare(b.name);
                }

                // ... and put non-deleted on top.
                return a.deleted ? 1 : -1;
            });
    }

    private async saveTypeAsync(cell: CellModel<AdminExtraChargeType>): Promise<void> {
        if (cell.model.id === "") {
            // Create
            const request: CreateExtraChargeTypeRequest = {
                name: cell.model.name,
                unit: cell.model.unit,
                externalId: cell.model.externalId,
                price: cell.model.price
            };

            await this.postAsync("api/admin/createExtraChargeType", request);
        } else {
            // Update
            const request: SaveExtraChargeTypeRequest = {
                extraChargeTypeId: cell.model.id,
                name: cell.model.name,
                unit: cell.model.unit,
                externalId: cell.model.externalId,
                price: cell.model.price,
                deleted: cell.model.deleted
            };

            await this.postAsync("api/admin/saveExtraChargeType", request);
        }

        await this.fetchDataAsync();
    }

    private async deleteTypeAsync(cell: CellModel<AdminExtraChargeType>): Promise<void> {
        const localDelete: boolean = (cell.model.id === "");
        if (localDelete) {
            await cell.grid.deleteAsync(cell.row.index);
            return;
        }

        const request: DeleteExtraChargeTypeRequest = {
            extraChargeTypeId: cell.model.id,
        };

        const response: DeleteExtraChargeTypeResponse = await this.postAsync("api/admin/deleteExtraChargeType", request);

        if (response.removedPermanently) {
            await ch.flyoutMessageAsync(Localizer.get(Localizer.extraChargeTypeManagementFlyoutMessagePermanentlyDeleted, cell.model.name));
        } else {
            await ch.flyoutMessageAsync(Localizer.get(Localizer.extraChargeTypeManagementFlyoutMessageMarkedAsDeleted, cell.model.name));
        }

        await this.fetchDataAsync();
    }

    private async restoreTypeAsync(cell: CellModel<AdminExtraChargeType>): Promise<void> {
        const request: RestoreExtraChargeTypeRequest = {
            extraChargeTypeId: cell.model.id,
        };

        await this.postAsync("api/admin/restoreExtraChargeType", request);

        await this.fetchDataAsync();
    }

    private async onAddClickAsync(): Promise<void> {
        const hasNewItem: boolean = (this._gridRef.current!.rows.some(row => row.model.id === ""));
        if (hasNewItem) {
            return;
        }

        const newType: AdminExtraChargeType = new AdminExtraChargeType();
        newType.id = "";
        newType.name = "";
        newType.deleted = false;

        const grid: Grid<AdminExtraChargeType> = this._gridRef.current!

        // Add to grid
        const rows: RowModel<AdminExtraChargeType>[] = await grid.model.insertAsync(0, newType);
        // Set focus to new type's name column
        await rows[0].get(nameof(AdminExtraChargeType.name)).editAsync();
    }

    private async onShowDeletedChange(checked: boolean) {
        await this.setState({showDeleted: checked});
        await this.fetchDataAsync();
    }

    private async setSearchTermAsync(search: string | null): Promise<void> {
        await this.setState({search});
    }

    private async clearStateAsync(): Promise<void> {
        await this.setState({search: null, showDeleted: false});
    }

    private async reloadAsync(): Promise<void> {
        await this.clearStateAsync();
        await this.fetchDataAsync();
    }

    private async fetchDataAsync(): Promise<void> {
        const allTypes: AdminExtraChargeType[] = await this.getExtraChargeTypesAsync();

        // Extra charge types to display.
        let filteredTypes: AdminExtraChargeType[] = allTypes;

        if (this.state.search) {
            filteredTypes = filteredTypes.filter(item =>
                item.name.toLowerCase().includes(this.state.search!.toLowerCase()) ||
                item.externalId.toLowerCase().includes(this.state.search!.toLowerCase())
            );

            // Minimum expected items to display a current page.
            let expectedItems: number = (this.grid.pageNumber * this.grid.pageSize) - this.grid.pageSize + 1;

            // Drop a page number if filtered items quantity less than expected items to display current page.
            if (filteredTypes.length < expectedItems) {
                this.grid.pageNumber = 1;
            }
        }

        if (!this.state.showDeleted) {
            filteredTypes = filteredTypes.filter(type => !type.deleted);
        }

        await this.setState({ filteredTypes, allTypes });

        await this._gridRef.current!.reloadAsync();
    }

    private fetchExtraChargeUnitFilteredTypes(sortColumnName: string | null, sortDirection: SortDirection | null): AdminExtraChargeType[] {
        UserInteractionDataStorage.setFilters(sortColumnName, "ExtraChargeUnitTypes.SortColumn");
        UserInteractionDataStorage.setFilters(sortDirection, "ExtraChargeUnitTypes.SortDirection");

        return this.state.filteredTypes;
    }

    private get sortColumn(): string {
        return UserInteractionDataStorage.getFilters("name", "ExtraChargeUnitTypes.SortColumn");
    }

    private get sortDirection(): SortDirection {
        return UserInteractionDataStorage.getFilters(SortDirection.Asc, "ExtraChargeUnitTypes.SortDirection");
    }

    public getTitle(): string {
        return Localizer.extraChargeTypeManagementPageTitle;
    }

    public async componentDidMount(): Promise<void> {
        await this.fetchDataAsync();
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.extraChargeTypeManagement}>
                <PageHeader title={Localizer.extraChargeTypeManagementPageTitle}/>

                <div className={styles.container}>

                    <TwoColumns>

                        <Inline justify={JustifyContent.Start}>

                            <TextInput inline
                                       id="extraChargeTypesSearchField"
                                       label={Localizer.genericSearch}
                                       placeholder={"{0} / {1}".format(Localizer.genericName, Localizer.genericExternalId)}
                                       width={"20ch"}
                                       value={this.state.search ?? ""}
                                       onChange={async (_, value) => await this.setSearchTermAsync(value)}
                            />

                            <Button id="searchExtraChargeTypes"
                                    icon={{name: "search", size: IconSize.Large}}
                                    type={ButtonType.Orange}
                                    onClick={async (_, __) => await this.fetchDataAsync()}
                            />

                            <Button id="reloadExtraChargeTypes"
                                    title={Localizer.genericActionReload}
                                    icon={{name: "far history", size: IconSize.Large}}
                                    type={ButtonType.Info}
                                    onClick={async () => await this.reloadAsync()}
                            />

                        </Inline>

                        <Inline justify={JustifyContent.End}>

                            <Checkbox inline
                                      id="showDeletedExtraChargeTypes"
                                      label={Localizer.genericShowDeleted}
                                      value={this.state.showDeleted}
                                      onChange={async (_, checked: boolean) => await this.onShowDeletedChange(checked)}
                            />

                            <Button id="addExtraChargeType"
                                    icon={{name: "plus", size: IconSize.Large}}
                                    type={ButtonType.Orange}
                                    onClick={async (_, __) => await this.onAddClickAsync()}
                            />

                        </Inline>

                    </TwoColumns>

                    <Grid id="extraChargeTypesGrid"
                          ref={this._gridRef}
                          pagination={RentaTaskConstants.paginationNumber}
                          columns={this._columns}
                          noDataText={Localizer.genericNoData}
                          initRow={(row: RowModel<AdminExtraChargeType>) => this.initRow(row)}
                          fetchData={async (_, __, ___,sortColumnName,sortDirection) => this.fetchExtraChargeUnitFilteredTypes(sortColumnName, sortDirection)}
                          defaultSortColumn={this.sortColumn}
                          defaultSortDirection={this.sortDirection}
                    />
                </div>

            </PageContainer>
        );
    }
}