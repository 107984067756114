import React from "react";
import {ActionType, BaseComponent, ch, TextAlign} from "@renta-apps/athenaeum-react-common";
import {CustomFormType} from "@/models/Enums";
import FormModel from "@/models/server/forms/FormModel";
import {FileModel, IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";
import {CellAction, CellModel, ColumnActionType, ColumnDefinition, ColumnType, Grid, GridHoveringType, GridModel, GridOddType, RowModel, ToolbarContainer} from "@renta-apps/athenaeum-react-components";
import ListFormsRequest from "@/models/server/requests/ListFormsRequest";
import Toolbar from "@/pages/ConstructionSiteManagement/FormsData/Toolbar/Toolbar";
import ToolbarModel from "@/pages/ConstructionSiteManagement/FormsData/Toolbar/ToolbarModel";
import SetFormRequiredRequest from "@/models/server/requests/SetFormRequiredRequest";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";
import WorkOrderModel, {IWorkOrderEditability} from "@/models/server/WorkOrderModel";
import UserInteractionDataStorage from "@/providers/UserInteractionDataStorage";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";

import styles from "./FormsData.module.scss";



interface IFormsDataProps {
    readonly?: boolean;
    showFiltersToolbar: boolean;

    /**
     * Called when a change has been made which requires the Tasks data to be reloaded from Server.
     */
    onUpdateWorkOrderBlockingForms(hasBlockingForms: boolean): Promise<void>;

    constructionSiteId?: string | null;

    workOrderId?: string | null;
}

interface IFormsDataState {
    filters: ToolbarModel;
}

export default class FormsData extends BaseComponent<IFormsDataProps, IFormsDataState> {

    public state: IFormsDataState = {
        filters: new ToolbarModel()
    };

    private static readonly saveAction = "save";
    private static readonly cancelAction = "cancel";
    private static readonly previewAction = "preview";
    private static readonly downloadAction = "download";

    private readonly _formsGridRef: React.RefObject<Grid<FormModel>> = React.createRef();

    private readonly _formsColumns: ColumnDefinition[] = [
        {
            header: Localizer.formsDataNameLanguageItemName,
            accessor: "displayName",
            sorting: true,
            minWidth: 150
        },
        {
            header: Localizer.formsDataWorkOrderLanguageItemName,
            accessor: "workOrder.name",
            sorting: true,
            minWidth: 150,
            visible: this.showExtraColumns
        },
        {
            header: Localizer.formsDataTypeLanguageItemName,
            accessor: "customType",
            format: (type: CustomFormType) => (type === CustomFormType.Audit) ? EnumProvider.getCustomFormTypeText(type) : "-",
            sorting: true,
            textAlign: TextAlign.Center,
            minWidth: 15,
            visible: this.showExtraColumns
        },
        {
            header: Localizer.formDefinitionPageFormItemRequiredLanguageItemName,
            accessor: nameof<FormModel>(form => form.required),
            type: ColumnType.Boolean,
            sorting: true,
            textAlign: TextAlign.Center,
            editable: true,
            init: (cell: CellModel<FormModel>) => this.initFormReadonly(cell),
            callback: async (cell: CellModel<FormModel>) => await this.onRequiredChangeAsync(cell),
            transform: (_, value) => value ? "✓" : ""
        },
        {
            header: "fas sync-alt",
            accessor: (model) => FormModel.getFormStatusIcon(model),
            type: ColumnType.Icon,
            minWidth: 40,
            name: "status",
            sorting: true,
        },
        {
            header: Localizer.formsDataReporterLanguageItemName,
            accessor: "user",
            group: Localizer.formsDataProcessInfoLanguageItemName,
            minWidth: 150,
            sorting: true,
            noWrap: true,
        },
        {
            header: Localizer.formsDataStartedAtLanguageItemName,
            group: Localizer.formsDataProcessInfoLanguageItemName,
            accessor: "startedAt",
            format: "D",
            textAlign: TextAlign.Center,
            minWidth: 80,
            sorting: true,
        },
        {
            header: Localizer.formsDataCompletedAtLanguageItemName,
            group: Localizer.formsDataProcessInfoLanguageItemName,
            accessor: "processedAt",
            format: "D",
            textAlign: TextAlign.Center,
            minWidth: 80,
            sorting: true,
        },
        {
            header: Localizer.formsDataPassedLanguageItemName,
            group: Localizer.formsDataProcessInfoLanguageItemName,
            accessor: "passed",
            textAlign: TextAlign.Center,
            minWidth: 55,
            sorting: true,
            transform: (_, value) => value ? "✓" : ""
        },
        {
            header: Localizer.formsDataTypeLanguageItemName,
            group: Localizer.formsDataApprovalLanguageItemName,
            accessor: "approvalType",
            visible: this.showExtraColumns,
            format: "CustomerApprovalType",
            textAlign: TextAlign.Center,
            minWidth: 55,
            sorting: true,
        },
        {
            header: Localizer.formsDataSentLanguageItemName,
            group: Localizer.formsDataApprovalLanguageItemName,
            accessor: "sentTo",
            visible: this.showExtraColumns,
            title: Localizer.formsDataSentToLanguageItemName,
            textAlign: TextAlign.Center,
            minWidth: 180,
            sorting: true,
            noWrap: true
        },
        {
            header: Localizer.formsDataSentAtLanguageItemName,
            group: Localizer.formsDataApprovalLanguageItemName,
            accessor: "sentAt",
            visible: this.showExtraColumns,
            format: "D",
            textAlign: TextAlign.Center,
            minWidth: 100,
            sorting: true,
            noWrap: true
        },
        {
            header: Localizer.tasksPanelActionsLanguageItemName,
            minWidth: 140,
            init: (cell) => this.initFormOperations(cell),
            actions: [
                {
                    name: FormsData.saveAction,
                    title: Localizer.genericSaveLanguageItemName,
                    type: ActionType.Create,
                    icon: "far save",
                    callback: async (cell, action) => await this.processFormOperationAsync(cell, action),
                },
                {
                    name: FormsData.cancelAction,
                    title: Localizer.genericActionCancelLanguageItemName,
                    type: ActionType.Delete,
                    icon: "ban",
                    callback: async (cell, action) => await this.processFormOperationAsync(cell, action),
                },
                {
                    name: FormsData.previewAction,
                    title: Localizer.genericActionPreviewLanguageItemName,
                    type: ColumnActionType.Preview,
                    right: true,
                    callback: async (cell, action) => await this.processFormOperationAsync(cell, action),
                },
                {
                    name: FormsData.downloadAction,
                    title: Localizer.genericActionDownloadLanguageItemName,
                    type: ColumnActionType.Download,
                    right: true,
                    callback: async (cell, action) => await this.processFormOperationAsync(cell, action),
                }
            ]
        }
    ];

    private initRow(row: RowModel<FormModel>): void {
        const model: FormModel = row.model;
        row.readonly = model.approved;

        const processed: boolean = model.processed;
        const approved: boolean = model.approved;

        row.className = (processed)
            ? "bg-processed"
            : (approved)
                ? "bg-approved"
                : "";
    }

    private initFormOperations(cell: CellModel<FormModel>): void {

        const form: FormModel = cell.row.model;

        const completed: boolean = form.processed;
        const approved: boolean = form.approved;
        const sent: boolean = form.sent;
        const modified: boolean = cell.row.modified;

        const saveAction: CellAction<FormModel> = cell.actions[0];
        const cancelAction: CellAction<FormModel> = cell.actions[1];
        const previewAction: CellAction<FormModel> = cell.actions[2];
        const downloadAction: CellAction<FormModel> = cell.actions[3];

        saveAction.visible = modified;
        cancelAction.visible = modified;
        previewAction.visible = (completed || approved || sent);
        downloadAction.visible = (completed || approved || sent);
    }

    private async processFormOperationAsync(cell: CellModel<FormModel>, action: CellAction<FormModel>): Promise<void> {

        const model: FormModel = cell.model;

        switch (action.action.name) {

            case FormsData.saveAction:
                const request: SetFormRequiredRequest = {
                    formId: model.id,
                    required: model.required,
                };
                const hasBlockingForms: boolean = await cell.grid.postAsync("api/form/setFormRequired", request);

                await cell.row.saveAsync();
                await this.props.onUpdateWorkOrderBlockingForms(hasBlockingForms);
                break;

            case FormsData.cancelAction:
                await cell.row.cancelAsync();
                break;

            case FormsData.previewAction:
                await this.previewFormPdfAsync(cell);
                break;

            case FormsData.downloadAction:
                const file: FileModel = await this.postAsync("api/constructionSiteManagement/getFormPdf", model.id);
                ch.download(file as any);
                break;

            default:
                throw new Error(`Action '${action.action.name}' is not supported`);
        }
    }

    private async onFiltersChange(filters: ToolbarModel): Promise<void> {
        await this.setState({filters});
        await this.formsGrid.reloadAsync();
    }

    private async getFormsAsync(pageNumber: number,
                                pageSize: number,
                                sortColumnName: string | null,
                                sortDirection: SortDirection | null): Promise<IPagedList<FormModel>> {

        UserInteractionDataStorage.setFilters(sortColumnName, "FormsData.SortColumn");
        UserInteractionDataStorage.setFilters(sortDirection, "FormsData.SortDirection");

        const request = new ListFormsRequest();

        if (this.constructionSiteId) {
            request.constructionSiteId = this.constructionSiteId;
        }
        else if (this.workOrderId) {
            request.workOrderId = this.workOrderId;
        }

        request.from = this.state.filters.from;
        request.to = this.state.filters.to;
        request.reportersIds = this.state.filters.reporters.map(item => item.id);
        request.status = this.state.filters.status;
        request.approvalTypes = this.state.filters.approvalTypes;
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;
        request.sortColumnName = sortColumnName;
        request.sortDirection = sortDirection;

        return await this.formsGrid.postAsync("api/constructionSiteManagement/listForms", request);
    }

    private async previewFormPdfAsync(cell: CellModel<FormModel>): Promise<void> {
        const formId: string = cell.model.id;
        await ch.documentPreviewAsync("api/constructionSiteManagement/getFormPdf", formId, Localizer.genericActionPreview);
    }

    private async onRequiredChangeAsync(cell: CellModel<FormModel>): Promise<void> {
        const form: FormModel = cell.model;

        form.required = cell.value;

        await cell.row.reRenderAsync();
    }

    private async initFormReadonly(cell: CellModel<FormModel>) {
        const form: FormModel = cell.model;
        const workOrder: WorkOrderModel | null = cell.model.workOrder;

        if (workOrder) {
            if (this.isSpinning()) {
                cell.readonly = true;
            }

            const editability: IWorkOrderEditability = WorkOrderModel.getEditability(workOrder);

            cell.readonly = (form.passed || !editability.editable);
        }
    }

    private get formsGrid(): GridModel<FormModel> {
        return this._formsGridRef.current!.model;
    }

    private get constructionSiteId(): string | null {
        return this.props.constructionSiteId || null;
    }

    private get workOrderId(): string | null {
        return this.props.workOrderId || null;
    }

    private get showExtraColumns(): boolean {
        return this.workOrderId == null;
    }

    private get readonly(): boolean {
        return (!!this.props.readonly);
    }

    private get sortColumn(): string {
        return UserInteractionDataStorage.getFilters("name", "FormsData.SortColumn");
    }

    private get sortDirection(): SortDirection {
        return UserInteractionDataStorage.getFilters(SortDirection.Asc, "FormsData.SortDirection");
    }

    public async reloadAsync(): Promise<void> {
        await this.formsGrid.reloadAsync();
    }

    public reload(): void {
        //not async
        // noinspection JSIgnoredPromiseFromCall
        this.reloadAsync();
    }

    public render(): React.ReactNode {
        return (
            <div id={"formsData"}
                 className={this.css(styles.formsData)}
            >

                {
                    (this.props.showFiltersToolbar) &&
                    (
                        <ToolbarContainer>
                            <div>
                                <Toolbar readonly={this.readonly}
                                         grid={this._formsGridRef.current}
                                         model={this.state.filters}
                                         onChange={async (model) => this.onFiltersChange(model)}
                                />
                            </div>
                        </ToolbarContainer>
                    )
                }

                <Grid id={"formsGrid"}
                      ref={this._formsGridRef}
                      className={this.css(styles.grid)}
                      noDataText={Localizer.genericNoData}
                      hovering={GridHoveringType.None}
                      odd={GridOddType.None}
                      pagination={RentaTaskConstants.paginationNumber}
                      minWidth="auto"
                      columns={this._formsColumns}
                      initRow={(row) => this.initRow(row)}
                      fetchData={async (_, pageNumber, pageSize, sortColumnName, sortDirection) => await this.getFormsAsync(pageNumber, pageSize, sortColumnName, sortDirection)}
                      readonly={this.readonly}
                      defaultSortColumn={this.sortColumn}
                      defaultSortDirection={this.sortDirection}
                />

            </div>
        );
    }
};