import React from "react";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import RentaTasksWizardPage from "../RentaTasksWizardPage";
import WorkOrderModel from "@/models/server/WorkOrderModel";
import {CheckboxWidget, DropdownOrderBy, DropdownRequiredType, DropdownWidget, RouteWidget, SelectListItem, TextAreaWidget, TextInputWidget, TitleWidget} from "@renta-apps/athenaeum-react-components";
import WorkOrderType from "@/models/server/WorkOrderType";
import {IBaseComponent} from "@renta-apps/athenaeum-react-common";
import RentaTasksController from "@/pages/RentaTasks/RentaTasksController";
import Localizer from "../../../localization/Localizer";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";
import EnumProvider from "@/providers/EnumProvider";
import {WorkOrderContractType} from "@/models/Enums";
import DateInputWidget from "@/components/DateInputWidget/DateInputWidget";
import PageDefinitions from "@/providers/PageDefinitions";

import styles from "./WorkOrderInfo.module.scss"
import rentaTaskStyles from "@/pages/RentaTask.module.scss";

export interface IWorkOrderInfoProps {
}

interface IWorkOrderInfoState {
    unscheduled: boolean;
    typeExpanded: boolean;
    contractTypeExpanded: boolean;
}

export default class WorkOrderInfo extends RentaTasksWizardPage<IWorkOrderInfoProps, IWorkOrderInfoState> {

    state: IWorkOrderInfoState = {
        unscheduled: (this.wizardContextWorkOrder.activationDate == null),
        typeExpanded: false,
        contractTypeExpanded: false,
    };

    private get wizardContextWorkOrder(): WorkOrderModel {
        return RentaTasksController.wizardContextWorkOrder!;
    }

    private get name(): string {
        return this.wizardContextWorkOrder.name!;
    }

    private get activationDate(): Date | null {
        return this.wizardContextWorkOrder.activationDate;
    }

    private get unscheduled(): boolean {
        return (this.state.unscheduled);
    }

    private get description(): string {
        return this.wizardContextWorkOrder.description || "";
    }

    private get creatingNew(): boolean {
        return this.wizardContextWorkOrder.id === "";
    }

    private async setNameAsync(name: string): Promise<void> {
        if (name !== this.name) {
            this.wizardContextWorkOrder.name = name;
            this.saveContext();
            await this.validateAsync();
        }
    }

    private async setUnscheduledAsync(unscheduled: boolean): Promise<void> {
        if (unscheduled !== this.unscheduled) {
            this.state.unscheduled = unscheduled;

            const activateDate: Date | null = (unscheduled) ? null : Utility.today();

            await this.setActivationDateAsync(activateDate);
        }
    }

    private async setActivationDateAsync(activationDate: Date | null): Promise<void> {
        if (activationDate !== this.activationDate) {
            this.wizardContextWorkOrder.activationDate = activationDate;
            this.saveContext();
            await this.reRenderAsync();
        }
    }

    private async setDescriptionAsync(description: string): Promise<void> {
        if (description !== this.description) {
            this.wizardContextWorkOrder.description = description;

            this.saveContext();
            await this.reRenderAsync();
        }
    }

    private async setTypeAsync(type: WorkOrderType): Promise<void> {
        this.wizardContextWorkOrder.type = type;
        this.saveContext();
        await this.reRenderAsync();
    }

    private async setContractTypeAsync(type: WorkOrderContractType): Promise<void> {
        this.wizardContextWorkOrder.contractType = type;
        this.saveContext();
        await this.reRenderAsync();
    }

    private get canUnschedule(): boolean {
        return (!this.wizardContextWorkOrder.completed);
    }

    private get needsActivationDate(): boolean {
        return (!this.wizardContextWorkOrder.completed) && (this.wizardContextWorkOrder.activationDate != null);
    }

    private async fetchWorkOrderTypesAsync(sender: IBaseComponent): Promise<WorkOrderType[]> {
        return await RentaTasksController.getActiveWorkOrderTypesAsync(sender, this.wizardContextWorkOrder);
    }

    private async setTypeExpandedAsync(expanded: boolean): Promise<void> {
        await this.setState({typeExpanded: expanded});
    }

    private async setContractTypeExpandedAsync(expanded: boolean): Promise<void> {
        await this.setState({contractTypeExpanded: expanded});
    }

    protected get canNext(): boolean {
        // Type is required for new tasks.
        return (!!this.name) && ((!this.creatingNew) || (!!this.wizardContextWorkOrder.type.id));
    }

    private get mounterContextWorkOrder(): WorkOrderModel | null {
        return ((RentaTasksController.isSignedIn) && (RentaTasksController.mounterContextWorkOrder))
            ? RentaTasksController.mounterContextWorkOrder
            : null;
    }

    private get currentTaskLabel(): string {
        const customerName: string = `${this.mounterContextWorkOrder?.owner?.organizationContract?.name}`;
        const workOrderName: string = `${this.mounterContextWorkOrder?.name!}`;
        if (customerName && customerName.trim() !== "") {
            return `${workOrderName} - ${customerName}`;
        }
        return workOrderName;
    }

    public async nextAsync(): Promise<void> {
        if (this.name) {
            return super.nextAsync();
        }
    }

    public getManual(): string {
        return Localizer.rentaTasksAddTaskManual;
    }

    public renderContent(): React.ReactNode {
        return (
            <React.Fragment>

                {
                    (this.mounterContextWorkOrder) &&
                    (
                        <RouteWidget wide
                                     className={rentaTaskStyles.myWorkOrderWidget}
                                     label={this.currentTaskLabel}
                                     description={Localizer.rentaTasksDashboardRouteWidgetCurrentTask}
                                     route={() => PageDefinitions.rentaTasksWorkOrder(this.mounterContextWorkOrder?.id ?? "")}
                        />
                    )
                }

                <TitleWidget wide
                             model={this.title}
                />

                <TextInputWidget id="name" stretchContent wide
                                 autoComplete={false}
                                 label={Localizer.addTaskTaskName}
                                 description={Localizer.addTaskTaskNameDescription}
                                 value={this.name}
                                 onChange={async (_, name) => await this.setNameAsync(name)}
                />

                <TextAreaWidget id="description" wide
                                className={styles.description}
                                label={Localizer.addTaskTaskDescription}
                                maxLength={RentaTaskConstants.bigStringLength}
                                description={Localizer.addTaskTaskDescriptionDescription}
                                value={this.description}
                                onChange={async (_, description) => await this.setDescriptionAsync(description)}
                />

                <DropdownWidget stretchContent minimized wide required autoGroupSelected
                                id="workOrderType"
                                icon={{name: "fas question-circle"}} label={Localizer.addTaskTaskTypeLabel}
                                description={Localizer.addTaskTaskTypeDescription}
                                minHeight={140}
                                expanded={this.state.typeExpanded}
                                disabled={(!this.creatingNew)}
                                selectedItem={this.wizardContextWorkOrder.type}
                                requiredType={DropdownRequiredType.Restricted}
                                fetchDataAsync={async (sender: DropdownWidget<WorkOrderType>, _) => await this.fetchWorkOrderTypesAsync(sender)}
                                onToggle={(_, expanded) => this.setTypeExpandedAsync(expanded)}
                                onChange={async (_, item: WorkOrderType) => await this.setTypeAsync(item)}
                />

                { (this.isManager) && (
                    <DropdownWidget stretchContent minimized wide required autoGroupSelected
                                    id="workOrderContractType"
                                    requiredType={DropdownRequiredType.Restricted}
                                    orderBy={DropdownOrderBy.None}
                                    icon={{name: "fas file-alt"}}
                                    label={Localizer.workOrderContractTypeDropdownLabel}
                                    items={EnumProvider.getWorkOrderContractTypeItems()}
                                    selectedItem={EnumProvider.getWorkOrderContractTypeItem(this.wizardContextWorkOrder.contractType)}
                                    minHeight={140}
                                    expanded={this.state.contractTypeExpanded}
                                    onToggle={(_, expanded) => this.setContractTypeExpandedAsync(expanded)}
                                    onChange={async (_, item: SelectListItem) => {
                                        await this.setContractTypeAsync(parseInt(item!.value));
                                    }}
                    />
                )}
                {
                    (this.needsActivationDate) &&
                    (
                        <DateInputWidget id="activationDate"
                                         wide={!this.canUnschedule}
                                         label={Localizer.addTaskStartDate}
                                         description={Localizer.addTaskStartDateDescription}
                                         value={this.activationDate || undefined}
                                         onChange={async (activationDate) => await this.setActivationDateAsync(activationDate)}
                        />
                    )
                }

                {
                    (this.canUnschedule) &&
                    (
                        <CheckboxWidget id={"unschedule"}
                                        label={Localizer.rentaTasksWorkOrderInfoCheckboxWidgetUnscheduled}
                                        description={Localizer.rentaTasksWorkOrderInfoCheckboxWidgetUnscheduledDescription}
                                        wide={!this.needsActivationDate}
                                        checked={this.unscheduled}
                                        onChange={async (_, checked) => await this.setUnscheduledAsync(checked)}
                        />
                    )
                }

            </React.Fragment>
        );
    }
}