import React from "react";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import {BaseComponent, IBaseComponent} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonType, Checkbox, DateInput, Dropdown, DropdownAlign, Form, Modal, NumberInput, TextAreaInput} from "@renta-apps/athenaeum-react-components";
import UserSalaryHour from "@/models/server/UserSalaryHour";
import User from "@/models/server/User";
import Localizer from "@/localization/Localizer";
import {FeatureSwitch} from "@/components/FeatureSwitch/FeatureSwitch";
import FeatureFlags from "@/helpers/FeatureFlags";
import UserAssignedToWorkOrder from "@/models/server/UserAssignedToWorkOrder";
import WorkOrderModel from "@/models/server/WorkOrderModel";

import styles from "./DailyHoursModal.module.scss";

interface IDailyHoursModalProps {
    mounters: User[];
    workOrder: WorkOrderModel;
    onChange(sender: IBaseComponent, hour: UserSalaryHour): Promise<void>;
}

interface IDailyHoursModalState {
}

export default class DailyHoursModal extends BaseComponent<IDailyHoursModalProps, IDailyHoursModalState> {
    private readonly _modalRef: React.RefObject<Modal<UserSalaryHour>> = React.createRef();
    private readonly _formRef: React.RefObject<Form> = React.createRef();

    private async onSubmitAsync(): Promise<void> {
        await this.props.onChange(this, this.hour);
        await this.modal.closeAsync();
    }

    private get hour(): UserSalaryHour {
        return this._modalRef.current!.data!;
    }

    private get isNew(): boolean {
        return (this._modalRef.current!.data!.id === "")
    }

    private get title(): string {
        return (this.isOpen)
            ? "{0}, {1:dd.MM.yyyy}".format(this.hour.user, this.hour.day)
            : "...";
    }

    private get modal(): Modal<UserSalaryHour> {
        return this._modalRef.current!;
    }

    public async openAsync(hour: UserSalaryHour): Promise<void> {
        await this.modal.openAsync(hour);
        await this.reRenderAsync();
    }

    public async closeAsync(): Promise<void> {
        await this.modal.closeAsync();
        await this.reRenderAsync();
    }

    public get isOpen(): boolean {
        return (this._modalRef.current != null) && (this._modalRef.current.isOpen);
    }

    public render(): React.ReactNode {

        return (
            <Modal id="dailyHoursModal" ref={this._modalRef}
                   className={styles.dailyHoursModal}
                   title={this.title}
                   subtitle={Localizer.dailyHoursModalSubtitle}
            >

                {
                    (this.isOpen) &&
                    (
                        <div className="row">
                            <div className="col">

                                <Form className={styles.form}
                                      ref={this._formRef}
                                      id="dailyHours"
                                      onSubmit={async () => await this.onSubmitAsync()}>

                                    <FeatureSwitch flagName={FeatureFlags.AlarmJobs}>
                                        <Checkbox id="isAlarmJobPrice"
                                                  label={Localizer.dailyHoursModalLabelsIncludeAlarmJob}
                                                  value={this.hour.isAlarmJob}
                                                  onChange={async (_, value) => { this.hour.isAlarmJob = value }}
                                        />
                                    </FeatureSwitch>

                                    <Dropdown id="mounters" required autoGroupSelected noSubtext
                                              label={Localizer.dailyHoursModalLabelsMounter}
                                              align={DropdownAlign.Left}
                                              items={this.props.mounters.map(mounter => UserAssignedToWorkOrder.createFromUser(mounter, this.props.workOrder.mounters))}
                                              selectedItem={this.hour.user!.id}
                                              disabled={!this.isNew}
                                              onChange={async (_, value) => { this.hour.user = value!; this.hour.userId = value!.id; } }
                                    />

                                    <DateInput id="day" required
                                               label={Localizer.dailyHoursModalLabelsDate}
                                               minDate={Utility.today().addDays(-31)}
                                               maxDate={Utility.today()}
                                               value={this.hour.day}
                                               readonly={!this.isNew}
                                               onChange={async (value) => { this.hour.day = value }}
                                    />

                                    <NumberInput id="normalHours" required
                                                 label={Localizer.dailyHoursModalLabelsNormalHours}
                                                 step={0.5}
                                                 value={this.hour.normalHours}
                                                 onChange={async (_, value) => { this.hour.normalHours = value }}
                                    />

                                    <NumberInput id="overtime50Hours" required
                                                 label={Localizer.dailyHoursModalLabelsOvertime50Hours}
                                                 min={0}
                                                 step={0.5}
                                                 value={this.hour.overtime50Hours}
                                                 onChange={async (_, value) => { this.hour.overtime50Hours = value }}
                                    />

                                    <NumberInput id="overtime100Hours" required
                                                 label={Localizer.dailyHoursModalLabelsOvertime100Hours}
                                                 min={0}
                                                 step={0.5}
                                                 value={this.hour.overtime100Hours}
                                                 onChange={async (_, value) => { this.hour.overtime100Hours = value }}
                                    />

                                    <FeatureSwitch flagName={FeatureFlags.UserSalaryHoursComment}>
                                        <TextAreaInput
                                            label={Localizer.genericComment}
                                            value={this.hour.comment ?? undefined}
                                            onChange={async (_, value) => { this.hour.comment = value }}
                                        />
                                    </FeatureSwitch>
                                    
                                    <div>

                                        <Button className={styles.buttons}
                                                type={ButtonType.Light}
                                                label={Localizer.genericActionCancel}
                                                onClick={async () => await this.closeAsync()}
                                        />

                                        <Button id="saveAddedHours"
                                                className={this.css(styles.buttons, "float-right")} submit
                                                type={ButtonType.Orange}
                                                label={Localizer.genericActionSave}
                                        />

                                    </div>

                                </Form>

                            </div>
                        </div>
                    )
                }

            </Modal>
        )
    }
}